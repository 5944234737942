import { WalkIN } from './Company';

export class Bed {
    id: string;
    branchCode:string;
    hospitalId:string;
    hospitalName:string;
    wardNumber: string;
    bedNumber: string;
    updatedDate: string;
    createdDate: string;
    validityDate: string;
    locationDetails: string;
    activePatient:boolean;
    active: boolean;
}

export class BedData {
    responseCode: boolean;
    message: string;
    responseObject: any[]
}

export class BedPaymentResponse {
    responseCode: boolean;
    message: string;
    responseObject:string;
}

export class BedPayment
{
    invoiceNo:string;
    bedNumber: string;
    hospitalId:string;
    hospitalName:string;
    bedTransactionNo: string;
    paymentdetails:BedPaymentDetails;
    createdUsername:string;
    createdByName:string;
    branchCode:string;
}




export class BedPaymentDetails
{
    branchCode:string;
    bedTransactionNo:string;
    hospitalId:string;
    hospitalName:string;
    amount:number;
    paymentMode:string;
    paymentRefId:string;

}


export class BedPatient
{
    branchCode:string;
    bedNumber:string;
    patientName:string;
    phoneNumber:string;
}

export class BedBillDetails
{
    branchCode:string;
    invoiceNo:string;
    kotNo:string;
    billType:string;
    isSubscidised:boolean;
    totalAmount:number;
    amountPaid:number;
    paymentMode:string;
    diffItemsCount:number;
    totalItems:number;
    paymentPending:boolean;
    createdDate:string;
    billitems:BedBillItems[];
}

export class BedBillItems
{
    branchCode:string;
   invoiceNo:string;
   kotNo:string;
   menuItemCode:string;
   itemSeqNo:string;
   itemName:string;
   quantity:number;
   price:number;
   totalPrice:number;
}

export class BulkBedOrder
{
    datalist:WalkIN[];
}

export class BedOrderDetails
{
    branchCode:string;
    bedNumber:string;
    wardNumber:string;
    hospitalId:string;
    hospitalName:string;
    patientName:string;
    phoneNumber:string;
    email:string;
    orderFlavour:string;
    totalAmount:number;
    amountPaid:number;
    totalExtraAmount:number;
    discountExtraAmount:number;
    amountExtraPaid:number;
    bedTransactionNo:string;
    createdDate:string;
    updatedDate:string;
    bedpaymentdetails:BedPaymentDetails[];
    billdetails:BedBillDetails[];
    bedextrapaymentdetails:BedPaymentDetails[];
    billextradetails:BedBillDetails[];
    //Used only for Bulk Bed Orders
    added:boolean;
    orderDetail:string;
    createdUsername;
    createdByName;
}

export class BedOrderTransactionVO
{
    bedTransactionNo:string;
    orderFlavour:String;

}

export class  BedExtraOrderCreditSummaryVO {
	
	totalBillAmount:number;
	totalPaidAmount:number;
	totalBalanceAmount:number;
    totalDiscountAmount:number;
	bedorderist:BedOrderDetails[];
}

export class BedExtraSummaryResponse {
    responseCode: boolean;
    message: string;
    responseObject: BedExtraOrderCreditSummaryVO;
}

export class HttpResponseAny {
    responseCode: boolean;
    message: string;
    responseObject: any;
}