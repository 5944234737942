import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { CashExpenseData, CashExpense } from '../../views/bean/CashExpense';
import { BedData } from '../../views/bean/Bed';
import { Observable } from 'rxjs';
import { api } from '../../../environments/api';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from '../auth/authentication.service';
import { PittyCashData } from '../../views/bean/PittyCash';

@Injectable({
  providedIn: 'root'
})
export class ExpenseserviceService {

 

  httpOptions;

  formDataHttpOptions = {
    headers: new HttpHeaders({ 'Content-Type': undefined })
  }

  constructor(private http: HttpClient,private authService:AuthenticationService) {
    let httpHeadersContentTypeJson= new HttpHeaders({'Content-Type':'application/json',
    "Authorization":"Basic "+this.authService.currentUserValue.authdata});
    this.httpOptions = {
      headers: httpHeadersContentTypeJson
    };
   }

  saveCashExpenseData(data: CashExpense): Observable<CashExpenseData> {
    return this.http.post<CashExpenseData>(api.addCashExpense, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  
  fetchCashExpenseByDate(data: any): Observable<CashExpenseData> {
    return this.http.post<CashExpenseData>(api.fetchCashExpenseByDate, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  fetchCashExpenseByAnyDate(data: any): Observable<CashExpenseData> {
    return this.http.post<CashExpenseData>(api.fetchCashExpenseByAnyRange, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  fetchCashExpenseByBranchAndDate(data: any): Observable<CashExpenseData> {
    return this.http.post<CashExpenseData>(api.fetchCashExpenseByBranchAndDate, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  savePittyCashData(data: CashExpense): Observable<PittyCashData> {
    return this.http.post<CashExpenseData>(api.addPittyCashTransaction, data, this.httpOptions).pipe(catchError(this.handleError));
  }

    
  fetchPittyCashDetailsByDate(data: any): Observable<PittyCashData> {
    return this.http.post<CashExpenseData>(api.fetchPittyCashByDate, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  fetchPittyCashDetailsByBranchCodeandDate(data: any): Observable<PittyCashData> {
    return this.http.post<CashExpenseData>(api.fetchPittyCashByBranchCodeAndDate, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  fetchPittyCashDetailsByAnyDate(data: any): Observable<PittyCashData> {
    return this.http.post<CashExpenseData>(api.fetchPittyCashByAnyRange, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  handleError(error: HttpErrorResponse):Observable<any>{

    if(error==undefined||error.error==undefined||error.error.message==undefined)
    {
      alert('Check Your Internet or Please Try Again');
    }
    else
    {
    alert(error.error.message);
    }
    return null;
    }


}
