export class Doctor
{
    hospitalId:string;
    doctorId: string;
    firstName: string;
    middleName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    department: string;
    active:boolean;
    deleted:boolean;
    credit:Credit;
    accountType:string;
    createdUsername:string;
    createdName:string;
    verifiedOtp:string;
    accountVerifiedDate:string;
    accountVerified:boolean;
}

export class Credit
{

    maxLimit: number;
    usedCredit: number;
    startDate: string;
    expiryDate: string;
}

export class DoctorData {
    responseCode: boolean;
    message: string;
    responseObject: any
}

export class ResponseDataArray {
    responseCode: boolean;
    message: string;
    responseObject: any[]
}

export class DoctorOrder
{
    doctorId: string;
    doctorName: string;
    department: string;
    accountType: string;
    totalAmount: string;
    paymentAmount: string;
    discountAmount: string;
    transactionNo: string;
    createdDate: string;
    paymentClearedDate:string;

    paymentdetails:DoctorPaymentDetails[];
    billdetails:DoctorBillDetails[];

    coupondetails:DoctorCouponDetails[];

    

}


export class DoctorPaymentDetails
{
    transactionNo:string;
    amount:number;
    paymentMode:string;
    paymentRefId:string;
    createdDate:string;

}

export class DoctorCouponDetails
{
    id: string;
    type: string;
    name: string;
    price: number;
    couponGroup: string;
    couponNumber: string;
    createdDate: string;
    validityDate: string;
    createdUsername: string;
    active: boolean;
    deleted: boolean;
    redeemed: boolean;

}

export class DoctorPayment
{
    doctorId:string;
    invoiceNo:string;
    transactionNo:string;
    paymentdetails: BillPayment;
    createdUsername:string;
    createdName:string;

}


export class BillPayment
{
    branchCode:string;
    amount:number;
    discount:number;
    paymentMode:string;
    paymentRefId:string;
}



export class DoctorBillDetails
{
    invoiceNo:string;
    kotNo:string;
    billType:string;
    totalAmount:number;
    amountPaid:number;
    paymentMode:string;
    diffItemsCount:number;
    totalItems:number;
    paymentPending:boolean;
    billitems:DoctorBillItems[];
}

export class DoctorBillItems
{
   invoiceNo:string;
   kotNo:string;
   menuItemCode:string;
   itemSeqNo:string;
   itemName:string;
   quantity:number;
   price:number;
   totalPrice:number;
}

export class DoctorcreditSummary
{

    totalBillAmount:number;
	totalCashAmount:number;
	totalCouponAmount:number;
	totalBalanceAmount:number;
    totalDiscountAmount:number;
	doctororderist:DoctorOrder[];

}

