import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { AuthenticationService } from '../../../service/auth/authentication.service';
import { MenuService } from '../../../service/menuServ/menu.service';
import { DishIngredient, DishPreperationStep, FoodItem, Ingredient } from '../../bean/Menu';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageComponent } from '../../Generic/message/message.component';

@Component({
  selector: 'app-reciepeprepsteps',
  templateUrl: './reciepeprepsteps.component.html',
  styleUrls: ['./reciepeprepsteps.component.css']
})
export class ReciepeprepstepsComponent implements OnInit {

  selectedFoodItemIndex:number=-1;
  selectedLanguageIdIndex:number=-1;

  selectedFoodItem:FoodItem;
  selectedLanguage:any;

  languageSelected:boolean=false;
  foodItemSelected:boolean=false;



  languageList=[{languageName:"English",languageCode:"english"},{languageName:"Kannada",languageCode:"kannada"},{languageName:"Hindi",languageCode:"hindi"}]
  allFoodItemData: FoodItem[] = [];
  tempAllFoodItemData: FoodItem[] = [];
  ingrdientDetails:DishIngredient;
  stepDetails:DishPreperationStep[]=[];

  initialRequestModel:any;


  searchBox : FormControl = new FormControl();

  displayDetails:boolean=false;


  translatedIngredients:Ingredient[]=[];


  constructor(private authService:AuthenticationService,private menuService:MenuService, private _snackBar: MatSnackBar) {


   }

   ngOnInit() {

    this.selectedLanguageIdIndex=-1;
    this.languageSelected=false;
    this.foodItemSelected=false;

    this.selectedFoodItem=null;
    this.selectedLanguage=null;


    this.searchBox.valueChanges.subscribe(
      term => {
       

          var enteredString =term as string;
          
          if(enteredString!='')
          {
          var filterResult:FoodItem[] = this. tempAllFoodItemData.filter(tran => 
            tran.foodItemName.toLowerCase().startsWith(enteredString.toLowerCase())
            );

            console.log(filterResult);
            
            if(filterResult.length>0)
            {
        this.allFoodItemData=filterResult;
            }
        
else
{
              this. allFoodItemData=this.tempAllFoodItemData;
          }
          
        }
        else
        {
          this. allFoodItemData=this.tempAllFoodItemData;
        }
        
        
    });

    this.displayDetails=false;

    this.fetchAllActiveFoodItems();



   }

   onSelectFoodItem(item)
  {
    this.selectedFoodItem=item;
    this.foodItemSelected=true;

  }

  search()
  {
    this.displayDetails=false;
    if(this.foodItemSelected&&this.selectedLanguageIdIndex>=0)
    {
      this.displayDetails=true;
     // this.fetchDishPrepIngredients();
     this.translatedIngredients=[];
     if(this.selectedFoodItem.ingredientDetails!=null&&this.selectedFoodItem.ingredientDetails.length>0)
     {
     for(let i=0;i<this.selectedFoodItem.ingredientDetails.length;i++)
     {
      this.translatedIngredients[i]=this.selectedFoodItem.ingredientDetails[i];
      if(this.selectedFoodItem.ingredientDetails[i].quantity<1)
      {
        this.translatedIngredients[i].quantity= (+this.translatedIngredients[i].quantity)*1000;
      if(this.selectedFoodItem.ingredientDetails[i].measurementUnit.toLocaleLowerCase()=='litres')
      {
        this.translatedIngredients[i].measurementUnit='ML'
      
      }
      else if(this.selectedFoodItem.ingredientDetails[i].measurementUnit.toLocaleLowerCase()=='kg')
      {
        this.translatedIngredients[i].measurementUnit='Grams'
      }
      }
      
     }
    }
      this.fetchDishPrepSteps()
    }
    else
    {
      this.openSnackBar('Select Inputs for Search')
    }

  }

  

 

  fetchAllActiveFoodItems()
  {
    this.menuService.fetchAllFoodItemlist().subscribe(response => {

      if(response.responseCode)
      {
       
        this.allFoodItemData=response.responseObject
        this.tempAllFoodItemData=response.responseObject
      
      }
    
    });
  }



  fetchDishPrepIngredients()
  {
    var param={dishCode:this.selectedFoodItem.foodItemCode,languageCode:this.selectedLanguage.languageCode}
    this.menuService.fetchDishIngredients(param).subscribe(response => {

      if(response.responseCode)
      {
       
        this.ingrdientDetails=response.responseObject
      
      }
    
    });
  }

  fetchDishPrepSteps()
  {
    var param={dishCode:this.selectedFoodItem.foodItemCode,languageCode:this.selectedLanguage.languageCode}
    this.menuService.fetchDishPrepStep(param).subscribe(response => {

      if(response.responseCode)
      {
       
       
        this.stepDetails=response.responseObject
      
      }
    
    });
  }



   onFoodItemSelected()
   {
    if(this.selectedFoodItemIndex=-1)
    {
      this.selectedFoodItem=undefined;
      this.foodItemSelected=false;
      return;

    }

    this.foodItemSelected=true;
    this.selectedFoodItem=this.allFoodItemData[this.selectedFoodItemIndex];
   }


   onLanguageSelected() {
    if(this.selectedLanguageIdIndex==-1)
    {
      this.languageSelected=false;
      return;

    }

    this.languageSelected=true;
    this.selectedLanguage=this.languageList[this.selectedLanguageIdIndex];
   
  }



  openSnackBar(message: string) {
    this._snackBar.openFromComponent(MessageComponent, {
      data: message,
      duration: 5 * 1000
    });
  }

}
