import { INavData } from '@coreui/angular';

export const SupervisorNavItems: INavData[] = [
  
  {
    name: 'Inventory Management',
    url: 'sadmin',
    icon: 'icon-star',
    children: [
      {
        name: 'Inventory Location',
        url: 'inventory/location',
        icon: 'icon-cursor'
      },
      {
        name: 'Credit Report',
        url: 'inventory/report',
        icon: 'icon-pencil'
      },
      {
        name: 'Inventory Items List',
        url: 'inventory/iitemslist',
        icon: 'icon-layers'
      },
      {
        name: 'Outgoing Transfers',
        url: 'inventory/itransfers',
        icon: 'icon-bell'
      }
      
    ]
  },
  {
    name: 'Fixed Asset Management',
    url: 'sadmin',
    children: [
      {
        name: 'Fixed Assets List',
        url: 'inventory/assetslist'
      },
      {
        name: 'Pending Approvals',
        url: 'inventory/assetpendingapprovals'
      }
    ]
  },
  {
    name: 'Kitchen Requests',
    url: 'sadmin',
    children: [
      {
        name: 'Manage Inventory Debit Requests',
        url: 'receipedebits'
      }
    ]
  },
  {
    name: 'Menu System',
    url: 'sadmin',
    icon: 'icon-film',
    children: [
      {
        name: 'View Menus',
        url: 'menu/mangement',
        icon: 'icon-layers'
      },
      {
        name: 'View Menu Items',
        url: 'menu/itemMangement',
        icon: 'icon-cursor'
      },
      {
        name: 'View Food Items',
        url: 'menu/foodItem',
        icon: 'icon-bell'
      }
    ]
  },
  {
    name: 'Doctors System',
    url: 'supervisor',
    icon: 'icon-chemistry',
    children: [
      {
        name: 'View Doctors',
        url: 'doctors/mangement',
        icon: 'icon-layers'
      }
    ]
  },
  {
    name: 'Bed System',
    url: 'supervisor',
    icon: 'icon-eyeglass',
    children: [
      {
        name: 'View Bed Details',
        url: 'bed/mangement',
        icon: 'icon-layers'
      },
      {
        name: 'Add/View Hospitals',
        url: 'bed/hospital',
        icon: 'icon-layers'
      }
    ]
    
  },
  {
    name: 'Feedback',
    url: 'supervisor',
    children: [
      {
        name:'Add Feedback Question',
        url: 'feedback/addquestion'
      },
      {
        name:'View Feedbacks',
        url: 'feedback/viewfeedback'
      }
    ]
  },
  {
    name: 'NABH Checklists',
    url: 'supervisor',
    children: [
      {
        name:'Report Cooking Temperature',
        url: 'nabhchecklist/reportcookingtemp'
      },
      {
        name:'Report Food Sampling',
        url: 'nabhchecklist/reportfoodsampling'
      },
      {
        name:'Report Freezer Temperature',
        url: 'nabhchecklist/reportfreezertemp'
      },
      {
        name:'Report Kitchen Cleaning',
        url: 'nabhchecklist/reportkitchencleaning'
      },
      {
        name:'Report Personal Hygiene',
        url: 'nabhchecklist/reportpersonalhygiene'
      },
      {
        name:'Report Sensory Evaluation',
        url: 'nabhchecklist/reportsensoryeval'
      },
      {
        name:'Report Chlorine Sanitisation',
        url: 'nabhchecklist/reportchlorine'
      },
      {
        name:'Report Pot Wash',
        url: 'nabhchecklist/reportpotwash'
      }
    ]
  }
];
