export class InventoryItem
{
    id:string;
    itemCode:string;
    name: string;
    category:string;
    description: string;
    units: number;
    totalQuantity:number;
    unitQuantity: number;
    balance: number;
    unitsMeasurementUnit:string;
    balanceMeasurementUnit:string;
    measurementUnit: string;
    disableDirectDebit:boolean;
    createdDate: Date;
    updatedDate: Date;
    active: boolean;
    deleted: boolean;
    inventoryItemTransactions: InventoryItemTransactions[];
    itemTransactions: InventoryItemTransactions[];
}

export class InventoryData
{
    responseCode:boolean;
    message:string;
    responseObject: any[]
}

export class InventoryItemData
{
    responseCode:boolean;
    message:string;
    responseObject: any
}

export class InventoryItemTransactions
{
            id: string;
            inventoryCode: string;
            itemCode: string;
            foodItemCode: string;
            unitQuantityMeasurementUnit:string;
            totalQuantityMeasurementUnit:string;
            balanceMeasurementUnit:string;
            units: number;
            quantity: number;
            unitQuatity: number;
            totalQuantity: number;
            price: number;
            type: string;
            balance: number;
            deleted: boolean;
            createdDate: string;
            createdUser: string;
            comments: string;
            expiryDate: string;
            alertBeforeDays: number;
}

export class IndividualInventoryLocation {
    inventoryCode:string;
    inventoryName:string;
    inventoryAddress:string;
    id:String;
    createdDate: String;
    updatedDate: String;
    createdUser: String;
    updatedUser: String;
    active: boolean;
    deleted: boolean;
}



export class InventoryLocations {
    inventoryCode:string;
    inventoryName:string;
    inventoryAddress:string;
    id:String;
    createdDate: String;
    updatedDate: String;
    createdUser: String;
    updatedUser: String;
    active: boolean;
    deleted: boolean;
    inventoryTransferRequest: InventoryTransferRequest[];
    transferRequest:boolean;
}


export class InventoryTransData {
    fromInventoryCode:string;
    toInventoryCode:string;
    itemCode:String;
    units:number;
    quantity: number;
    transferedUser:string;
    transferComments: string;
}

export class InventoryDebitData {
    itemCode:string;
    inventoryCode:string;
    foodItemCode:string;
    units: number;
    unitQuantity:number;
    totalQuantity:number;
    comment: string;
    createdUser:string;
    type: string;
}

export class InventoryCreditData {
    itemCode:string;
    inventoryCode:string;
    units: number;
    unitQuantity:number;
    totalQuantity:number;
    price:number;
    type: string;
    comment: string;
    createdUser:string;
    alertBeforeDays:string;
    expiryDate:string;
    
}




export enum TransType {
    CREDIT = "CREDIT",
    DEBIT = "DEBIT",
    TRANSFER = "TRANSFER"
}

export class InventoryBalanceDetails
{
    price: number;
    expiryMessage: null;
    totalCreditUnits: number;
    totalDebitUnits: number;
    totalCreditQuantity: number;
    totalDebitQuantity: number;
    totalUnitsBalance: number;
    totalQuantityBalance: number;
    subInventoryBalances: SubInventorySBalanceDetails[];
}

export class SubInventorySBalanceDetails
{
    inventoryCode: string;
    inventoryName: string;
    totalQuantityBalance: number;
    totalUnitsBalance: number;
    totalCreditQuantity: number;
    totalDebitQuantity: number;
    totalCreditUnits: number;
    totalDebitUnits: number;

}

export class InventoryTransfer
{
    fromInventoryCode: string;
    toInventoryCode: string;
    itemCode: string;
    units: number;
    quantity: number;
    transferedUser:string;
    transferComments: string;
}


export class InventoryTransferRequest
{
    id: string;
    fromInventoryCode: string;
    toInventoryCode: string;
    itemCode: string;
    measurementUnit:string;
    units:number;
    quantity: number;
    transferredDate: string;
    arDate: string;
    verified: boolean;
    accepted: boolean;
    rejected: boolean;
    active: boolean;
    deleted: boolean;
    createdDate: string;
    transferedUser: string;
    verifiedUser: string;
    receivedUser: string;
    transferComments: string;
    arComments: string
}

export class CreditReport
{
    totalCreditCount:number;
    totalCreditUnits:number;
    creditTransactions: CreditTransaction[];
}

export class DebitReport
{
    totalDebitCount:number;
    totalDebitUnits:number;
    debitTransactions: CreditTransaction[];
}

export class CreditTransaction
{
    id: string;
    inventoryCode: string;
    itemCode: string;
    itemName: string;
    measurementUnit:string;
    menuItemCode: string;
    units: number;
    quantity: number;
    price: number;
    type: string;
    balance: number;
    deleted: boolean;
    createdDate: string;
    createdUser: string;
    comments: string;
    expiryDate: string;
    alertBeforeDays: number;
}


export class ItemConsumption
{
    totalDebitCount:number;
    debitCountMeasurementUnit:string;
    totalRecoveryCount: number;
    recoveryCountMeasurementUnit:string;
    totalWastageCount: number;
    wastageCountMeasurementUnit:string;
    totalMissingCount: number;
    missingCountMeasurementUnit:string;
    inventoryItemCode: string;
    inventoryItemName: string;
    fooditemlist: FoodItemRecoverSumary[];
}






export class  FoodItemRecoverSumary
{
    foodItemCode:string;
	foodItemName:string;
	
	debitCount:string;
    debitCountMeasurementUnit:string;
	recoveryCount:string;
    recoveryCountMeasurementUnit:string;
	wastageCount:string;
    wastageCountMeasurementUnit:string;
	missingCount:string;
    missingCountMeasurementUnit:string;
}

export class InventoryItemConsumption
{
    inventoryItemCode:string;
	inventoryItemName:string;
    debitCount:string;
	recoveryCount:string;
	wastageCount:string;
	missingCount:string;
}