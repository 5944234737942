import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { MenuData, Menu, Item, Ingredient,FoodItem, MenuItemFoodItemLink, MenuMenuItemLink} from '../../views/bean/Menu';
import { api } from '../../../environments/api';
import { catchError, map, tap } from 'rxjs/operators';
import { WalkIN, WalkINData } from '../../views/bean/Company';
import { BillReportResponse } from '../../views/bean/billreport';
import { BillingResponse, BillingResponseAny } from '../../views/bean/BillingResponse';
import { Comments } from '../../views/bean/Comments';
import { LinkEmployee } from '../../views/bean/LinkEmployee';
import { DoctorData } from '../../views/bean/Doctor';
import { LoggedInUserAccountDetails } from '../../views/bean/UserAccount';
import { AuthenticationService } from '../auth/authentication.service';
import { Property } from 'aws-sdk/clients/appflow';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

 

  httpOptions;

  httpMultipartOptions;
  
  formDataHttpOptions = {
    headers: new HttpHeaders({ 'Content-Type': undefined })
  }

  constructor(private http: HttpClient,private authService:AuthenticationService) {
    
    console.log("Basic Auth="+this.authService.currentUserValue.authdata);
    let httpHeadersContentTypeJson= new HttpHeaders({'Content-Type':'application/json',
    "Authorization":"Basic "+this.authService.currentUserValue.authdata});
    this.httpOptions = {
      headers: httpHeadersContentTypeJson
    };

   /* let httpHeadersContentTypeMultipart= new HttpHeaders().append('Content-Type', 'multipart/form-data')
    .append("Authorization", "Basic "+this.authService.currentUserValue.authdata);

  
  
    this.httpMultipartOptions = {
      headers:httpHeadersContentTypeMultipart
    };*/

    console.log(this.httpOptions);
   // console.log(this.httpMultipartOptions);
   }

  uploadMenuFile(fileToUpload: File,branchCode:any): Observable<MenuData> {
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    formData.append('branchCode',branchCode);
    return this.http.post<MenuData>(api.uploadMenuFile, formData).pipe(catchError(this.handleError));
}

uploadMenuCategoryFile(fileToUpload: File,branchCode:any): Observable<MenuData> {
  const formData: FormData = new FormData();
  formData.append('file', fileToUpload, fileToUpload.name);
  formData.append('branchCode',branchCode);
  return this.http.post<MenuData>(api.uploadMenuCatgeoryFile, formData).pipe(catchError(this.handleError));
}

uploadFoodItemFile(fileToUpload: File): Observable<MenuData> {
  const formData: FormData = new FormData();
  formData.append('file', fileToUpload, fileToUpload.name);
  return this.http.post<MenuData>(api.uploadFoodItemFile, formData).pipe(catchError(this.handleError));
}

uploadFoodItemFileWithPrepStep(fileToUpload: File): Observable<MenuData> {
  const formData: FormData = new FormData();
  formData.append('file', fileToUpload, fileToUpload.name);
  return this.http.post<MenuData>(api.uploadFoodItemFileWithPrepStep, formData).pipe(catchError(this.handleError));
}

  saveData(data: Menu): Observable<MenuData> {
    console.log(this.httpOptions);
    return this.http.post<MenuData>(api.createMenu, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  addItemToMenu(data: Menu): Observable<MenuData> {
    return this.http.post<MenuData>(api.addItemToMenu, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  linkItemToMenu(data: Menu): Observable<MenuData> {
    return this.http.post<MenuData>(api.linkMenuItemToMenu, data, this.httpOptions).pipe(catchError(this.handleError));
  }


  /*  getDoctosData(data: string): Observable<MenuData> {
     return this.http.post<MenuData>(api.getAllDoctorById, data, this.httpOptions);
   } */

  /*  updateData(data: any): Observable<MenuData> {
     return this.http.put<MenuData>(api.updateDoctorCredit, data, this.httpOptions);
   } */

  deleteData(data: any): Observable<any> {
    return this.http.post<any>(api.deleteMenu, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  getAllMenuData(): Observable<MenuData> {
    return this.http.get<MenuData>(api.getAllMenu,this.httpOptions).pipe(catchError(this.handleError));
  }

  getByMenuCode(data:any): Observable<any> {
    return this.http.post<any>(api.getByMenucode,data,this.httpOptions).pipe(catchError(this.handleError));
  }

  getAllActiveMenuData(): Observable<MenuData> {
    return this.http.get<MenuData>(api.getAllActiveMenu,this.httpOptions).pipe(catchError(this.handleError));
  }

  getAllMenuDataByBranchCode(data: any): Observable<MenuData> {
    return this.http.post<MenuData>(api.getAllMenuByBranchCode,data,this.httpOptions).pipe(catchError(this.handleError));
  }

  getAllActiveMenuDataByBranchCode(data: any): Observable<MenuData> {
    return this.http.post<MenuData>(api.getAllActiveMenuByBranchCode,data,this.httpOptions).pipe(catchError(this.handleError));
  }

  /*   activateItem(data: FormData): Observable<MenuData> {
      return this.http.post<MenuData>(api.activateInventoryItem, data, this.httpOptions);
    }
   */
  deactivate(data: any): Observable<MenuData> {
    return this.http.post<MenuData>(api.deactivateMenu, data, this.httpOptions).pipe(catchError(this.handleError));
  }



  fetchAllItemlist(): Observable<MenuData> {
    return this.http.get<MenuData>(api.getAllItem).pipe(catchError(this.handleError));
  }

  fetchAllItemlistByBranchCode(data: any): Observable<MenuData> {
    return this.http.post<MenuData>(api.getAllItemByBranchCode,data, this.httpOptions).pipe(catchError(this.handleError));
  }

  fetchAllActiveItemlistByBranchCode(data: any): Observable<MenuData> {
    return this.http.post<MenuData>(api.getAllActiveItemByBranchCode,data, this.httpOptions).pipe(catchError(this.handleError));
  }

  

  saveItem(data: Item): Observable<MenuData> {
    return this.http.post<MenuData>(api.createItem, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  deactivateItem(data: any): Observable<MenuData> {
    return this.http.post<MenuData>(api.deactivateItem, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  deleteItem(data: any): Observable<MenuData> {
    return this.http.post<MenuData>(api.deleteMenuItem, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  activateItem(data: any): Observable<MenuData> {
    return this.http.post<MenuData>(api.activateItem, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  deactivateMenu(data: any): Observable<MenuData> {
    return this.http.post<MenuData>(api.deactivateMenu, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  activateMenu(data: any): Observable<MenuData> {
    return this.http.post<MenuData>(api.activateMenu, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  deleteMenu(data: any): Observable<MenuData> {
    return this.http.post<MenuData>(api.deleteMenu, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  removeFoodItem(data: MenuItemFoodItemLink): Observable<MenuData> {
    return this.http.post<MenuData>(api.removeFoodItemToMenuItem, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  removeIngredient(data: any): Observable<MenuData> {
    return this.http.post<MenuData>(api.removeIngredientFromFoodItem, data, this.httpOptions).pipe(catchError(this.handleError));
  }


  addIngredientToMenu(data: Ingredient): Observable<MenuData> {
    return this.http.post<MenuData>(api.addIngredient, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  removeToMenu(data: Ingredient): Observable<MenuData> {
    return this.http.post<MenuData>(api.removeIngredientFromFoodItem, data, this.httpOptions).pipe(catchError(this.handleError));
  }


  addFoodItemToMenuITem(data: MenuItemFoodItemLink): Observable<MenuData> {
    return this.http.post<MenuData>(api.addFoodItemToMenuItem, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  addMenuItemToMenu(data: MenuMenuItemLink): Observable<MenuData> {
    return this.http.post<MenuData>(api.linkMenuItemToMenu, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  removeMenuItemFromMenu(data: MenuMenuItemLink): Observable<MenuData> {
    return this.http.post<MenuData>(api.unlinkMenuItemToMenu, data, this.httpOptions).pipe(catchError(this.handleError));
  }


  fetchAllFoodItemlist(): Observable<MenuData> {
    return this.http.get<MenuData>(api.getAllFoodItem,this.httpOptions).pipe(catchError(this.handleError));
  }

  saveFoodItem(data: FoodItem): Observable<MenuData> {
    return this.http.post<MenuData>(api.createFoodItem, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  addIngredientToFoodItem(data: Ingredient): Observable<MenuData> {
    console.log(data);
    return this.http.post<MenuData>(api.addIngredient, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  linkInventoryItemToMenuItem(data:any): Observable<any> {
    return this.http.post<any>(api.linkInventoryItemToMenuItem, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  unlinkInventoryItemToMenuItem(data:any): Observable<any> {
    return this.http.post<any>(api.unlinkInventoryItemToMenuItem, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  deactivateFoodItem(data: any): Observable<MenuData> {
    return this.http.post<MenuData>(api.deactivateFoodItem, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  activateFoodItem(data: any): Observable<MenuData> {
    return this.http.post<MenuData>(api.activateFoodItem, data, this.httpOptions).pipe(catchError(this.handleError));
  }

deleteFoodItem(data: any): Observable<MenuData> {
    return this.http.post<MenuData>(api.deleteFoodItem, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  fetchByFoodItem(data: any): Observable<any> {
    return this.http.post<any>(api.fetchByFoodItem, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  addWalkInOrderBill(data: WalkIN): Observable<BillingResponse> {
    return this.http.post<BillingResponse>(api.addWalkInOrderBill,data, this.httpOptions).pipe(catchError(this.handleError));
  }

  migrateBill(data: WalkIN): Observable<BillingResponse> {
    return this.http.post<BillingResponse>(api.migratePreKotBill,data, this.httpOptions).pipe(catchError(this.handleError));
  }

  addPreKotOrderBill(data: WalkIN): Observable<BillingResponse> {
    return this.http.post<BillingResponse>(api.addPreKotOrderBill,data, this.httpOptions).pipe(catchError(this.handleError));
  }

  addBedOrderBill(data: WalkIN): Observable<BillingResponse> {
    return this.http.post<BillingResponse>(api.addBedOrderBill,data, this.httpOptions).pipe(catchError(this.handleError));
  }

  addBedExtraOrderBill(data: WalkIN): Observable<BillingResponse> {
    return this.http.post<BillingResponse>(api.addExtraBedOrder,data, this.httpOptions).pipe(catchError(this.handleError));
  }

  addDoctorOrderBill(data: WalkIN): Observable<BillingResponseAny> {
    return this.http.post<BillingResponseAny>(api.addDoctorOrderBill,data, this.httpOptions).pipe(catchError(this.handleAndThrowError));
  }

  fetchWalkInBilllist(): Observable<WalkINData> {
    return this.http.get<WalkINData>(api.fetchWalkIn,this.httpOptions).pipe(catchError(this.handleError));
  }

  fetchAllPreKotBills(): Observable<WalkINData> {
    return this.http.get<WalkINData>(api.fetchPreKot,this.httpOptions).pipe(catchError(this.handleError));
  }

  fetchAllPreKotBillBranchCode(data:any): Observable<WalkINData> {
    return this.http.post<WalkINData>(api.fetchPreKotBranchCode,data,this.httpOptions).pipe(catchError(this.handleError));
  }

  addComments(data:Comments): Observable<DoctorData> {
    return this.http.post<DoctorData>(api.addComments,data, this.httpOptions).pipe(catchError(this.handleError));
  }

  linkemployee(data:LinkEmployee): Observable<DoctorData> {
    return this.http.post<DoctorData>(api.linkemployee,data, this.httpOptions).pipe(catchError(this.handleError));
  }

  deleteWalkinTransaction(data:any): Observable<any> {
    return this.http.post<any>(api.deleteWalkInOrderTransaction,data, this.httpOptions).pipe(catchError(this.handleError));
  
  }

  bulkLinkMenuToDay(data:any): Observable<any> {
    return this.http.post<any>(api.bulklinkmenutoday,data, this.httpOptions).pipe(catchError(this.handleError));
  }

  bulkLinkMenuItemToMenu(data:any): Observable<any> {
    return this.http.post<any>(api.bulklinkMenuItemtoMenu,data, this.httpOptions).pipe(catchError(this.handleError));
  }

  linkMenuToDay(data:any): Observable<any> {
    return this.http.post<any>(api.linkmenutoday,data, this.httpOptions).pipe(catchError(this.handleError));
  }

  unlinkMenuToDay(data:any): Observable<any> {
    return this.http.post<any>(api.unlinkmenutoday,data, this.httpOptions).pipe(catchError(this.handleError));
  }

  fetchalllinkedMenuToDayBranchCode(data:any): Observable<any> {
    return this.http.post<any>(api.fetchalllinkedmenutodaybranchcode,data, this.httpOptions).pipe(catchError(this.handleError));
  }

  fetchallMenuFortheDayBranchCode(data:any): Observable<any> {
    return this.http.post<any>(api.fetchallemenuitembydaybranchcode,data, this.httpOptions).pipe(catchError(this.handleError));
  }


  bulkLinkFoodItemToDay(data:any): Observable<any> {
    return this.http.post<any>(api.bulklinkfooditemtoday,data, this.httpOptions).pipe(catchError(this.handleError));
  }

 

  linkFoodItemToDay(data:any): Observable<any> {
    return this.http.post<any>(api.linkfooditemtoday,data, this.httpOptions).pipe(catchError(this.handleError));
  }

  unlinkFoodItemToDay(data:any): Observable<any> {
    return this.http.post<any>(api.unlinkfooditemtoday,data, this.httpOptions).pipe(catchError(this.handleError));
  }

  fetchalllinkedFoodItemToDayBranchCode(data:any): Observable<any> {
    return this.http.post<any>(api.fetchalllinkedfooditemtodaybranchcode,data, this.httpOptions).pipe(catchError(this.handleError));
  }

  fetchallFoodItemFortheDayBranchCode(data:any): Observable<any> {
    return this.http.post<any>(api.fetchallfooditemitembydaybranchcode,data, this.httpOptions).pipe(catchError(this.handleError));
  }


  saveDishIngredients(data: any): Observable<any> {
    return this.http.post<any>(api.addUpdateDishPrepIngredients, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  fetchDishIngredients(data: any): Observable<any> {
    return this.http.post<any>(api.fetchDishPrepIngredients, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  deleteDishIngredients(data: any): Observable<any> {
    return this.http.post<any>(api.deleteDishPrepIngredients, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  saveDishPrepStep(data: any): Observable<any> {
    return this.http.post<any>(api.addUpdateDishPrepStep, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  fetchDishPrepStep(data: any): Observable<any> {
    return this.http.post<any>(api.fetchDishPrepSteps, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  deleteDishPrepStep(data: any): Observable<any> {
    return this.http.post<any>(api.deleteDishPrepStep, data, this.httpOptions).pipe(catchError(this.handleError));
  }
  


  handleError(error: HttpErrorResponse):Observable<any>{
    
    if(error==undefined)
    {
      alert('Check Your Internet or Please Try Again');
    }
    else
    {
    alert(error);
    }
    return null;
    }

    handleAndThrowError(error: HttpErrorResponse):Observable<any>{
      return throwError(error);
      }

}

