import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';

@Component({
    selector: 'AlertDialog',
    templateUrl: 'alertpopup.html',
})
export class AlertPopup {

   

    icon:string;
    message:string;


    constructor(public dialogRef: MatDialogRef<AlertPopup>,
         @Inject(MAT_DIALOG_DATA) public data: any)
    {
        this.icon=data.icon;
        this.message=data.message;       
     
    }

    
    
    okClick(): void {
        this.dialogRef.close('Ok');
    }

   }