import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { InventoryItem, InventoryData, InventoryLocations,
   InventoryTransData,
  InventoryTransfer,
  IndividualInventoryLocation,
  InventoryItemData} from '../../views/bean/Inventory';
import { api } from '../../../environments/api';
import { catchError, map, tap } from 'rxjs/operators';
import { AuthenticationService } from '../auth/authentication.service';
import { LoggedInUserAccountDetails } from '../../views/bean/UserAccount';

@Injectable({
  providedIn: 'root'
})
export class ManagementService {

  inventoryItem:InventoryItem;

  getInventoryItem():InventoryItem
  {
    return this.inventoryItem;
  }

  setInventoryItem(item:InventoryItem)
  {
    this.inventoryItem=item;
  }

  httpOptions;

  formDataHttpOptions={
    headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
  }

  constructor(private http: HttpClient,private authService:AuthenticationService) {
    let httpHeadersContentTypeJson= new HttpHeaders({'Content-Type':'application/json',
    "Authorization":"Basic "+this.authService.currentUserValue.authdata});
    this.httpOptions = {
      headers: httpHeadersContentTypeJson
    };
   }

   uploadInventoryItemFile(fileToUpload: File): Observable<InventoryItemData> {
    let httpHeadersContentTypeMultipart= new HttpHeaders({
    "Authorization":"Basic "+this.authService.currentUserValue.authdata});
    var httpOptions1 = {
      headers: httpHeadersContentTypeMultipart
    };
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name,);
    return this.http.post<InventoryItemData>(api.uploadInventoryItemFile, formData,httpOptions1).pipe(catchError(this.handleError));
}

  saveInventoryITem(data: InventoryItem): Observable<InventoryItem> {
    return this.http.post<InventoryItem>(api.saveInventoryItem, data, this.httpOptions).pipe(catchError(this.handleError));
  }

updateInventoryItem(data: InventoryItem): Observable<InventoryItem> {
    return this.http.post<InventoryItem>(api.updateInventoryItem, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  
downloadInventoryItemExcel() {
  this.http.get(api.downloadInventoryItemExcel, this.httpOptions).pipe(catchError(this.handleError));
}

  getMenuData(data: InventoryItem): Observable<InventoryItem> {
    return this.http.post<InventoryItem>(api.getMenuData, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  activateInventoryLocation(data: any): Observable<any> {
    return this.http.post<InventoryItem>(api.activateInventoryLocation, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  deactivateInventoryLocation(data: any): Observable<any> {
    return this.http.post<InventoryItem>(api.deactivateInventoryLocation, data).pipe(catchError(this.handleError));
  }

  deleteInventoryLocation(data: any): Observable<any> {
    return this.http.post<InventoryItem>(api.deleteInventoryLocation, data).pipe(catchError(this.handleError));
  }

  getAllInventoryLocations(): Observable<InventoryData> {
    return this.http.get<InventoryData>(api.getAllInventoryLocations).pipe(catchError(this.handleError));
  }

  getAllActiveInventoryLocations(): Observable<InventoryData> {
    return this.http.get<InventoryData>(api.getAllActiveInventoryLocations).pipe(catchError(this.handleError));
  }

  getAllInventoryItems(): Observable<InventoryData> {
    return this.http.get<InventoryData>(api.getAllInventoryItem).pipe(catchError(this.handleError));
  }

  getAllActiveInventoryItems(): Observable<InventoryData> {
    return this.http.get<InventoryData>(api.getAllActiveInventoryItem).pipe(catchError(this.handleError));
  }

  getAllInventoryItemsByInventoryCode(data:any): Observable<InventoryData> {
    return this.http.post<InventoryData>(api.getAllInventoryItemByInventoryCode,data,this.httpOptions).pipe(catchError(this.handleError));
  }

 

  activateInventoryItem(data: any): Observable<any> {
    return this.http.post<InventoryItem>(api.activateInventoryItem, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  deactivateInventoryItem(data: any): Observable<any> {
    return this.http.post<InventoryItem>(api.deactivateInventoryItem, data).pipe(catchError(this.handleError));
  }

  deleteInventoryItem(data: any): Observable<any> {
    return this.http.post<InventoryItem>(api.deleteInventoryItem, data).pipe(catchError(this.handleError));
  }

  deleteTransaction(data: any): Observable<any> {
    return this.http.post<any>(api.deleteInventoryItemTransaction, data).pipe(catchError(this.handleError));
  }

  getAllInventoryLocationItems(): Observable<InventoryData> {
    return this.http.get<InventoryData>(api.getAllActiveInventoryLocations).pipe(catchError(this.handleError));
  }

  saveLocationData(data: InventoryLocations): Observable<InventoryLocations> {
    return this.http.post<InventoryLocations>(api.saveInventoryLocationItem, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  addTrasactionItem(data: InventoryTransData): Observable<InventoryTransData> {
    return this.http.post<InventoryTransData>(api.transactionItem , data, this.httpOptions).pipe(catchError(this.handleError));
  }

  fetchAllItemBalanceDetails():Observable<InventoryData> {
    return this.http.get<InventoryData>(api.fetchAllItemByBalanceDetails).pipe(catchError(this.handleError));
  }

  fetchAllItemBalanceDetailsByItemCode(data: any): Observable<InventoryData> {
    return this.http.post<InventoryData>(api.fetchAllItemByBalanceDetails, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  transferInventory(data: InventoryTransData): Observable<InventoryLocations> {
    return this.http.post<InventoryLocations>(api.transferInventory, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  fetchAllPendingTransferRequestByInventoryCode(data: any): Observable<InventoryData> {
    return this.http.post<InventoryData>(api.fetchPendingTransferByCode, data,this.httpOptions).pipe(catchError(this.handleError));
  }

  fetchAllPendingTransferRequest(): Observable<InventoryData> {
    return this.http.get<InventoryData>(api.fetchPendingTransfer,this.httpOptions).pipe(catchError(this.handleError));
  }

  fetchAllPendingAcceptRequest(): Observable<InventoryData> {
    return this.http.get<InventoryData>(api.fetchPendingAccepts,this.httpOptions).pipe(catchError(this.handleError));
  }

  fetchAllPendingAcceptRequestByInventoryCode(data: any): Observable<InventoryData> {
    return this.http.post<InventoryData>(api.fetchPendingAcceptsByCode, data,this.httpOptions).pipe(catchError(this.handleError));
  }

  findByItemCode(data: any): Observable<InventoryData> {
    return this.http.post<InventoryData>(api.findByItemCode, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  findByInventoryItemCode(data: any): Observable<InventoryData> {
    return this.http.post<InventoryData>(api.findByInventoryItemCode, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  findByItemCodeByDate(data: any): Observable<InventoryData> {
    return this.http.post<InventoryData>(api.findByItemCodeByDate, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  findByInventoryItemCodeByDate(data: any): Observable<InventoryData> {
    return this.http.post<InventoryData>(api.findByInventoryItemCodeByDate, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  acceptItemRequest(data: any): Observable<InventoryData> {
    return this.http.post<InventoryData>(api.acceptRequestItem, data, this.httpOptions).pipe(catchError(this.handleError));
  }

 rejectItemRequest(data: any): Observable<InventoryData> {
   return this.http.post<InventoryData>(api.rejectRequestItem, data, this.httpOptions).pipe(catchError(this.handleError));
  }
  
  addtransaction(data: any): Observable<InventoryData> {
   return this.http.post<InventoryData>(api.addtransactionItem, data, this.httpOptions).pipe(catchError(this.handleError));
  }

   searchBalanceDetailsByDate(data: any): Observable<InventoryData> {
    return this.http.post<InventoryData>(api.fetchAllItemByBalanceDetailsByDate, data, this.httpOptions).pipe(catchError(this.handleError));
  } 

  searchBalanceDetailsByAny(data: any): Observable<InventoryData> {
    return this.http.post<InventoryData>(api.fetchAllItemByBalanceDetailsAny, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  fetchAllCreditReportallRange(): Observable<InventoryData> {
    return this.http.get<InventoryData>(api.fetchCreditReportAllRange,this.httpOptions).pipe(catchError(this.handleError));
  }
  fetchAllCreditReportByDate(data: FormData): Observable<InventoryData> {
    return this.http.post<InventoryData>(api.fetchCreditReportByDate, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  fetchAllCreditReportByDateInvCode(data: FormData): Observable<InventoryData> {
    return this.http.post<InventoryData>(api.fetchCreditReportByDateInvCode, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  fetchAllDebitReportallRange(): Observable<InventoryData> {
    return this.http.get<InventoryData>(api.fetchDebitReportAllRange,this.httpOptions).pipe(catchError(this.handleError));
  }
  fetchAllDebitReportByDate(data: FormData): Observable<InventoryData> {
    return this.http.post<InventoryData>(api.fetchDebitReportByDate, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  fetchAllDebitReportByDateInvCode(data: FormData): Observable<InventoryData> {
    return this.http.post<InventoryData>(api.fetchDebitReportByDateInvCode, data, this.httpOptions).pipe(catchError(this.handleError));
  }


  fetchItemRecovery(data:any): Observable<InventoryData> {
    return this.http.post<InventoryData>(api.fetchItemRecovery, data, this.httpOptions).pipe(catchError(this.handleError));
  }
  fetchItemRecoveryByDate(data: any): Observable<InventoryData> {
    return this.http.post<InventoryData>(api.fetchItemRecoveryByDate, data, this.httpOptions).pipe(catchError(this.handleError));
  }

 

  fetchAllInventoryItemsRecoveryAnyRange(): Observable<InventoryData> {
    return this.http.get<InventoryData>(api.fetchAllInvItemRecoveryDetailsAnyRange, this.httpOptions).pipe(catchError(this.handleError));
  }
  fetchAllInventoryItemsRecoveryDateRange(data: any): Observable<InventoryData> {
    return this.http.post<InventoryData>(api.fetchAllInvItemRecoveryDetailsByDateRange, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  fetchItemRecoveryInventoryLocation(data:any): Observable<InventoryData> {
    return this.http.post<InventoryData>(api.fetchItemRecoveryInventoryLocation, data, this.httpOptions).pipe(catchError(this.handleError));
  }
  fetchItemRecoveryByDateInventoryLocation(data: any): Observable<InventoryData> {
    return this.http.post<InventoryData>(api.fetchItemRecoveryByDateInventoryLocation, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  fetchAllInventoryItemsRecoveryAnyRangeInventoryLocation(data: any): Observable<InventoryData> {
    return this.http.post<InventoryData>(api.fetchAllInvItemRecoveryDetailsAnyRangeInventoryLocation,data, this.httpOptions).pipe(catchError(this.handleError));
  }
  fetchAllInventoryItemsRecoveryDateRangeInventoryLocation(data: any): Observable<InventoryData> {
    return this.http.post<InventoryData>(api.fetchAllInvItemRecoveryDetailsByDateRangeInventoryLocation, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  handleError(error: HttpErrorResponse):Observable<any>{
  
    if(error==undefined||error.error==undefined||error.error.message==undefined)
    {
      alert('Check Your Internet or Please Try Again');
    }
    else
    {
    alert(error.error.message);
    }
    return null;
    }
}
