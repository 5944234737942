import {Component } from '@angular/core';
import { GlobalVariables } from '../../service/GloabalVariables.service';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../service/auth/authentication.service';
import { MatDialog, MatSnackBar } from '@angular/material';
import { LogoutsummaryComponent } from '../../views/logout/logoutsummary/logoutsummary.component';
import { ChangepasswordComponent } from '../../views/user/changepassword/changepassword.component';
import { MessageComponent } from '../../views/Generic/message/message.component';
import { SupervisorNavItems } from '../../supervisornav';

@Component({
  selector: 'supervisor-dashboard',
  templateUrl: './supervisor-layout.component.html'
})
export class SupervisorLayoutComponent {
  public sidebarMinimized = false;
  public navItems = SupervisorNavItems;

  multipleRoles:boolean;

 /*  constructor(globalVariable:GlobalVariables,private _route: Router)
  {
   /*   if(!globalVariable.getLoggedIn())
    {
      this._route.navigate(['/login']);
    }  

  } */

  constructor(public dialog: MatDialog,private route: Router,private authService:AuthenticationService,private snackBar: MatSnackBar)
  {
   
     

  }

  ngOnInit()
  {
    if (this.authService.currentUserValue==null||this.authService.currentUserValue==undefined||this.authService.currentUserValue.username==null||this.authService.currentUserValue.username==undefined||this.authService.currentUserValue.role.role!="ROLE_SUPERVISOR") { 
      this.route.navigate(['/logout']);
    }

   /*  if(this.authService.currentUserValue.roles.length>1||this.authService.currentUserValue.branches.length>1)
    {
     
      this.multipleRoles=true;
    
    } */

    if(this.authService.currentUserValue.roles!=undefined||this.authService.currentUserValue.roles.length>1)
    {
      this.multipleRoles=true;
    }

    else if(this.authService.currentUserValue.branches!=undefined||this.authService.currentUserValue.branches.length>1)
    {
     
      this.multipleRoles=true;
    
    }
  }

  

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  passwordChange()
  {

    const dialogRef = this.dialog.open(ChangepasswordComponent, {
      width: '800px',maxHeight:'600px'
    });
  
    
     dialogRef.afterClosed().subscribe((data) => {
  
      if(data!=null)
      {
        this.authService.currentUserValue.password=data.newPassword;
        this.authService.loggedIn(this.authService.currentUserValue);
        this.openSnackBar("Password Change Successfully");
        dialogRef.close();
      }
      
  
  
  });

  }

  openSnackBar(message: string) {
    this.snackBar.openFromComponent(MessageComponent, {
      data: message,
      duration: 5 * 1000
    });
  }


  logout()
{
  
  console.log("logging Out");
  this.route.navigate(['/logout']);

}

roleBranchChange()
  {
    this.route.navigate(['/afterlogin']);
  }
  
}




