import { Component, Inject} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InventoryCreditData} from "../../bean/Inventory"
import { ManagementService } from "../../../service/inventory/inventory.service";
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';


@Component({
    selector: 'PopUpCredit',
    templateUrl: 'credit.html',
    styleUrls: ['popUpStyle.css'],
})
export class PopUpCredit{ 

    options:string []=[];
    myControl = new FormControl();
    filteredOptions: Observable<string[]>;

    submitted:boolean=false;
    creditForm: FormGroup;

    constructor(public dialogRef: MatDialogRef<PopUpCredit>,
        @Inject(MAT_DIALOG_DATA) public data: InventoryCreditData,
        private mService: ManagementService,private formBuilder: FormBuilder
        )
    {

        
    this.creditForm = this.formBuilder.group({
        units: ['', Validators.required],
        quantity: ['', Validators.required],
        totalQuantity: ['', Validators.required],
        price: ['', Validators.required]
    });
  
    this.submitted=false;
    this.creditForm.reset();

        this.options=[];

      /*   this.mService.getAllInventoryItems().subscribe(data=>{
         var  value = data.responseObject as Inventory[];
         value.forEach(val=>{
             this.options.push(val.itemCode);
         });

         console.log(this.options);
        });

        this.filteredOptions = this.myControl.valueChanges
            .pipe(
                startWith(''),
                map(value => this._filter(value))
            ); */
    }
    
    onNoClick(): void {
        this.dialogRef.close();
    }

    onSubmitClick(): void {
        this.submitted=true;
        if(!this.creditForm.valid)
        {
          return;
        }
       
        this.dialogRef.close(this.data);
    }

    private _filter(value: string): string[] {
        const filterValue = value.toLowerCase();

        return this.options.filter(option => option.toLowerCase().includes(filterValue));
    }
}