import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [
  {
    title: true,
    name: 'Inventory Management'
  },
  {
    name: 'Inventory System',
    url: 'iadmin',
    icon: 'icon-star',
    children: [
      {
        name: 'Inventory Items',
        url: 'inventory/iitemslist',
        icon: 'icon-layers'
      },
      {
        name: 'Outgoing Transfers',
        url: 'inventory/itransfers',
        icon: 'icon-bell'
      },
      {
        name: 'Incoming Transfers',
        url: 'inventory/incoming',
        icon: 'icon-bell'
      },
      {
        name: 'Manage Pitty Cash',
        url: 'viewpittycash',
        icon: 'icon-layers'
      },
      
      
    ]
  },
  {
    name: 'Kitchen Requests',
    url: 'sadmin',
    children: [
      {
        name: 'Manage Inventory Debit Requests',
        url: 'receipedebits'
      }
    ]
  },
  {
    name: 'Fixed Asset Management',
    url: 'sadmin',
    children: [
      {
        name: 'Fixed Assets List',
        url: 'inventory/assetslist'
      }
    ]
  }
];
