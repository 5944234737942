import { Component, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { FixedAssetService } from '../../../../service/fixedasset/fixedassets.service';
import { AssetItemTransaction } from '../../../bean/Assets';


@Component({
    selector: 'FixedAssetPopUpCredit',
    templateUrl: 'fixedassetcreditpopup.html',
    
})
export class FixedAssetCreditPopup{ 

    options:string []=[];
    myControl = new FormControl();
    filteredOptions: Observable<string[]>;

    submitted:boolean=false;
    creditForm: FormGroup;

    constructor(public dialogRef: MatDialogRef<FixedAssetCreditPopup>,
        @Inject(MAT_DIALOG_DATA) public data: AssetItemTransaction,private formBuilder: FormBuilder
        )
    {
          
    this.creditForm = this.formBuilder.group({
        count: ['', Validators.required]
    });
  
    this.submitted=false;
    this.creditForm.reset();

        this.options=[];

    }
    
    onCloseClick(): void {
        this.dialogRef.close();
    }

    onSubmitClick(): void {
        this.submitted=true;
        if(!this.creditForm.valid)
        {
          return;
        }
        this.data.transactionType='CREDIT';
        this.dialogRef.close(this.data);
    }

}