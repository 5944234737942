import { formatDate } from '@angular/common';
import { Route } from '@angular/compiler/src/core';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { api } from '../../../../../environments/api';
import { AuthenticationService } from '../../../../service/auth/authentication.service';
import { FixedAssetService } from '../../../../service/fixedasset/fixedassets.service';
import { ManagementService } from '../../../../service/inventory/inventory.service';
import { AssetItem, AssetItemTransaction } from '../../../bean/Assets';
import { InventoryItem, InventoryLocations, TransType } from '../../../bean/Inventory';
import { MessageComponent } from '../../../Generic/message/message.component';
import { FixedAssetCreditPopup } from '../../popups/fixedassets/FixedAssetCreditPopup';
import { FixedAssetDebitPopup } from '../../popups/fixedassets/FixedAssetDebitPopup';
import { FixedAssetExcelUploadPopup } from '../../popups/fixedassets/FixedAssetExcelUploadPopup';
import { PopupUploadAssetItemFile } from '../../popups/PopupUploadAssetItemFile';

@Component({
  selector: 'app-fixedassetslist',
  templateUrl: './fixedassetslist.component.html',
  styleUrls: ['./fixedassetslist.component.css']
})
export class FixedassetslistComponent implements OnInit {

  private inventoryLocations:InventoryLocations[];

  inventoryCode:string;
  inventoryName:string;

  submitted:boolean=false;

  addItems: boolean = false;

  allAssetsItemList: AssetItem[] = [];
  tempAssetsItemList: AssetItem[] = [];
  updateTyep: boolean = false;

  actions:string[]=['Update','Activate','De-Activate','Delete'];


  model: AssetItem = new AssetItem();

  urlData:string;

  superAdmin:boolean=false;
  superVisor:boolean=false;
  inventoryAdmin:boolean=false;

  fixedAssetForm: FormGroup;

  debitData:AssetItemTransaction;
  creditData:AssetItemTransaction;


  selectedLocationIdIndex:number;

  
  constructor(private formBuilder: FormBuilder,private authService:AuthenticationService,private inventoryService: ManagementService,
              private fixedAssetService: FixedAssetService, 
              private _snackBar: MatSnackBar,public dialog: MatDialog,
              private  _router: Router) { 

    this.superAdmin=false;
    this.superVisor=false;
    this.inventoryAdmin=false;
   
    if(this.authService.currentUserValue.role.role=='ROLE_SUPER_ADMIN')
    {
       this.superAdmin=true;
    }
    else if(this.authService.currentUserValue.role.role=='ROLE_SUPERVISOR')
    {
    this.superVisor=true;
    this.inventoryCode=this.authService.currentUserValue.role.details;
    }
    else if(this.authService.currentUserValue.role.role=='ROLE_INVENTORY_ADMIN')
    {
    this.inventoryAdmin=true;
    this.inventoryCode=this.authService.currentUserValue.role.details;
    }
  }

  
  onLocationSelected()
  {
   
    if(this.selectedLocationIdIndex!=-1)
    {
      if(this.selectedLocationIdIndex>0)
      {
        this.inventoryCode=this.inventoryLocations[this.selectedLocationIdIndex-1].inventoryCode;
        this.inventoryName=this.inventoryLocations[this.selectedLocationIdIndex-1].inventoryName;
      }
    }
   
  }

  search()
  {
    this.allAssetsItemList=[];
    this.tempAssetsItemList=[];
   
    if(this.selectedLocationIdIndex==0)
    {
      console.log('Search All Location');
      this.fetchAllFixedAssets();
    }
    else
    {
     console.log('Search All Inventory Reports='+this.inventoryCode);
    this.fetchAllFixedAssetsInventoryCode();
    }
  }

  ngOnInit() {
    

    this.fixedAssetService.setAssetItem(null);
    this.fixedAssetService.setInventoryCode(null);

    this.fixedAssetForm = this.formBuilder.group({
      assetCode: ['', Validators.required],
      assetName: ['', Validators.required],
      totalQuantity:['',Validators.required],
      purchaseDate:['',Validators.required],
      warrantyEndDate:['',Validators.required]
  });

  this.submitted=false;
  this.fixedAssetForm.reset();

  if(this.superAdmin)
  {
    this.selectedLocationIdIndex=0;
  this.inventoryCode=null;
  }

  

  if(this.authService.currentUserValue.role.role=="ROLE_SUPER_ADMIN")
  {
    
  this.inventoryService.getAllInventoryLocationItems().subscribe(data => {
    console.log(data.responseObject);
    this.inventoryLocations = data.responseObject;
  });

  this.fetchAllFixedAssets();
 
  }
  else  if(this.authService.currentUserValue.role.role=="ROLE_INVENTORY_ADMIN"||this.authService.currentUserValue.role.role=="ROLE_SUPERVISOR")
  {
    var param={"inventoryCode":this.inventoryCode};
    this.fixedAssetService.fixedAssetItemFetchAllActiveInventoryCode(param).subscribe(data => {
      this. allAssetsItemList = data.responseObject;
      this.tempAssetsItemList=data.responseObject;
      console.log(data);
    });

    this.fetchAllFixedAssetsInventoryCode();
    
  }

    this.addItems = false;
    this.updateTyep = false;
    this.model = new AssetItem();
    this.model.totalQuantity=0;

    var currentDate=new Date();
    this.model.purchaseDate= formatDate(currentDate, 'yyyy-MM-dd', 'en-US');
    this.model.warrantyEndDate= formatDate(currentDate, 'yyyy-MM-dd', 'en-US');

  
    
  }

  fetchAllFixedAssets()
  {
    this.fixedAssetService.fixedAssetItemFetchAll().subscribe(data => {
      this. allAssetsItemList = data.responseObject;
      this.tempAssetsItemList=data.responseObject;
      console.log(this. allAssetsItemList);
    });
  }

  fetchAllFixedAssetsInventoryCode()
  {
    var param={"inventoryCode":this.inventoryCode};
    this.fixedAssetService.fixedAssetItemFetchAllInventoryCode(param).subscribe(data => {
      this. allAssetsItemList = data.responseObject;
      this.tempAssetsItemList=data.responseObject;
      console.log(this. allAssetsItemList);
    });
  }


  addItem() {

   

    this.addItems = !this.addItems;
    this.updateTyep = false;
    this.model = new AssetItem();
  }

  update(data: AssetItem) {
    this.addItems = !this.addItems;
    this.updateTyep = true;
    this.model=data;
  }


  deactivate(data:AssetItem)
  {
 
    this.fixedAssetService.deactivateFixedAssetItem(data).subscribe(result=>{
      this.openSnackBar(result.message);
      this.search();
    });

  }




  activate(data:InventoryItem)
  {
   
    this.fixedAssetService.activateFixedAssetItem(data).subscribe(result => {
      this.openSnackBar(result.message);
      this.search();
    });
  }


  delete(data: InventoryItem) {
    this.fixedAssetService.deleteFixedAssetItem(data).subscribe(result => {
      this.openSnackBar(result.message);
      this.search();
    });
  }




  saveAssetItem() {

    this.submitted=true;
    if(this.fixedAssetForm.invalid)
    {
      return;
    }

    this.model.createdUser=this.authService.currentUserValue.firstName+" "+this.authService.currentUserValue.lastName;


    this.fixedAssetService.addNewAssetItem(this.model).subscribe(data => {
        this.openSnackBar("Data Updated Successfully ....!!!!");
        this.addItem();
        this.search();
      });
  
  }



  applyFilter(event: Event) {

    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    var filterResult:AssetItem[] = this.tempAssetsItemList.filter(tran => 
      tran.assetName.toLowerCase().startsWith(filterValue)
      ||tran.assetCode.toLowerCase().startsWith(filterValue));
  
      if(filterValue=='')
      {
        this.  allAssetsItemList=this.tempAssetsItemList;
      }
      else
      {
      if(filterResult.length>0)
      {
        this.allAssetsItemList=filterResult;
      }
      else
      {
        this.allAssetsItemList=this.tempAssetsItemList;
      }
    }
   
  }



  openSnackBar(message:string) {
    this._snackBar.openFromComponent(MessageComponent, {
      data: message,
      duration:5*1000
    });
  }


  transcations(data:AssetItem)
  {
    this.fixedAssetService.setAssetItem(data);
    this.fixedAssetService.setInventoryCode(this.inventoryCode);
    console.log(this.inventoryCode)
    this.urlData=this._router.url;
    if(this.superAdmin)
    {
     
      this._router.navigate(['/superadmin/inventory/assetitemview'], { state:data});
    }
    else if(this.superVisor)
    {
     
      this._router.navigate(['/supervisor/inventory/assetitemview'], { state:data});
    }
    else
    {
   
      this._router.navigate(['/iadmin/inventory/assetitemview'], { state:data});
    }
  
  }


  showFileUploadPopup()
  {
    var param={"inventoryCode":this.inventoryCode,"inventoryName":this.inventoryName};
    const dialogRef = this.dialog.open(FixedAssetExcelUploadPopup, {
      width: '450px',
      data:param
    });
    dialogRef.afterClosed().subscribe(data=>{
      this.ngOnInit();
    });
  }

  downloadAssetItemExcel()
  {
    //this.mService.downloadInventoryItemExcel();
    window.open(api.downloadInventoryItemExcel, "_blank");
  }

  openCreditDialog(invItem:AssetItem)
  {

    this.creditData=new AssetItemTransaction();
    this.creditData.assetCode = invItem.assetCode;
    this.creditData.assetName = invItem.assetName;
    this.creditData.transactionType = TransType.CREDIT.toString();
    const dialogRef = this.dialog.open(FixedAssetCreditPopup, {
      maxWidth: '600px', maxHeight:'600px',
      data: this.creditData
    });

    dialogRef.afterClosed().subscribe(result => {

      this.creditData = result;
    this.creditData.inventoryCode=this.authService.currentUserValue.role.details;
    this.creditData.transactionType = TransType.CREDIT.toString();
    this.creditData.createdUser=this.authService.currentUserValue.firstName+" "+this.authService.currentUserValue.lastName;
      console.log( this.creditData);
      if (result == undefined || result.count == undefined )
      {
        this.openSnackBar('Count in not Defined');
        return;
      } 

      this.fixedAssetService.addNewAssetItemTransaction(this.creditData).subscribe(value => {
        this.openSnackBar(value.message+"....!!!!");
        this.search();
      });
    });

   
  }

  openDeditDialog(invItem:AssetItem)
  {

    this.creditData=new AssetItemTransaction();
    this.creditData.assetCode = invItem.assetCode;
    this.creditData.assetName = invItem.assetName;
    this.creditData.transactionType = TransType.DEBIT.toString();
    const dialogRef = this.dialog.open(FixedAssetDebitPopup, {
      maxWidth: '600px', maxHeight:'600px',
      data: this.creditData
    });

    dialogRef.afterClosed().subscribe(result => {

      this.creditData = result;
    this.creditData.inventoryCode=this.authService.currentUserValue.role.details;
    this.creditData.transactionType = TransType.DEBIT.toString();
    this.creditData.createdUser=this.authService.currentUserValue.firstName+" "+this.authService.currentUserValue.lastName;
      console.log( this.creditData);
      if (result == undefined || result.count == undefined )
      {
        this.openSnackBar('Count in not Defined');
        return;
      } 

      this.fixedAssetService.addNewAssetItemTransaction(this.creditData).subscribe(value => {
        this.openSnackBar(value.message+"....!!!!");
        this.search();
      });
    });

   
  }

}
