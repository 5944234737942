import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { api } from '../../../environments/api';
import { HosiptialResponseData } from '../../views/bean/Hospital';
import { LogoutCashierSummaryDate } from '../../views/bean/LogoutCashierSummaryDate';
import { AuthenticationService } from '../auth/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class LogoutService {

  httpOptions;

  formDataHttpOptions = {
    headers: new HttpHeaders({ 'Content-Type': undefined })
  }

  constructor(private http: HttpClient,private authService:AuthenticationService) {
   
    let httpHeadersContentTypeJson= new HttpHeaders({'Content-Type':'application/json',
    "Authorization":"Basic "+this.authService.currentUserValue.authdata});
    this.httpOptions = {
      headers: httpHeadersContentTypeJson
    };
   }

  logoutSummary(data: LogoutCashierSummaryDate): Observable<HosiptialResponseData> {
    return this.http.post<HosiptialResponseData>(api.logoutsummary, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  logoutSummaryByBranchCode(data: LogoutCashierSummaryDate): Observable<HosiptialResponseData> {
    return this.http.post<HosiptialResponseData>(api.logoutsummaryByBranchCode, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  handleError(error: HttpErrorResponse):Observable<any>{
    
    if(error==undefined)
    {
      alert('Check Your Internet or Please Try Again');
    }
    else
    {
    alert(error);
    }
    return null;
    }

}
