import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { InventoryDebitData } from '../../bean/Inventory';
import { ManagementService } from '../../../service/inventory/inventory.service';
import { startWith, map } from 'rxjs/operators';
import { MenuService } from '../../../service/menuServ/menu.service';
import { FoodItem } from '../../bean/Menu';
import { MatSnackBar } from '@angular/material';
import { MessageComponent } from '../../Generic/message/message.component';

@Component({
    selector: 'PopUpDebit',
    templateUrl: 'debit.html',
})
export class PopUpDebit {

    options:string []=[];
    myControl = new FormControl();
    filteredOptions: Observable<string[]>;

    allFoodItemData:FoodItem[]=[];

    submitted:boolean=false;
    debitForm: FormGroup;

    selectedFoodItemCode:string;

    constructor(public dialogRef: MatDialogRef<PopUpDebit>,  private snackBar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) public data: InventoryDebitData,private inventoryService:ManagementService,private mServiceMenu: MenuService,private formBuilder: FormBuilder
        )
    {      

        this.debitForm = this.formBuilder.group({
          //  units: ['', Validators.required],
            quantity: ['', Validators.required],
          //  totalQuantity: ['', Validators.required],
            foodItemCode: ['', Validators.required]
        });
      
        this.selectedFoodItemCode='None';
        this.submitted=false;
        this.debitForm.reset();

            this.mServiceMenu.fetchAllFoodItemlist().subscribe(data=>{
                console.log(data.responseObject);
                this.allFoodItemData = data.responseObject;
             });

         

    }

  
    
    onNoClick(): void {
        this.dialogRef.close();
    }

    openSnackBar(message:string) {
        this.snackBar.openFromComponent(MessageComponent, {
          data: message,
          duration:5*1000
        });
      }
    

    onSubmit(): void {

        this.submitted=true;
        if(!this.debitForm.valid&&this.selectedFoodItemCode=='None')
        {
          return;
        }

        if(this.selectedFoodItemCode=='WASTAGE'&&(this.data.comment==""||this.data.comment==null))
        {
            this.openSnackBar("Comments Required");
            return;
        }

        if(this.selectedFoodItemCode=='OTHER'&&(this.data.comment==""||this.data.comment==null))
        {
            this.openSnackBar("Comments Required");
            return;
        }

        if(this.data.unitQuantity>0)
        {
        this.data.units=this.calculataUnitQuantity();
        console.log("units"+this.data.units);
        this.data.totalQuantity=this.data.unitQuantity;
        console.log("total"+this.data.totalQuantity);
        this.data.foodItemCode=this.selectedFoodItemCode;
        this.dialogRef.close(this.data);
        }
    }

    calculataUnitQuantity():number
    {
       
        var debitQuantity=+this.data.unitQuantity;
        var totalUnits=+this.data.units;
        var totalQuantity=+this.data.totalQuantity;
        var debitUnits=(totalUnits*debitQuantity)/totalQuantity;

        return debitUnits;
       

    }

   

}