import { Injectable } from '@angular/core';
import { BehaviorSubject} from 'rxjs';
import {LoggedInUserAccountDetails} from '../../views/bean/UserAccount';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<LoggedInUserAccountDetails>;
    public currentUser: LoggedInUserAccountDetails;

    constructor() {
        this.currentUserSubject = new BehaviorSubject<LoggedInUserAccountDetails>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.value;
    }

    public get currentUserValue(): LoggedInUserAccountDetails {
        return this.currentUserSubject.value;
    }

    loggedIn(user:LoggedInUserAccountDetails) {
        user.authdata = window.btoa(user.username + ':' + user.password);
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUser=user;
        this.currentUserSubject.next(user);
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
    }
}