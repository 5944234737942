import { Component, OnInit } from '@angular/core';
import { ViewChild, Input } from '@angular/core';
import { ManagementService } from '../../../service/inventory/inventory.service';
import { InventoryData, CreditReport, CreditTransaction, DebitReport, InventoryLocations} from '../../bean/Inventory';
import { MatRadioButton } from '@angular/material';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MessageComponent } from "../../Generic/message/message.component";
import { MatDialog } from '@angular/material/dialog';
import { TransferInventory } from "../popups/transferInventory";
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-report-details',
  templateUrl: './report-details.component.html',
  styleUrls: ['./report-details.component.css']
})
export class ReportDetailsComponent implements OnInit {
  
  allInventoryData: CreditTransaction[] = [];


  model: DebitReport = new DebitReport();

  selectedLocationIdIndex:number;
  selectedInventoryName:string
  selectedInventoryCode:string
  private inventoryLocations:InventoryLocations[];


  constructor(private mService: ManagementService, private _snackBar: MatSnackBar,
    public dialog: MatDialog) {

  }

  

  ngOnInit() {

    this.model = new DebitReport();
    /* this.mService.fetchAllDebitReportallRange().
      subscribe(result => {
        this.model= result.responseObject as unknown as DebitReport;
        this.allInventoryData = this.model.debitTransactions;
        this.dataSource = new MatTableDataSource(this.allInventoryData);
        console.log(this.allInventoryData);
        this.loadingSpinner = false;
      }); */

      var currentDate=new Date();
      this.fromDate= formatDate(currentDate, 'yyyy-MM-dd', 'en-US');
      this.toDate= formatDate(currentDate, 'yyyy-MM-dd', 'en-US');
     // this.seachByDate();
  


    this.selectedLocationIdIndex=0;
    this.fetchAllInventoryLocations();
  
  }




  applyFilter(event: Event) {
    
  }

  onLocationSelected()
  {
    if(this.selectedLocationIdIndex!=-1)
    {
      if(this.selectedLocationIdIndex>0)
    {
        this.selectedInventoryCode=this.inventoryLocations[this.selectedLocationIdIndex-1].inventoryCode;
        this.selectedInventoryName=this.inventoryLocations[this.selectedLocationIdIndex-1].inventoryName;
    }
    
    }
   
  }

  fetchAllInventoryLocations()
  {
    this.mService.getAllActiveInventoryLocations().subscribe(data => {
      console.log(data.responseObject);
      this.inventoryLocations = data.responseObject;
    });
  }

  openSnackBar(message: string) {
    this._snackBar.openFromComponent(MessageComponent, {
      data: message,
      duration: 5 * 1000
    });
  }


  fromDate:string;
  toDate:string;

  search()
  {
    if(this.selectedLocationIdIndex==0)
    {
      this.seachByDate();
    }
    else
    {
      this.searchByDateRangeInventoryCode();

    }
  }
  
  seachByDate() {
    
    var data: any = {
      "startDate": this.fromDate,
      "endDate": this.toDate
    }
    this.mService.fetchAllDebitReportByDate(data).subscribe(data => {

      this.model= data.responseObject as unknown as DebitReport;
      console.log(this.model);
      this.allInventoryData = this.model.debitTransactions;
       this.updateWeightDetails();
      //    this.dataSource = new MatTableDataSource(filteredData.itemTransactions);
    });

  }

  searchByDateRangeInventoryCode() {
    
    var data: any = {
      "inventoryCode":this.selectedInventoryCode,
      "startDate": this.fromDate,
      "endDate": this.toDate
    }
    this.mService.fetchAllDebitReportByDateInvCode(data).subscribe(data => {

      this.model= data.responseObject as unknown as DebitReport;
      console.log(this.model);
      this.allInventoryData = this.model.debitTransactions;
       this.updateWeightDetails();
      //    this.dataSource = new MatTableDataSource(filteredData.itemTransactions);
    });

  }

  updateWeightDetails()
  {
   
    for(let i=0;i<this.allInventoryData.length;i++)
    {
      if(this.allInventoryData[i].quantity<1)
    {
     
      if(this.allInventoryData[i].measurementUnit.toLowerCase()=='kg')
      {
        this.allInventoryData[i].quantity=this.allInventoryData[i].quantity*1000;
        this.allInventoryData[i].measurementUnit='GRAMS';
      }
      else if(this.allInventoryData[i].measurementUnit.toLowerCase()=='litres')
      {
        this.allInventoryData[i].quantity=this.allInventoryData[i].quantity*1000;
        this.allInventoryData[i].measurementUnit='ML';
      }
    }

    if(this.allInventoryData[i].balance<1)
    {
     
      if(this.allInventoryData[i].measurementUnit.toLowerCase()=='kg')
      {
        this.allInventoryData[i].balance=this.allInventoryData[i].balance*1000;
        this.allInventoryData[i].measurementUnit='GRAMS';
      }
      else if(this.allInventoryData[i].measurementUnit.toLowerCase()=='litres')
      {
        this.allInventoryData[i].balance=this.allInventoryData[i].balance*1000;
        this.allInventoryData[i].measurementUnit='ML';
      }
    }

    
    

    }
  }

}

