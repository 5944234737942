import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { MatSnackBar } from '@angular/material';
import { FixedAssetService } from '../../../../service/fixedasset/fixedassets.service';
import { MessageComponent } from '../../../Generic/message/message.component';
import { AuthenticationService } from '../../../../service/auth/authentication.service';

@Component({
    selector: 'FixedAssetExcelUploadPopup',
    templateUrl: 'fixedassetexcelupload.html',

})
export class FixedAssetExcelUploadPopup {

    fileToUpload: File = null;

    
   
    submitted:boolean=false;
    fixedAssetUploadForm: FormGroup;

    inventoryCode:string;
    inventoryName:string;

    createdUser:string;


    constructor(public dialogRef: MatDialogRef<FixedAssetExcelUploadPopup>,
         @Inject(MAT_DIALOG_DATA) public data:any,private _snackBar: MatSnackBar,private formBuilder: FormBuilder,
        private fixedassetservice: FixedAssetService,private authService:AuthenticationService)
    {
        this.inventoryCode=this.data.inventoryCode;
        this.inventoryName=this.data.inventoryName;
        this.createdUser=this.authService.currentUserValue.firstName+" "+this.authService.currentUserValue.lastName;
    }

    handleFileInput(files: FileList) {
        this.fileToUpload = files.item(0);
    }

  
    onSubmit()
    {
        this.submitted=true;
        if(this.fixedAssetUploadForm.invalid&&this.submitted)
        return
        else
        this.fixedassetservice.uploadAssetItemExcel(this.fileToUpload,this.createdUser).subscribe(data => {
            if(data.responseCode===true)
            {
                this.openSnackBar(data.message);
                this.dialogRef.close("success");
            }
            else{
                this.openSnackBar('Error Uploading Excel..');
            
            }

            this.ngOnInit();
        })
    }

    ngOnInit() {

        this.submitted=false;
        this.fixedAssetUploadForm = this.formBuilder.group({
         
          file: ['', Validators.required],
    
      });
    
      this.fixedAssetUploadForm.reset();
    }

    openSnackBar(message: string) {
        this._snackBar.openFromComponent(MessageComponent, {
          data: message,
          duration: 5 * 1000
        });
      }

   

  

  
    
    onCloseClick(): void {
        this.dialogRef.close(null);
    }


}