import { Component, OnInit } from '@angular/core';

import { FormBuilder } from '@angular/forms';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { MenuService } from '../../../service/menuServ/menu.service';
import { FoodItem } from '../../bean/Menu';
import { MessageComponent } from '../../Generic/message/message.component';


@Component({
  selector: 'app-receipelist',
  templateUrl: './receipelist.component.html',
  styleUrls: ['./receipelist.component.css']
})
export class ReceipelistComponent implements OnInit {

  addItems: boolean = false;
  updateTyep: boolean = false;

  allFoodItemData: FoodItem[] = [];
  tempFoodItemData: FoodItem[] = [];
 


  constructor(private mservice: MenuService,private formBuilder: FormBuilder,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    public router: Router) {
  }

  ngOnInit() {

      this.mservice.fetchAllFoodItemlist().subscribe(data => {
        console.log(data);
        this.allFoodItemData = data.responseObject as FoodItem[];
        this.tempFoodItemData = data.responseObject as FoodItem[];
     });

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    var filterResult:FoodItem[] = this.tempFoodItemData.filter(tran => 
      tran.foodItemCode.toLowerCase().startsWith(filterValue) ||tran.foodItemName.toLowerCase().startsWith(filterValue));
  
      if(filterValue=='')
      {
        this. allFoodItemData=this.tempFoodItemData;
      }
      else
      {
      if(filterResult.length>0)
      {
  this.allFoodItemData=filterResult;
      }
      else
      {
        this. allFoodItemData=this.tempFoodItemData;
      }
    }
  }



  openSnackBar(message: string) {
    this._snackBar.openFromComponent(MessageComponent, {
      data: message,
      duration: 5 * 1000
    });
  }

}
