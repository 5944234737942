import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { ManagementService } from '../../../service/inventory/inventory.service';
import { FoodItemRecoverSumary, InventoryItem, InventoryLocations, ItemConsumption } from '../../bean/Inventory';
import { MessageComponent } from '../../Generic/message/message.component';

@Component({
  selector: 'app-itemrecoveryreport',
  templateUrl: './itemrecoveryreport.component.html',
  styleUrls: ['./itemrecoveryreport.component.css']
})
export class ItemrecoveryreportComponent implements OnInit {

  fromDate: string;
  toDate: string;
  allFoodItemSummaryData: FoodItemRecoverSumary[] = [];
  actions: string[] = ['Update', 'Activate', 'De-Activate', 'Delete'];
  model: ItemConsumption = new ItemConsumption();

  selectedSearchType:string;
  searchTypes: string[] = ['Overall Summary', 'Summary By Date'];
  isOverallSummary:boolean;

  selectedinventoryItemCodeIndex:number;

  selectedinventoryItemCode:string;

  selectedinventoryItemName:string;

  selectedinventoryItemMeasurementUnit:string;

  inventoryItemSelected:boolean;

  allInventoryItemList:InventoryItem[]=[];

  selectedLocationIdIndex:number;
  selectedInventoryName:string
  selectedInventoryCode:string
  private inventoryLocations:InventoryLocations[];


  constructor(private mService: ManagementService, 
    private _snackBar: MatSnackBar,
    public dialog: MatDialog) {

  }

  onInventoryItemSelected()
  {
   
    this.inventoryItemSelected=false;
    if(this.selectedinventoryItemCodeIndex!=-1)
    {
      this.selectedinventoryItemCode=this. allInventoryItemList[this.selectedinventoryItemCodeIndex].itemCode;
      this.selectedinventoryItemName=this. allInventoryItemList[this.selectedinventoryItemCodeIndex].name;
      this.selectedinventoryItemMeasurementUnit=this.allInventoryItemList[this.selectedinventoryItemCodeIndex].measurementUnit;
    this.inventoryItemSelected=true;
    }
   
  }

  onLocationSelected()
  {
    if(this.selectedLocationIdIndex!=-1)
    {
      if(this.selectedLocationIdIndex>0)
    {
        this.selectedInventoryCode=this.inventoryLocations[this.selectedLocationIdIndex-1].inventoryCode;
        this.selectedInventoryName=this.inventoryLocations[this.selectedLocationIdIndex-1].inventoryName;
    }
    
    }
   
  }

  fetchAllInventoryLocations()
  {
    this.mService.getAllActiveInventoryLocations().subscribe(data => {
      console.log(data.responseObject);
      this.inventoryLocations = data.responseObject;
    });
  }


  ngOnInit() {

    this.model = new ItemConsumption();

    var currentDate=new Date();
    this.fromDate= formatDate(currentDate, 'yyyy-MM-dd', 'en-US');
    this.toDate= formatDate(currentDate, 'yyyy-MM-dd', 'en-US');

    this.isOverallSummary=true;
    this.selectedSearchType=this.searchTypes[0];

    this.selectedLocationIdIndex=0;

    this.fetchAllInventoryItems();   
    this.fetchAllInventoryLocations();
  
  }

  fetchAllInventoryItems()
  {
    this.mService.getAllInventoryItems().subscribe(data=>{
      console.log(data.responseObject);
      this.allInventoryItemList = data.responseObject;
   });
  }

  onSearchOptionChange()
  {
    if(this.selectedSearchType==this.searchTypes[0])
    {
      this.isOverallSummary=true;
    }
    else
    {
      this.isOverallSummary=false;
    }
   this.selectedSearchType=this.isOverallSummary?this.searchTypes[0]:this.searchTypes[1];
  }

 


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
 
  }



  openSnackBar(message: string) {
    this._snackBar.openFromComponent(MessageComponent, {
      data: message,
      duration: 5 * 1000
    });
  }


 


  fetchAllSummary()
  {
    

    if(!this.inventoryItemSelected)
    {
      this.openSnackBar('Select Inventory Item')
    }

    if(this.selectedLocationIdIndex==0)
    {
      var data:any={
        "itemCode": this.allInventoryItemList[this.selectedinventoryItemCodeIndex].itemCode
      }
      this.mService.fetchItemRecovery(data).
        subscribe(result => {
          this.model = result.responseObject as unknown as ItemConsumption;
          this. allFoodItemSummaryData = this.model.fooditemlist;
          console.log(this. allFoodItemSummaryData);
          this.updateWeightDetails();
      
        });
    }
    else
    {
      this.fetchAllSummaryByInventoryCode();
    }
    
  }

  fetchAllSummaryByInventoryCode()
  {
    var data:any={
      "itemCode": this.allInventoryItemList[this.selectedinventoryItemCodeIndex].itemCode,
      "inventoryCode":this.selectedInventoryCode
    }
    this.mService.fetchItemRecoveryInventoryLocation(data).
      subscribe(result => {
        this.model = result.responseObject as unknown as ItemConsumption;
        this. allFoodItemSummaryData = this.model.fooditemlist;
        console.log(this. allFoodItemSummaryData);
        this.updateWeightDetails();
    
      });
  }

  searchByDate() {

    if(!this.inventoryItemSelected)
    {
      this.openSnackBar('Select Inventory Item');
      return ;
    }

    if(this.selectedLocationIdIndex==0)
    {
    var data: any = {
      "itemCode":  this.allInventoryItemList[this.selectedinventoryItemCodeIndex].itemCode,
      "startDate": this.fromDate,
      "endDate": this.toDate
    }
    this.mService.fetchItemRecoveryByDate(data).subscribe(data => {

      var model = data.responseObject as unknown as ItemConsumption;
      this.allFoodItemSummaryData = model.fooditemlist;
      console.log(this. allFoodItemSummaryData);
      this.updateWeightDetails();
    });
     
  }
  else
  {
    var data: any = {
      "inventoryCode":  this.selectedInventoryCode,
      "itemCode":  this.allInventoryItemList[this.selectedinventoryItemCodeIndex].itemCode,
      "startDate": this.fromDate,
      "endDate": this.toDate
    }
    this.mService.fetchItemRecoveryByDateInventoryLocation(data).subscribe(data => {

      var model = data.responseObject as unknown as ItemConsumption;
      this.allFoodItemSummaryData = model.fooditemlist;
      console.log(this. allFoodItemSummaryData);
      this.updateWeightDetails();
    });
  }

  }

  updateWeightDetails()
  {

     //Debit Count
     if(this.model.totalDebitCount<1)
     {
       this.model.totalDebitCount=(+(this.model.totalDebitCount)*1000);
       
     }
     if(this.selectedinventoryItemMeasurementUnit.toLowerCase()=='kg')
     {
       this.model.debitCountMeasurementUnit='GRAMS';
     }
     else if(this.selectedinventoryItemMeasurementUnit.toLowerCase()=='litres')
     {
      this.model.debitCountMeasurementUnit='ML';
     }
 
      //Missing Count
      if(this.model.totalMissingCount<1)
      {
        this.model.totalMissingCount=this.model.totalMissingCount*1000;
        
      }
      if(this.selectedinventoryItemMeasurementUnit.toLowerCase()=='kg')
      {
        this.model.missingCountMeasurementUnit='GRAMS';
      }
      else if(this.selectedinventoryItemMeasurementUnit.toLowerCase()=='litres')
      {
        this.model.missingCountMeasurementUnit='ML';
      }
 
       //Recovery Count
       if(this.model.totalRecoveryCount<1)
       {
        this.model.totalRecoveryCount=this.model.totalRecoveryCount*1000;
         
       }
       if(this.selectedinventoryItemMeasurementUnit.toLowerCase()=='kg')
       {
        this.model.recoveryCountMeasurementUnit='GRAMS';
       }
       else if(this.selectedinventoryItemMeasurementUnit.toLowerCase()=='litres')
       {
        this.model.recoveryCountMeasurementUnit='ML';
       }
 
       //Wastage Count
       if(this.model.totalWastageCount<1)
       {
         this.model.totalWastageCount=this.model.totalWastageCount*1000;
         
       }
       if(this.selectedinventoryItemMeasurementUnit.toLowerCase()=='kg')
       {
        this.model.wastageCountMeasurementUnit='GRAMS';
       }
       else if(this.selectedinventoryItemMeasurementUnit.toLowerCase()=='litres')
       {
        this.model.wastageCountMeasurementUnit='ML';
       }

    for(let i=0;i<this.allFoodItemSummaryData.length;i++)
    {
      //Debit Count
      if(+(this.allFoodItemSummaryData[i].debitCount)<1)
    {
      this.allFoodItemSummaryData[i].debitCount=""+(+(this.allFoodItemSummaryData[i].debitCount)*1000);
      
    }
    if(this.selectedinventoryItemMeasurementUnit.toLowerCase()=='kg')
    {
      this.allFoodItemSummaryData[i].debitCountMeasurementUnit='GRAMS';
    }
    else if(this.selectedinventoryItemMeasurementUnit.toLowerCase()=='litres')
    {
      this.allFoodItemSummaryData[i].debitCountMeasurementUnit='ML';
    }

     //Missing Count
     if(+(this.allFoodItemSummaryData[i].missingCount)<1)
     {
       this.allFoodItemSummaryData[i].missingCount=""+(+(this.allFoodItemSummaryData[i].missingCount)*1000);
       
     }
     if(this.selectedinventoryItemMeasurementUnit.toLowerCase()=='kg')
     {
      this.allFoodItemSummaryData[i].missingCountMeasurementUnit='GRAMS';
     }
     else if(this.selectedinventoryItemMeasurementUnit.toLowerCase()=='litres')
     {
      this.allFoodItemSummaryData[i].missingCountMeasurementUnit='ML';
     }

      //Recovery Count
      if(+(this.allFoodItemSummaryData[i].recoveryCount)<1)
      {
        this.allFoodItemSummaryData[i].recoveryCount=""+(+(this.allFoodItemSummaryData[i].recoveryCount)*1000);
        
      }
      if(this.selectedinventoryItemMeasurementUnit.toLowerCase()=='kg')
      {
        this.allFoodItemSummaryData[i].recoveryCountMeasurementUnit=' GRAMS';
      }
      else if(this.selectedinventoryItemMeasurementUnit.toLowerCase()=='litres')
      {
        this.allFoodItemSummaryData[i].recoveryCountMeasurementUnit='ML';
      }

      //Wastage Count
      if(+(this.allFoodItemSummaryData[i].wastageCount)<1)
      {
        this.allFoodItemSummaryData[i].wastageCount=""+(+(this.allFoodItemSummaryData[i].wastageCount)*1000);
        
      }
      if(this.selectedinventoryItemMeasurementUnit.toLowerCase()=='kg')
      {
        this.allFoodItemSummaryData[i].wastageCountMeasurementUnit='GRAMS';
      }
      else if(this.selectedinventoryItemMeasurementUnit.toLowerCase()=='litres')
      {
        this.allFoodItemSummaryData[i].wastageCountMeasurementUnit='ML';
      }
    

    }
  }

}
