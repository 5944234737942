import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InventoryTransfer } from "../../bean/Inventory"


@Component({
    selector: 'transferInventory',
    templateUrl: 'transferInventory.html',
    styleUrls: ['popUpStyle.css'],
})
export class TransferInventory {

    transferType: boolean = false;

    constructor(public dialogRef: MatDialogRef<TransferInventory>,
        @Inject(MAT_DIALOG_DATA) public data: InventoryTransfer) {
            
            console.log(data);
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}