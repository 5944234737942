import { formatDate } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSnackBar, MatSort, MatTableDataSource } from '@angular/material';
import { ManagementService } from '../../../service/inventory/inventory.service';
import { CreditTransaction,CreditReport, InventoryLocations } from '../../bean/Inventory';
import { MessageComponent } from '../../Generic/message/message.component';

@Component({
  selector: 'app-credit-report',
  templateUrl: './credit-report.component.html',
  styleUrls: ['./credit-report.component.css']
})
export class CreditReportComponent implements OnInit {

   
  allData: CreditTransaction[] = [];
  allTempData: CreditTransaction[] = [];

  selectedLocationIdIndex:number;
  selectedInventoryName:string
  selectedInventoryCode:string
  private inventoryLocations:InventoryLocations[];


  constructor(private mService: ManagementService, private _snackBar: MatSnackBar,
    public dialog: MatDialog) {
    
  }

 
 



  model: CreditReport = new CreditReport();

  ngOnInit() {

    var currentDate=new Date();
    this.fromDate= formatDate(currentDate, 'yyyy-MM-dd', 'en-US');
    this.toDate= formatDate(currentDate, 'yyyy-MM-dd', 'en-US');
    this.seachByDate();

    this.model = new CreditReport();
  /*   this.mService.fetchAllCreditReportallRange().
      subscribe(result => {
        this.model= result.responseObject as unknown as CreditReport;
        this.allMenuData = this.model.creditTransactions;
        this.dataSource = new MatTableDataSource(this.allMenuData);
        console.log(this.allMenuData);
        this.loadingSpinner = false;
      }); */

      this.selectedLocationIdIndex=0;
      this.fetchAllInventoryLocations();

  
  }

  fetchAllInventoryLocations()
  {
    this.mService.getAllActiveInventoryLocations().subscribe(data => {
      console.log(data.responseObject);
      this.inventoryLocations = data.responseObject;
    });
  }


  onLocationSelected()
  {
   
    if(this.selectedLocationIdIndex!=-1)
    {
      if(this.selectedLocationIdIndex>0)
      {
        this.selectedInventoryCode=this.inventoryLocations[this.selectedLocationIdIndex-1].inventoryCode;
        this.selectedInventoryName=this.inventoryLocations[this.selectedLocationIdIndex-1].inventoryName;
      }
    }
   
  }


 



  applyFilter(event: Event) {

    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    var filterResult:CreditTransaction[] = this. allTempData.filter(tran => 
      tran.itemCode.toLowerCase().startsWith(filterValue) ||tran.itemName.toLowerCase().startsWith(filterValue));
  
      if(filterValue=='')
      {
        this. allData=this.allTempData;
      }
      else
      {
      if(filterResult.length>0)
      {
  this.allData=filterResult;
      }
      else
      {
        this. allData=this.allTempData;
      }
    }
    
  }



  openSnackBar(message: string) {
    this._snackBar.openFromComponent(MessageComponent, {
      data: message,
      duration: 5 * 1000
    });
  }


  fromDate:string;
  toDate:string;

  search()
  {
    if(this.selectedLocationIdIndex==0)
    {
      this.seachByDate();
    }
    else
    {
      this.searchByDateRangeInventoryCode();

    }
  }
  
  seachByDate() {
   
    var data: any = {
      "startDate": this.fromDate,
      "endDate": this.toDate
    }
    this.mService.fetchAllCreditReportByDate(data).subscribe(data => {

      this.model= data.responseObject as unknown as CreditReport;
      this.allData = this.model.creditTransactions;
      this.allTempData=this.model.creditTransactions;
    });
  }

    searchByDateRangeInventoryCode() {
   
      var data: any = {
        "startDate": this.fromDate,
        "endDate": this.toDate,
        "inventoryCode":this.selectedInventoryCode
      }
      this.mService.fetchAllCreditReportByDateInvCode(data).subscribe(data => {
  
        this.model= data.responseObject as unknown as CreditReport;
        this.allData = this.model.creditTransactions;
        this.allTempData=this.model.creditTransactions;
      });

  }

}
