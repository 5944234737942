export class AssetItem
{
    id:string;
	assetCode:string;
	assetName:string;
	totalQuantity:number;
    purchaseDate:string;
    warrantyEndDate:string;
	createdUser:string;
	modifiedUser:string;
	createdDate:Date;
	updatedDate:Date;
    active:boolean;
	deleted:boolean;
   
}

export class AssetItemTransaction
{
    id:string;
    inventoryCode:string
    assetCode:string
    assetName:string
    transactionType:string
    count:number;
    comments:string;
    arcomments:string;
    newrequest:boolean;
    accepted:boolean;
    rejected:boolean;
    deleted:boolean;
    createdUser:string;
	modifiedUser:string;
	createdDate:Date;
	updatedDate:Date;
}

export class AssetItemSummary
{
    debitCount:number;
	creditCount:number;
	balance:number;
	assetslist:AssetItemTransaction[];
    rejectedlist:AssetItemTransaction[];
}

export class APIResponse
{
    responseCode: boolean;
    message: string;
    responseObject: any;
}