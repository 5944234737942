import { Component, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { AssetItemTransaction } from '../../../bean/Assets';


@Component({
    selector: 'FixedAssetPopUpDebit',
    templateUrl: 'fixedassetdebitpopup.html',

})
export class FixedAssetDebitPopup{ 

    options:string []=[];
    myControl = new FormControl();
    filteredOptions: Observable<string[]>;

    submitted:boolean=false;
    debitForm: FormGroup;

    constructor(public dialogRef: MatDialogRef<FixedAssetDebitPopup>,@Inject(MAT_DIALOG_DATA) public data: AssetItemTransaction,
    private formBuilder: FormBuilder)
    { 
    this.debitForm = this.formBuilder.group({
        count: ['', Validators.required]
    });
  
    this.submitted=false;
    this.debitForm.reset();

        this.options=[];

    }
    
    onCloseClick(): void {
        this.dialogRef.close();
    }

    onSubmitClick(): void {
        this.submitted=true;
        if(!this.debitForm.valid)
        {
          return;
        }
       
        this.data.transactionType='DEBIT';
        this.dialogRef.close(this.data);
    }

}