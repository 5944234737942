import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSnackBar, MatSort, MatTableDataSource } from '@angular/material';
import { AuthenticationService } from '../../../service/auth/authentication.service';
import { ManagementService } from '../../../service/inventory/inventory.service';
import { InventoryLocations, InventoryTransferRequest } from '../../bean/Inventory';
import { MessageComponent } from '../../Generic/message/message.component';
import { PopUpAcceptRequest } from '../popups/PopupAcceptRequest';
import { PopUpRejectRequest } from '../popups/PopupRejectRequest';

@Component({
  selector: 'app-incomingrequests',
  templateUrl: './incomingrequests.component.html',
  styleUrls: ['./incomingrequests.component.css']
})
export class IncomingrequestsComponent implements OnInit {



  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  loadingSpinner:boolean=false;

  dataSource: MatTableDataSource<InventoryTransferRequest>;

 
  constructor(private authService:AuthenticationService,private mService: ManagementService, private _snackBar: MatSnackBar,
    public dialog: MatDialog) {
  
  }

 

  allMenuData: InventoryTransferRequest[] = [];
 


  actions: string[] = ['Update', 'Activate', 'De-Activate', 'Delete'];

  superAdmin:boolean=false;
  superVisor:boolean=false;
  inventoryAdmin:boolean=false;


  ngOnInit() {

    this.superAdmin=false;
    this.superVisor=false;
    this.inventoryAdmin=false;
   
    if(this.authService.currentUserValue.role.role=='ROLE_SUPER_ADMIN')
    {
      
       this.superAdmin=true;
    }
    else if(this.authService.currentUserValue.role.role=='ROLE_SUPERVISOR')
    {
    this.superVisor=true;
    }
    else if(this.authService.currentUserValue.role.role=='ROLE_INVENTORY_ADMIN')
    {
    this.inventoryAdmin=true;
    }


    if(this.inventoryAdmin)
    {
    var formData;
    formData={"inventoryCode": this.authService.currentUserValue.role.details};

    console.log(formData);


    this.mService.fetchAllPendingAcceptRequestByInventoryCode(formData).
      subscribe(result => {
        var value= result.responseObject as InventoryTransferRequest []; 
        console.log(value);
        this.allMenuData = value;
        this.dataSource = new MatTableDataSource(this.allMenuData);
        console.log(this.allMenuData);
        this.loadingSpinner=false;
      });

    }
    else
    {
      this.mService.fetchAllPendingAcceptRequest().
      subscribe(result => {
        var value= result.responseObject as InventoryTransferRequest []; 
        console.log(value);
        this.allMenuData = value;
        this.dataSource = new MatTableDataSource(this.allMenuData);
        console.log(this.allMenuData);
        this.loadingSpinner=false;
      });
    }


 
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }


  displayedColumns: string[] = ['fromInventoryCode', 'itemCode', 'units', 'quantity', 'transferredDate', 'active','transferComments',"arComments",'action'];
  columnsToDisplay: string[] = this.displayedColumns.slice();
  data: InventoryTransferRequest[] = this.allMenuData;

  


acceptRequest(data:InventoryTransferRequest)
{
  const dialogRef = this.dialog.open(PopUpAcceptRequest, {
    width: '450px',
    data:null
  });

  dialogRef.afterClosed().subscribe(result => {

    if(result!=null)
    {
      var val:any={
        "id": data.id,
        "receivedUser":this.authService.currentUserValue.firstName+" "+this.authService.currentUserValue.lastName,
        "comments":result
      }
        this.mService.acceptItemRequest(val).subscribe(value=>{
    
          this.loadingSpinner = false;
          this.openSnackBar("Request Accepted Succesfully .....!!!!");
    
          this.ngOnInit();
        });
    }
   
  });
 
  
}

  rejectRequest(data: InventoryTransferRequest)
  {
    const dialogRef = this.dialog.open(PopUpRejectRequest, {
      width: '450px',
      data:null
    });
  
    dialogRef.afterClosed().subscribe(result => {
  
      if(result!=null)
      {
        var val: any = {
          "id": data.id,
          "receivedUser":this.authService.currentUserValue.firstName+" "+this.authService.currentUserValue.lastName,
          "comments": result
        }
        this.mService.rejectItemRequest(val).subscribe(value => {
          this.loadingSpinner = false;
          this.openSnackBar("Request Rejected Successfully .....!!!!");
    
          this.ngOnInit()
        })
      }
   
    
  });
}

  openSnackBar(message: string) {
    this._snackBar.openFromComponent(MessageComponent, {
      data: message,
      duration: 5 * 1000
    });
  }


}
