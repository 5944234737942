
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Doctor,DoctorData, DoctorOrder, DoctorPaymentDetails, DoctorPayment,ResponseDataArray } from '../../views/bean/Doctor';
import { api } from '../../../environments/api';
import { catchError } from 'rxjs/operators';
import { LoggedInUserAccountDetails } from '../../views/bean/UserAccount';
import { AuthenticationService } from '../auth/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class DmangementService {


  httpOptions;

  formDataHttpOptions = {
    headers: new HttpHeaders({ 'Content-Type': undefined })
  }

  constructor(private http: HttpClient,private authService:AuthenticationService) {
  
    let httpHeadersContentTypeJson= new HttpHeaders({'Content-Type':'application/json',
    "Authorization":"Basic "+this.authService.currentUserValue.authdata});
    this.httpOptions = {
      headers: httpHeadersContentTypeJson
    };
   }

  saveData(data: Doctor): Observable<DoctorData> {
    return this.http.post<DoctorData>(api.saveDoctor, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  addNewOnlyData(data: Doctor): Observable<DoctorData> {
    return this.http.post<DoctorData>(api.addOnly, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  getDoctosData(data: string): Observable<Doctor> {
    return this.http.post<Doctor>(api.getAllDoctorById, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  updateData(data: any): Observable<Doctor> {
    return this.http.put<Doctor>(api.updateDoctorCredit, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  deleteData(data: Doctor): Observable<any> {
    return this.http.delete<any>(api.deleteDoctor,this.httpOptions).pipe(catchError(this.handleError));
  }

  getAllDoctorsByHospitalId(data:any): Observable<ResponseDataArray> {
    return this.http.post<ResponseDataArray>(api.getAllDoctorsByHospitalId,data,this.httpOptions).pipe(catchError(this.handleError));
  }

  getAllDoctorAccountsByHospitalId(data:any): Observable<ResponseDataArray> {
    return this.http.post<ResponseDataArray>(api.getAllActiveDoctorOrdersByHsopitalId,data,this.httpOptions).pipe(catchError(this.handleError));
  }

  

  getAllData(): Observable<ResponseDataArray> {
    return this.http.get<ResponseDataArray>(api.getAllDoctors,this.httpOptions).pipe(catchError(this.handleError));
  }


  getAllActiveData(): Observable<DoctorData> {
    return this.http.get<DoctorData>(api.getAllActiveDoctors,this.httpOptions).pipe(catchError(this.handleError));
  }

  getAllActiveDoctorOrders(): Observable<DoctorData> {
    return this.http.get<DoctorData>(api.getAllActiveDoctorOrders,this.httpOptions).pipe(catchError(this.handleError));
  }

  getAllActiveDataByAccountType(data:any): Observable<ResponseDataArray> {
    return this.http.post<ResponseDataArray>(api.getAllActiveByAccountType, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  getAllDataByAccountType(data:any): Observable<ResponseDataArray> {
    return this.http.post<ResponseDataArray>(api.getAllByAccountType, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  getAllDataByAccountTypeHospitalId(data:any): Observable<ResponseDataArray> {
    return this.http.post<ResponseDataArray>(api.getAllByAccountTypeHospitalID, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  activate(data: any): Observable<DoctorData> {
    return this.http.post<DoctorData>(api.activateDoctor, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  deactivate(data: any): Observable<DoctorData> {
    return this.http.post<DoctorData>(api.deactivateDoctor, data,this.httpOptions).pipe(catchError(this.handleError));
  }

  delete(data: any): Observable<DoctorData> {
    return this.http.post<DoctorData>(api.deleteDoctor, data,this.httpOptions).pipe(catchError(this.handleError));
  }

  fetchDoctorOrder(data: any): Observable<DoctorData> {
    return this.http.post<DoctorData>(api.fetchDoctorOrdersById, data,this.httpOptions).pipe(catchError(this.handleError));
  }

  sendBalanceNotificationSmsAll(): Observable<DoctorData> {
    return this.http.get<DoctorData>(api.sendBalanceAlertNotificationSmsAll,this.httpOptions).pipe(catchError(this.handleError));
  }

  sendBalanceNotificationSms(data: any): Observable<DoctorData> {
    return this.http.post<DoctorData>(api.sendBalanceAlertNotificationSms,data,this.httpOptions).pipe(catchError(this.handleError));
  }

  sendDoctorEmailSummary(data: any): Observable<DoctorData> {
    return this.http.post<DoctorData>(api.sendEmailSummary, data,this.httpOptions).pipe(catchError(this.handleError));
  }

  addDoctorPayment(data: DoctorPayment): Observable<DoctorData> {
    return this.http.post<DoctorData>(api.adddoctorpayment, data,this.httpOptions).pipe(catchError(this.handleError));
  }

  deleteDoctorTransaction(data:any): Observable<any> {
    return this.http.post<any>(api.deleteDoctorOrderTransaction,data, this.httpOptions).pipe(catchError(this.handleError));
  
  }

  handleError(error: HttpErrorResponse):Observable<any>{

    if(error==undefined||error.error==undefined||error.error.message==undefined)
    {
      alert('Check Your Internet or Please Try Again');
    }
    else
    {
    alert(error.error.message);
    }
    return null;
    }

}
