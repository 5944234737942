import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../../service/auth/authentication.service';
import { FixedAssetService } from '../../../../service/fixedasset/fixedassets.service';
import { ManagementService } from '../../../../service/inventory/inventory.service';
import { AssetItemTransaction } from '../../../bean/Assets';
import { InventoryLocations } from '../../../bean/Inventory';
import { MessageComponent } from '../../../Generic/message/message.component';
import { FixedAssetCommentPopup } from '../../popups/fixedassets/prekotcomments/fixedassetcommentpopup.component';

@Component({
  selector: 'app-fixedassetnewreq',
  templateUrl: './fixedassetnewreq.component.html',
  styleUrls: ['./fixedassetnewreq.component.css']
})
export class FixedassetnewreqComponent implements OnInit {

  private inventoryLocations:InventoryLocations[];

  inventoryCode:string;
  inventoryName:string;

  submitted:boolean=false;

  allAssetsItemTranList: AssetItemTransaction[] = [];
  tempAssetsItemTranList: AssetItemTransaction[] = [];


  urlData:string;

  superAdmin:boolean=false;
  superVisor:boolean=false;
  inventoryAdmin:boolean=false;

  selectedLocationIdIndex:number;

  
  constructor(private authService:AuthenticationService,private inventoryService: ManagementService,
              private fixedAssetService: FixedAssetService, 
              private _snackBar: MatSnackBar,public dialog: MatDialog,) { 

    this.superAdmin=false;
    this.superVisor=false;
    this.inventoryAdmin=false;
   
    if(this.authService.currentUserValue.role.role=='ROLE_SUPER_ADMIN')
    {
       this.superAdmin=true;
    }
    else if(this.authService.currentUserValue.role.role=='ROLE_SUPERVISOR')
    {
    this.superVisor=true;
    this.inventoryCode=this.authService.currentUserValue.role.details;
    }
    else if(this.authService.currentUserValue.role.role=='ROLE_INVENTORY_ADMIN')
    {
    this.inventoryAdmin=true;
    this.inventoryCode=this.authService.currentUserValue.role.details;
    }
  }

  
  onLocationSelected()
  {
    if(this.selectedLocationIdIndex!=-1)
    {
      if(this.selectedLocationIdIndex>0)
      {
        this.inventoryCode=this.inventoryLocations[this.selectedLocationIdIndex-1].inventoryCode;
        this.inventoryName=this.inventoryLocations[this.selectedLocationIdIndex-1].inventoryName;
      }
    }
   
  }

  search()
  {
    this.allAssetsItemTranList=[];
    this.tempAssetsItemTranList=[];
    if(this.selectedLocationIdIndex==0)
    {
      console.log('Search All Location');
      this.fetchAllNewRequest();
    }
    else
    {
     console.log('Search All Inventory Reports='+this.inventoryCode);
    this.fetchAllNewRequestInventoryCode();
    }
  }

  ngOnInit() {


    this.selectedLocationIdIndex=0;
  
    this.inventoryService.getAllInventoryLocationItems().subscribe(data => {
      console.log(data.responseObject);
      this.inventoryLocations = data.responseObject;
    });

  if(this.authService.currentUserValue.role.role=="ROLE_SUPER_ADMIN")
  {
    this.fetchAllNewRequest();
  }
  else  if(this.authService.currentUserValue.role.role=="ROLE_INVENTORY_ADMIN"||this.authService.currentUserValue.role.role=="ROLE_SUPERVISOR")
  {
    this.fetchAllNewRequestInventoryCode();
  }

    
  }

  fetchAllNewRequest()
  {
    this.fixedAssetService.assetItemTransactionFetchallNew().subscribe(data => {
      this. allAssetsItemTranList = data.responseObject;
      this.tempAssetsItemTranList=data.responseObject;
      console.log(this. allAssetsItemTranList);
    });
  }

  fetchAllNewRequestInventoryCode()
  {
    var param={"inventoryCode":this.inventoryCode};
    this.fixedAssetService.assetItemTransactionFetchallNewInventoryCode(param).subscribe(data => {
      this. allAssetsItemTranList = data.responseObject;
      this.tempAssetsItemTranList=data.responseObject;
      console.log(this. allAssetsItemTranList);
    });
  }


  



  applyFilter(event: Event) {

    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    var filterResult:AssetItemTransaction[] = this.tempAssetsItemTranList.filter(tran => 
      tran.assetName.toLowerCase().startsWith(filterValue) ||tran.inventoryCode.toLowerCase().startsWith(filterValue)
      ||tran.assetCode.toLowerCase().startsWith(filterValue));
  
      if(filterValue=='')
      {
        this.  allAssetsItemTranList=this.tempAssetsItemTranList;
      }
      else
      {
      if(filterResult.length>0)
      {
        this.allAssetsItemTranList=filterResult;
      }
      else
      {
        this.allAssetsItemTranList=this.tempAssetsItemTranList;
      }
    }
   
  }



  openSnackBar(message:string) {
    this._snackBar.openFromComponent(MessageComponent, {
      data: message,
      duration:5*1000
    });
  }

  openAcceptCommentDialog(itemTran:AssetItemTransaction)
  {
    
    const dialogRef = this.dialog.open(FixedAssetCommentPopup, {
      width: '800px',maxHeight:'600px',data:"Accept"
    });

    dialogRef.afterClosed().subscribe((result:string) => {
    
      
      if(result!=undefined&&result!=null)
      {
        var param={"id":itemTran.id,"comments":result,"user":this.authService.currentUserValue.firstName+" "+this.authService.currentUserValue.lastName};
        this.fixedAssetService.assetItemTransactionAccept(param).subscribe(data => {
          this.openSnackBar(data.message);
          this.search();
        });
       
      }

  });
  }


  openRejectCommentDialog(itemTran:AssetItemTransaction)
  {
    
    const dialogRef = this.dialog.open(FixedAssetCommentPopup, {
      width: '800px',maxHeight:'600px',data:"Accept"
    });

    dialogRef.afterClosed().subscribe((result:string) => {
    
      
      if(result!=undefined&&result!=null)
      {
        var param={"id":itemTran.id,"comments":result,"user":this.authService.currentUserValue.firstName+" "+this.authService.currentUserValue.lastName};
        this.fixedAssetService.assetItemTransactionReject(param).subscribe(data => {
          this.openSnackBar(data.message);
          this.search();
        });
     
      }

  });
  }


  

}
