import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../service/auth/authentication.service';
import { ManagementService } from '../../../service/inventory/inventory.service';
import { KitchenDebitService } from '../../../service/kitchendebit/kitchendebits.service';
import { MenuService } from '../../../service/menuServ/menu.service';
import { InventoryLocations } from '../../bean/Inventory';
import { FoodItem, Ingredient, KitchenInventoryDebits, KitchenInventoryOrders} from '../../bean/Menu';
import { MessageComponent } from '../../Generic/message/message.component';
import { AlertPopup } from '../../popup/AlertPopup';
import { ConfirmPopup } from '../../popup/ConfirmPopup';

@Component({
  selector: 'app-debitreceipeitems',
  templateUrl: './debitreceipeitems.component.html',
  styleUrls: ['./debitreceipeitems.component.css']
})
export class DebitreceipeitemsComponent implements OnInit {

  private inventoryLocations:InventoryLocations[];

  inventoryCode:string;
  inventoryName:string;

  myControl = new FormControl();
  submitted:boolean=false;
  ingredientEstimateForm: FormGroup;

  dataGenerated:boolean=false;

 
  foodItem:FoodItem;

  tableData:Ingredient[]=[];

  ingredients:KitchenInventoryDebits[]=[];

  searchBox : FormControl = new FormControl();
  foodItemSelected:boolean;

  allFoodItemData:FoodItem[]=[];
  tempFoodItemData:FoodItem[]=[];

  personCount:number;

  kitchenOrderData:KitchenInventoryOrders;

  branchCode:string;

  constructor(private formBuilder: FormBuilder,private _snackBar: MatSnackBar,
      private inventoryService: ManagementService,private authService:AuthenticationService,private kitchenDebitService: KitchenDebitService,private menuService: MenuService, public dialog: MatDialog
      , public router: Router)
  {
    this.branchCode=this.authService.currentUserValue.branch.branchCode;
   
  
     
   
  }

  fetchFoodItemList()
  {
    this.menuService.fetchAllFoodItemlist().subscribe(data=>{
      console.log(data.responseObject);
      this.allFoodItemData = data.responseObject;
      this.tempFoodItemData=data.responseObject;
      
   });
  }



  ngOnInit() {

    this.submitted=false;

    this.inventoryCode=this.authService.currentUserValue.role.details;

    this.fetchFoodItemList();

      this.ingredientEstimateForm = this.formBuilder.group({
        personCount: ['', Validators.required]
    });

    this.ingredientEstimateForm.reset();

    this.searchBox.valueChanges.subscribe(
      term => {
       

          var enteredString =term as string;
          
          if(enteredString!='')
          {
          var filterResult:FoodItem[] = this.tempFoodItemData.filter(tran => 
            tran.foodItemCode.toLowerCase().startsWith(enteredString.toLowerCase()) ||tran.foodItemName.toLowerCase().startsWith(enteredString.toLowerCase())
            );

            console.log(filterResult);
            
            if(filterResult.length>0)
            {
              this.allFoodItemData=filterResult;
            }
        
else
{
              this. allFoodItemData=this.tempFoodItemData;
          }
          
        }
        else
        {
          this. allFoodItemData=this.tempFoodItemData;
        }
        
        
    });

    this.inventoryService.getAllInventoryLocationItems().subscribe(data => {
      console.log(data.responseObject);
      this.inventoryLocations = data.responseObject;
      for(var i=0;i<this.inventoryLocations.length;i++)
      {
        if(this.inventoryCode==this.inventoryLocations[i].inventoryCode)
        {
          this.inventoryName=this.inventoryLocations[i].inventoryName;
          break;
        }
      }
    });
  
  
  }

  reset()
  {
    this.submitted=false;

    this.ingredientEstimateForm = this.formBuilder.group({
      personCount: ['', Validators.required]
  });

  this.ingredientEstimateForm.reset();
  this.ingredients=[];
  this.dataGenerated=false;
  }



  selectedOption(item)
  {
    this.foodItem=item;
    console.log(item.foodItemName);
    this.tableData=this.foodItem.ingredientDetails;
    this.foodItemSelected=true;

  }


  openSnackBar(message: string) {
      this._snackBar.openFromComponent(MessageComponent, {
        data: message,
        duration: 5 * 1000
      });
    }

    calcuateIngredientsQuantity(): void {

    this.submitted=true;

    if(this.ingredientEstimateForm.invalid)
    {
      return;
    }

    if(!this.foodItemSelected)
    {
      console.log('Select a Food Item')
      return;
    }

    this.dataGenerated=true;
    this.kitchenOrderData=this.generateInventoryDebitData();
    console.log('completed',this.kitchenOrderData)

  }

  sendRequestToInventory()
  {
    
    const dialogRef = this.dialog.open(ConfirmPopup, {
      width: '400px',maxHeight:'300px',data:{message:"Are you Sure to Request?",description:"This will Send Request to Inventory for Requested Items"}
    });

    dialogRef.afterClosed().subscribe((result:string) => {
    
      
      if(result=='yes')
      {
        this.kitchenDebitService.newRequest(this.kitchenOrderData).subscribe(data => {
          const dialogRef = this.dialog.open(AlertPopup, {
            width: '400px',maxHeight:'300px',data:{"icon":"success","message":"Request Sent Successfully to Approval"}
          });
          dialogRef.afterClosed().subscribe(data=>{
          {
          this.reset();
          }});
        });
       
       
      }

  });
    
  }


  generateInventoryDebitData():KitchenInventoryOrders
  {
    this.ingredients=[];
    for(var i=0;i<this.tableData.length;i++)
    {
      
      var foodItem=new KitchenInventoryDebits();
      foodItem.branchCode=this.branchCode;
      foodItem.inventoryItemCode=this.tableData[i].inventoryItemCode;
      foodItem.inventoryItemName=this.tableData[i].inventoryItemName;
      foodItem.foodItemCode=this.foodItem.foodItemCode;
      foodItem.foodItemName=this.foodItem.foodItemName;
      foodItem.quantity=this.tableData[i].quantity*this.personCount;
      foodItem.measurementUnit=this.tableData[i].measurementUnit;
      foodItem.requestedUser=this.authService.currentUserValue.firstName+" "+this.authService.currentUserValue.lastName;
      this.ingredients.push(foodItem);
    }

     var invDebit=new KitchenInventoryOrders();
     invDebit.branchCode=this.branchCode;
     invDebit.inventoryCode=this.authService.currentUserValue.role.details;
     invDebit.inventoryName=this.inventoryName;
     invDebit.foodItemCode=this.foodItem.foodItemCode;
     invDebit.foodItemName=this.foodItem.foodItemName;
     invDebit.personCount=this.personCount;
     invDebit.kitchendebits=this.ingredients;
     invDebit.requestedUser=this.authService.currentUserValue.firstName+" "+this.authService.currentUserValue.lastName;
    
   return invDebit;

  }

    

     
      
  }

  

  
  



