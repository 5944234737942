import { Component, OnInit } from '@angular/core';
import { ViewChild, Input } from '@angular/core';
import { ManagementService } from '../../../service/inventory/inventory.service';
import { InventoryData, ItemConsumption, InventoryItem, FoodItemRecoverSumary } from '../../bean/Inventory';
import { MatRadioButton } from '@angular/material';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MessageComponent } from "../../Generic/message/message.component";
import { MatDialog } from '@angular/material/dialog';
import { TransferInventory } from "../popups/transferInventory";
import { Router } from '@angular/router';

@Component({
  selector: 'app-recovery-report',
  templateUrl: './recovery-report.component.html',
  styleUrls: ['./recovery-report.component.css']
})
export class RecoveryReportComponent implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  loadingSpinner: boolean = false;
  dataSource: MatTableDataSource<FoodItemRecoverSumary>;
  invData: InventoryItem = new InventoryItem();

  constructor(private mService: ManagementService, 
    private _snackBar: MatSnackBar,
    private router: Router,
    public dialog: MatDialog) {
    this.loadingSpinner = true;

    if (this.invData == undefined) {
      this.router.navigate(['inventory/management']);
      return;
    }

    this.invData = this.mService.getInventoryItem();
    this.dataSource = new MatTableDataSource(this. allFoodItemSummaryData);
  }

  addItems: boolean = false;
  allFoodItemSummaryData: FoodItemRecoverSumary[] = [];
  updateTyep: boolean = false;
  actions: string[] = ['Update', 'Activate', 'De-Activate', 'Delete'];


  model: ItemConsumption = new ItemConsumption();

  ngOnInit() {

    this.model = new ItemConsumption();
    var data:any={
      "itemCode": this.invData.itemCode
    }
    this.mService.fetchItemRecovery(data).
      subscribe(result => {
        this.model = result.responseObject as unknown as ItemConsumption;
        this. allFoodItemSummaryData = this.model.fooditemlist;
        this.dataSource = new MatTableDataSource(this. allFoodItemSummaryData);
        console.log(this. allFoodItemSummaryData);
        this.updateWeightDetails();
      });
    this.addItems = false;
    this.updateTyep = false;
  
  }



  openSnackBar(message: string) {
    this._snackBar.openFromComponent(MessageComponent, {
      data: message,
      duration: 5 * 1000
    });
  }


  fromDate: string;
  toDate: string;

  seachByDate() {
    this.loadingSpinner = true;
    var data: any = {
      "itemCode": this.invData.itemCode,
      "startDate": this.fromDate,
      "endDate": this.toDate
    }
    this.mService.fetchItemRecoveryByDate(data).subscribe(data => {

      var model = data.responseObject as unknown as ItemConsumption;
      this.allFoodItemSummaryData = model.fooditemlist;
      console.log(this. allFoodItemSummaryData);
      this.updateWeightDetails();
     
    });

  }

  updateWeightDetails()
  {

     //Debit Count
     if(this.model.totalDebitCount<1)
     {
       this.model.totalDebitCount=(+(this.model.totalDebitCount)*1000);
       
     }
     if(this.invData.measurementUnit.toLowerCase()=='kg')
     {
       this.model.debitCountMeasurementUnit='GRAMS';
     }
     else if(this.invData.measurementUnit.toLowerCase()=='litres')
     {
      this.model.debitCountMeasurementUnit='ML';
     }
 
      //Missing Count
      if(this.model.totalMissingCount<1)
      {
        this.model.totalMissingCount=this.model.totalMissingCount*1000;
        
      }
      if(this.invData.measurementUnit.toLowerCase()=='kg')
      {
        this.model.missingCountMeasurementUnit='GRAMS';
      }
      else if(this.invData.measurementUnit.toLowerCase()=='litres')
      {
        this.model.missingCountMeasurementUnit='ML';
      }
 
       //Recovery Count
       if(this.model.totalRecoveryCount<1)
       {
        this.model.totalRecoveryCount=this.model.totalRecoveryCount*1000;
         
       }
       if(this.invData.measurementUnit.toLowerCase()=='kg')
       {
        this.model.recoveryCountMeasurementUnit='GRAMS';
       }
       else if(this.invData.measurementUnit.toLowerCase()=='litres')
       {
        this.model.recoveryCountMeasurementUnit='ML';
       }
 
       //Wastage Count
       if(this.model.totalWastageCount<1)
       {
         this.model.totalWastageCount=this.model.totalWastageCount*1000;
         
       }
       if(this.invData.measurementUnit.toLowerCase()=='kg')
       {
        this.model.wastageCountMeasurementUnit='GRAMS';
       }
       else if(this.invData.measurementUnit.toLowerCase()=='litres')
       {
        this.model.wastageCountMeasurementUnit='ML';
       }

    for(let i=0;i<this.allFoodItemSummaryData.length;i++)
    {
      //Debit Count
      if(+(this.allFoodItemSummaryData[i].debitCount)<1)
    {
      this.allFoodItemSummaryData[i].debitCount=""+(+(this.allFoodItemSummaryData[i].debitCount)*1000);
      
    }
    if(this.invData.measurementUnit.toLowerCase()=='kg')
    {
      this.allFoodItemSummaryData[i].debitCountMeasurementUnit='GRAMS';
    }
    else if(this.invData.measurementUnit.toLowerCase()=='litres')
    {
      this.allFoodItemSummaryData[i].debitCountMeasurementUnit='ML';
    }

     //Missing Count
     if(+(this.allFoodItemSummaryData[i].missingCount)<1)
     {
       this.allFoodItemSummaryData[i].missingCount=""+(+(this.allFoodItemSummaryData[i].missingCount)*1000);
       
     }
     if(this.invData.measurementUnit.toLowerCase()=='kg')
     {
      this.allFoodItemSummaryData[i].missingCountMeasurementUnit='GRAMS';
     }
     else if(this.invData.measurementUnit.toLowerCase()=='litres')
     {
      this.allFoodItemSummaryData[i].missingCountMeasurementUnit='ML';
     }

      //Recovery Count
      if(+(this.allFoodItemSummaryData[i].recoveryCount)<1)
      {
        this.allFoodItemSummaryData[i].recoveryCount=""+(+(this.allFoodItemSummaryData[i].recoveryCount)*1000);
        
      }
      if(this.invData.measurementUnit.toLowerCase()=='kg')
      {
        this.allFoodItemSummaryData[i].recoveryCountMeasurementUnit=' GRAMS';
      }
      else if(this.invData.measurementUnit.toLowerCase()=='litres')
      {
        this.allFoodItemSummaryData[i].recoveryCountMeasurementUnit='ML';
      }

      //Wastage Count
      if(+(this.allFoodItemSummaryData[i].wastageCount)<1)
      {
        this.allFoodItemSummaryData[i].wastageCount=""+(+(this.allFoodItemSummaryData[i].wastageCount)*1000);
        
      }
      if(this.invData.measurementUnit.toLowerCase()=='kg')
      {
        this.allFoodItemSummaryData[i].wastageCountMeasurementUnit='GRAMS';
      }
      else if(this.invData.measurementUnit.toLowerCase()=='litres')
      {
        this.allFoodItemSummaryData[i].wastageCountMeasurementUnit='ML';
      }
    

    }
  }

}

