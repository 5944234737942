import { environment} from './environment';

export const api = {
    saveCompanyData:   environment.baseUrl + "/companyController" + "/saveData",
    getCompanyData:    environment.baseUrl + "/companyController" + "/getData",
    updateCompanyData: environment.baseUrl + "/companyController" + "/updateData",
    deleteCompanyData: environment.baseUrl + "/companyController" + "/deleteData",
    getAllCompanyData: environment.baseUrl + "/companyController" + "/getAllData",
    saveMenuData: environment.baseUrl + "/menuController" + "/saveData",
    getMenuData: environment.baseUrl + "/menuController" + "/getData",
    updateMenuData: environment.baseUrl + "/menuController" + "/updateData",
    deleteMenuData: environment.baseUrl + "/menuController" + "/deleteData",
    getAllMenuData: environment.baseUrl + "/menuController" + "/getAllData",
    saveOrderData: environment.baseUrl + "/orderItemSController" + "/saveData",
    getOrderData: environment.baseUrl + "/orderItemSController" + "/getData",
    updateOrderData: environment.baseUrl + "/orderItemSController" + "/updateData",
    deleteOrderData: environment.baseUrl + "/orderItemSController" + "/deleteData",
    getAllOrderData: environment.baseUrl + "/orderItemSController" + "/getAllData",
    initalOrderData: environment.baseUrl + "/orderItemSController" +"/initialData",
    laterSavedOrderData: environment.baseUrl + "/orderItemSController" + "/savedPaymentForLater",



    getAllActiveInventoryLocations: environment.baseUrl + "/inventoryloc" + "/fetchallactive",
    getAllInventoryLocations: environment.baseUrl + "/inventoryloc" + "/fetchall",
    activateInventoryLocation: environment.baseUrl + "/inventoryloc" + "/activate",
    deactivateInventoryLocation: environment.baseUrl + "/inventoryloc" + "/deactivate",
    deleteInventoryLocation: environment.baseUrl + "/inventoryloc" + "/delete",


    getAllInventoryItem: environment.baseUrl + "/inventoryitem" + "/fetchall",
    getAllActiveInventoryItem: environment.baseUrl + "/inventoryitem" + "/fetchallactive",
    getAllInventoryItemByInventoryCode: environment.baseUrl + "/inventoryitem" + "/fetchallbyinventorycode",
    saveInventoryItem: environment.baseUrl + "/inventoryitem" + "/createnew",
    updateInventoryItem: environment.baseUrl + "/inventoryitem" + "/update",
    downloadInventoryItemExcel: environment.baseUrl + "/inventoryitem" + "/downloadinventoryexcel",
    activateInventoryItem: environment.baseUrl + "/inventoryitem" + "/activate",
    deactivateInventoryItem: environment.baseUrl + "/inventoryitem" + "/deactivate",
    deleteInventoryItem: environment.baseUrl + "/inventoryitem" + "/delete",
    deleteInventoryItemTransaction: environment.baseUrl + "/inventoryitem" + "/deletetransaction",

    downloadPatientWiswSummary: environment.baseUrl + "/billreport" + "/downloadpatientsummaryexcel",
    downloadBedOrdersDaySummary: environment.baseUrl + "/billreport" + "/downloadBedOrdersExcel",
    downloadAllOrdersSummaryExcel: environment.baseUrl + "/billreport" + "/downloadAllOrdersExcel",
    downloadCouponsDaySummaryExcel: environment.baseUrl + "/billreport" + "/downloadCouponsExcel",

    transactionItem: environment.baseUrl + "/inventoryitem" + "/addtransaction",
    fetchAllItemByBalanceDetails: environment.baseUrl + "/inventoryitem" + "/fetchitembalanceanyrange",
    fetchAllItemByBalanceDetailsByDate: environment.baseUrl + "/inventoryitem" + "/fetchItemslistbalancedaterange",

    //Doctor
     getAllDoctors: environment.baseUrl + "/doctor" + "/fetchall",
     getAllDoctorsByHospitalId: environment.baseUrl + "/doctor" + "/fetchallaccountbyhospitalid",
     getAllActiveDoctorOrders: environment.baseUrl + "/doctororder" + "/fetchallactive",
     getAllActiveDoctorOrdersByHsopitalId: environment.baseUrl + "/doctororder" + "/fetchallbyhospitalid",
    getAllActiveDoctors: environment.baseUrl + "/doctor" + "/fetchallactive",
    getAllActiveByAccountType: environment.baseUrl + "/doctor" + "/fetchallactivebyaccounttype",
    getAllByAccountType: environment.baseUrl + "/doctor" + "/fetchallbyaccounttype",
    getAllByAccountTypeHospitalID: environment.baseUrl + "/doctor" + "/fetchallaccountbyhospitalid",
    getAllDoctorById: environment.baseUrl + "/doctor" + "/fetchbydoctorid",
    saveDoctor: environment.baseUrl + "/doctor" + "/add",
    addOnly: environment.baseUrl + "/doctor" + "/addonly",
    updateDoctorCredit: environment.baseUrl + "/doctor" + "/updateCreditLimit",
    fetchDoctorOrdersById: environment.baseUrl + "/doctororder" + "/fetchbydoctorid",
    adddoctorpayment: environment.baseUrl + "/doctororder" + "/addpayment",
    activateDoctor: environment.baseUrl + "/doctor" + "/activate",
    deactivateDoctor: environment.baseUrl + "/doctor" + "/deactivate",
    deleteDoctor: environment.baseUrl + "/doctor" + "/delete",
    sendEmailSummary: environment.baseUrl + "/doctororder" + "/senddoctoremailsummary",
    sendBalanceAlertNotificationSms:environment.baseUrl + "/doctororder" + "/sendbalancealertsms",
    sendBalanceAlertNotificationSmsAll:environment.baseUrl + "/doctororder" + "/sendbalancealertsmsall",
    deleteDoctorOrderTransaction:environment.baseUrl + "/doctororder" + "/deleteorder",


    //Employee
    activateEmployee: environment.baseUrl + "/employee" + "/activate",
    deactivateEmployee: environment.baseUrl + "/employee" + "/deactivate",
    deleteEmployee: environment.baseUrl + "/employee" + "/delete",
    getAllEmployees: environment.baseUrl + "/employee" + "/fetchall",
    getAllActiveEmployees: environment.baseUrl + "/employee" + "/fetchallactive",
    saveEmployee: environment.baseUrl + "/employee" + "/add",
    fetchEmployeeById: environment.baseUrl + "/employee" + "/fetchbyemployeeid",
 

    getAllInventoryLocationItem: environment.baseUrl + "/inventoryloc" + "/fetchall",
    getAllActiveInventoryLocation: environment.baseUrl + "/inventoryloc" + "/fetchallactive",
    saveInventoryLocationItem: environment.baseUrl + "/inventoryloc" + "/addnew",

    transferInventory: environment.baseUrl + "/inventorytransfer" +"/newtransfer",   
    fetchPendingTransferByCode: environment.baseUrl + "/inventorytransfer" +"/pendingtransfersbyinventorycode",
    fetchPendingAcceptsByCode: environment.baseUrl + "/inventorytransfer" +"/pendingacceptsbyinventorycode",
    fetchPendingTransfer: environment.baseUrl + "/inventorytransfer" +"/pendingtransfers",
    fetchPendingAccepts: environment.baseUrl + "/inventorytransfer" +"/pendingaccepts",

    findByItemCode: environment.baseUrl + "/inventoryitem" + "/fetchbyitemcode",
    findByInventoryItemCode: environment.baseUrl + "/inventoryitem"+"/fetchbyiteminventorycode",
    findByItemCodeByDate: environment.baseUrl + "/inventoryitem" + "/fetchbyitemcodedaterange",
    findByInventoryItemCodeByDate: environment.baseUrl + "/inventoryitem"+"/fetchbyiteminventorycodedaterange",

    acceptRequestItem: environment.baseUrl + "/inventorytransfer" + "/accepttransfer",
    rejectRequestItem: environment.baseUrl + "/inventorytransfer" + "/rejecttransfer",

    addtransactionItem: environment.baseUrl + "/inventoryitem" + "/addtransaction",

   fetchCreditReportAllRange: environment.baseUrl + "/inventoryitem" + "/fetchcreditreportanyrange",
    fetchCreditReportByDate: environment.baseUrl + "/inventoryitem" + "/fetchcreditreportdaterange",
    fetchCreditReportByDateInvCode: environment.baseUrl + "/inventoryitem" + "/fetchcreditreportdaterangeinvcode",

    fetchDebitReportAllRange: environment.baseUrl + "/inventoryitem" + "/fetchdebitreportanyrange",
    fetchDebitReportByDate: environment.baseUrl + "/inventoryitem" + "/fetchdebitreportdaterange",
    fetchDebitReportByDateInvCode: environment.baseUrl + "/inventoryitem" + "/fetchdebitreportdaterangeinvcode",

   

    fetchItemRecovery: environment.baseUrl + "/inventoryitem" + "/fetchitemconsumptionanyrange",
    fetchItemRecoveryByDate: environment.baseUrl + "/inventoryitem" + "/fetchitemconsumptiondaterange",

    fetchItemRecoveryInventoryLocation: environment.baseUrl + "/inventoryitem" + "/fetchitemconsumptionanyrangeinvloc",
    fetchItemRecoveryByDateInventoryLocation: environment.baseUrl + "/inventoryitem" + "/fetchitemconsumptiondaterangeinvloc",

    fetchAllItemByBalanceDetailsAny: environment.baseUrl + "/inventoryitem" + "/fetchitembalanceanyrange",

    uploadInventoryItemFile: environment.baseUrl + "/inventoryitem" + "/uploadinventoryitemexcel",

    fetchAllInvItemRecoveryDetailsAnyRange: environment.baseUrl + "/inventoryitem" + "/fetchallitemsconsumptionreportanyrange",
    fetchAllInvItemRecoveryDetailsByDateRange: environment.baseUrl + "/inventoryitem" + "/fetchallitemsconsumptionreportdaterange",

    fetchAllInvItemRecoveryDetailsAnyRangeInventoryLocation: environment.baseUrl + "/inventoryitem" + "/fetchallitemsconsumptionreportanyrangeinvloc",
    fetchAllInvItemRecoveryDetailsByDateRangeInventoryLocation: environment.baseUrl + "/inventoryitem" + "/fetchallitemsconsumptionreportdaterangeinvloc",


    //CouponsData
    getAllCoupons: environment.baseUrl + "/coupon" +"/findall",
    getAllCouponsGNT: environment.baseUrl + "/coupon" +"/fetchallgnt",
    getAllActiveCoupons: environment.baseUrl + "/coupon" +"/findAllActive",
    createCoupons: environment.baseUrl + "/coupon" + "/generate",
    deleteCoupons: environment.baseUrl + "/coupon" + "/delete",
    activateCoupons: environment.baseUrl + "/coupon" + "/activate",
    deActivateCoupons: environment.baseUrl + "/coupon" + "/deactivate",
    deleteCouponGroup: environment.baseUrl + "/coupon" + "/deletecoupongroup",
    activateCouponGroup: environment.baseUrl + "/coupon" + "/activatecoupongroup",
    deActivateCouponGroup: environment.baseUrl + "/coupon" + "/deactivatecoupongroup",
    fetchGroupCoupon: environment.baseUrl + "/coupon" + "/findbycoupongroup",
    findByCoupons: environment.baseUrl + "/coupon" + "/findbynumber",
    findByPrice: environment.baseUrl + "/coupon" + "/findbyprice",
    findactivebyCouponno: environment.baseUrl + "/coupon" + "/findactivebycouponno",
    fetchDistictCoupons: environment.baseUrl + "/coupon" + "/fetchdistinctcoupongroup",
    fetchDistictCouponsbranchcode: environment.baseUrl + "/coupon" + "/fetchdistinctcoupongroupbranchcode",
    fetchByGroupDetails: environment.baseUrl + "/coupon" + "/fetchbygroupdetails",
    uploadCouponLogo: environment.baseUrl + "/coupon" + "/uploadcouponlogo",
    deleteCouponLogo: environment.baseUrl + "/coupon" + "/deletecouponlogo",
    fetchAllCouponLogo: environment.baseUrl + "/coupon" + "/fetchallcouponlogos",
    fetchCouponLogoBase64ById: environment.baseUrl + "/coupon" + "/fetchlogobase64",
    
    //Bed Order
    getAllBedByHospitalId: environment.baseUrl + "/beddetails" + "/fetchallbyhospitalid",
    getAllActiveBedByHospitalId: environment.baseUrl + "/beddetails" + "/fetchallactivebyhospitalid",
    getAllActiveBed: environment.baseUrl + "/beddetails" + "/fetchallactive",
    getAllBed: environment.baseUrl + "/beddetails" + "/fetchall",
    getByBedNo: environment.baseUrl + "/bedorder" + "/fetchbybednum",
    addBedPayment: environment.baseUrl + "/bedorder" + "/addpayment",
    createBed: environment.baseUrl + "/beddetails" + "/add",
    creatActivate: environment.baseUrl + "/beddetails" + "/activate",
    sendBedExtraOrderSummaryReportAsEmail: environment.baseUrl + "/bedorder" + "/sendbedextraorderemailsummary",
    deleteBedOrderTransaction:environment.baseUrl + "/bedorder" +"/deleteorder",
    deleteBedExtraOrderTransaction:environment.baseUrl + "/bedorder" +"/deletextraeorder",

assignbedmenu: environment.baseUrl + "/bedorder" + "/assignbedmenu",
    fetchAssignedBedMenu: environment.baseUrl + "/bedorder" + "/fetchbedmenu",
    fetchAssignedBedMenuBranchCode: environment.baseUrl + "/bedorder" + "/fetchbedmenubranchcode",
    getAllActiveBedOrders: environment.baseUrl + "/bedorder" + "/fetchallactive",
    getAllActiveBedOrdersByBranchCode: environment.baseUrl + "/bedorder" + "/fetchallactivebybranchcode",
    fetchActiveBedOrdersbyhospitalid: environment.baseUrl + "/bedorder" + "/fetchallactivebyhospitalid",
    addExtraBedOrder: environment.baseUrl + "/bedorder" + "/addextrabedorder",
    addBulkBedOrder: environment.baseUrl + "/bedorder" + "/addbulkbill",

   // deleteBed: environment.baseUrl + "/beddetails" + "/delete",
   
    deActivateBed: environment.baseUrl + "/beddetails" + "/deactivate",
    activateBed: environment.baseUrl + "/beddetails" + "/activate",
    discharge: environment.baseUrl + "/bedorder" + "/discharge",
    addBedPatient: environment.baseUrl + "/bedorder" + "/addnew",
    updateBedPatient: environment.baseUrl + "/bedorder" + "/updatepatientdetails",

    addNewHospital: environment.baseUrl + "/hospital" + "/add",
    deletehospital: environment.baseUrl + "/hospital" + "/delete",
    getallhospitals: environment.baseUrl + "/hospital" + "/findall",
    getallactivehospitals: environment.baseUrl + "/hospital" + "/findallactive",
    findbyhospitalid: environment.baseUrl + "/hospital" + "/findbyhospitalid",
    activatehospital: environment.baseUrl + "/hospital" + "/activate",
   // deleteBed: environment.baseUrl + "/beddetails" + "/delete",
   deactivatehospital: environment.baseUrl + "/hospital" + "/deactivate",
   fetchhospitalbybranchcode:environment.baseUrl + "/hospital" + "/fetchhospitalsbybranchcode",

    /////////Menu Food API
   
    getAllMenu: environment.baseUrl + "/menu" + "/fetchall",
    getAllActiveMenu: environment.baseUrl + "/menu" + "/fetchallactive",
    getAllMenuByBranchCode: environment.baseUrl + "/menu" + "/fetchallbybranchcode",
    getAllActiveMenuByBranchCode: environment.baseUrl + "/menu" + "/fetchallactivebybranchcode",
    getMenuByMenuCode: environment.baseUrl + "/menu" + "/fetchbycode",
    createMenu: environment.baseUrl + "/menu" + "/add",
    addItemToMenu: environment.baseUrl + "/menu" + "/addmenuitem",
    linkMenuItemToMenu: environment.baseUrl + "/menu" + "/linkmenuitem",
    unlinkMenuItemToMenu: environment.baseUrl + "/menu" + "/unlinkmenuitem",
    activateMenu: environment.baseUrl + "/menu" + "/activate",
    deactivateMenu: environment.baseUrl + "/menu" + "/deactivate",
    deleteMenu: environment.baseUrl + "/menu" + "/delete",
    downloadMenuCategoryExcelAll: environment.baseUrl + "/menu" + "/downloadmenucategoryexcelall",
    downloadMenuCategoryExcelBranchCode: environment.baseUrl + "/menu" + "/downloadmenucategoryexcelbranchcode",


    /////////////Item API
    uploadMenuCatgeoryFile: environment.baseUrl + "/menu" + "/uploadfile",
    uploadMenuFile: environment.baseUrl + "/menuitem" + "/uploadfile",
    uploadFoodItemFile: environment.baseUrl + "/fooditem" + "/uploadexcel",
    uploadFoodItemFileWithPrepStep: environment.baseUrl + "/fooditem" + "/uploadreceipefile",
    getAllItem: environment.baseUrl + "/menuitem" + "/fetchall",
    getAllItemByBranchCode: environment.baseUrl + "/menuitem" + "/fetchallbybranchcode",
    getAllActiveItemByBranchCode: environment.baseUrl + "/menuitem" + "/fetchallactivebybranchcode",
    getByMenucodeBranchCode:environment.baseUrl + "/menuitem" + "/fetchbymenucodebranchcode",
    getByMenucode:environment.baseUrl + "/menu" + "/fetchbycode",
    createItem: environment.baseUrl + "/menuitem" + "/add",
    deleteMenuItem: environment.baseUrl + "/menuitem" + "/deletebyitemcode",
    activateItem: environment.baseUrl + "/menuitem" + "/activatebyitemcode",
    deactivateItem: environment.baseUrl + "/menuitem" + "/deactivatebyitemcode",
    addFoodItemToMenuItem: environment.baseUrl + "/menuitem" + "/addfooditem",
    removeFoodItemToMenuItem: environment.baseUrl + "/menuitem" + "/removefooditem",
    downloadMenuItemExcelBranchCode: environment.baseUrl + "/menuitem" + "/downloadmenuoptionexcelbranchcode",
    downloadMenuItemExcelAll: environment.baseUrl + "/menuitem" + "/downloadmenuoptionexcelall",


    getAllActiveFoodItem: environment.baseUrl + "/fooditem" + "/fetchallactive",
    getAllFoodItem: environment.baseUrl + "/fooditem" + "/fetchall",
    createFoodItem: environment.baseUrl + "/fooditem" + "/add",
    deleteFoodItem: environment.baseUrl + "/fooditem" + "/deletebyitemcode",
    activateFoodItem: environment.baseUrl + "/fooditem" + "/activatebyitemcode",
    deactivateFoodItem: environment.baseUrl + "/fooditem" + "/deactivatebyitemcode",
    fetchByFoodItem: environment.baseUrl + "/fooditem" +"/fetchbyitemcode",
    addIngredient: environment.baseUrl + "/fooditem" + "/addingredient",
    removeIngredientFromFoodItem: environment.baseUrl + "/fooditem" + "/removeingredient",
    downloadFoodItemExcelWithPrepStep: environment.baseUrl + "/fooditem" + "/downloaddishexcelwithprepstep",
    downloadFoodItemExcelWithItemList: environment.baseUrl + "/fooditem" + "/downloaddishexcelitemlist",

    linkInventoryItemToMenuItem: environment.baseUrl + "/menuitem" + "/linkdirectsaleitem",
    unlinkInventoryItemToMenuItem: environment.baseUrl + "/menuitem" + "/unlinkdirectsaleitem",
    bulklinkMenuItemtoMenu: environment.baseUrl + "/menu" + "/bulklinkmenuitem",

    //Dish Preperatiom Management API's
    addUpdateDishPrepIngredients: environment.baseUrl + "/dishprep" + "/addupdateingredients",
    deleteDishPrepIngredients: environment.baseUrl + "/dishprep" + "/deleteingredients",
    fetchDishPrepIngredients: environment.baseUrl + "/dishprep" + "/fetchingredients",
    addUpdateDishPrepStep: environment.baseUrl + "/dishprep" + "/addupdateprepstep",
    deleteDishPrepStep: environment.baseUrl + "/dishprep" + "/deleteprepstep",
    fetchDishPrepSteps: environment.baseUrl + "/dishprep" + "/fetchdishprepstep",

    //login
    login: environment.baseUrl + "/userlogin" + "/weblogin",

    //useraccount
    createUser: environment.baseUrl + "/useraccount" + "/create",
    updateUser: environment.baseUrl + "/useraccount" + "/update",
    blockUser: environment.baseUrl + "/useraccount" +"/blockuser",
    unblockUser: environment.baseUrl + "/useraccount" +"/unblockuser",
    activateUser: environment.baseUrl + "/useraccount" +"/activate",
    deactivateUser: environment.baseUrl + "/useraccount" +"/deactivate",
    deleteUser: environment.baseUrl + "/useraccount" +"/delete",
    getAllUser: environment.baseUrl + "/useraccount" +"/fetchAll",
    changePassword: environment.baseUrl + "/useraccount" +"/changepassword",

     //Feedback
     fetchFeedbackByRange: environment.baseUrl + "/feedback" +"/fetchByRange",
     fetchAllUnRead: environment.baseUrl + "/feedback" +"/fetchallnew",
     fetchUnreadByDateRange: environment.baseUrl + "/feedback" +"/fetchnewbydaterange",
     fetchAllQuestions: environment.baseUrl + "/question" +"/fetchAll",

     fetchFeedbackByRangeBranchCode: environment.baseUrl + "/feedback" +"/fetchByRangebranchcode",
     fetchAllUnReadBranchCode: environment.baseUrl + "/feedback" +"/fetchallnewbranchcode",
     fetchUnreadByDateRangeBranchCode: environment.baseUrl + "/feedback" +"/fetchnewbydaterangebranchcode",
     fetchAllQuestionsBranchCode: environment.baseUrl + "/question" +"/fetchallbranchcode",

     updateFeedbackAsViewed: environment.baseUrl + "/feedback" +"/updateViewed",
     activateQuestion: environment.baseUrl + "/question" +"/activate",
     deactivateQuestion: environment.baseUrl + "/question" +"/deactivate",
     addQuestion: environment.baseUrl + "/question" +"/addnew",
     updateQuestion: environment.baseUrl + "/question" +"/update",
    


       //Partner Account Order
       addPartnerAccountOrder: environment.baseUrl + "/partnerorder" +"/addbill",
       fetchPartnerNames: environment.baseUrl + "/partnerorder" +"/fetchpartnernames",
       fetchPartnerOrderSummaryByDateRange: environment.baseUrl + "/partnerorder" +"/fetchsummarybydaterange",
       fetchPartnerOrderSummaryByDateRangeBranchCode: environment.baseUrl + "/partnerorder" +"/fetchsummarybypartnernamedaterangebranchcode",
       fetchPartnerOrderSummaryByNameDateRange: environment.baseUrl + "/partnerorder" +"/fetchsummarybypartnernamedaterange",
       deletePartnerOrderTransaction:environment.baseUrl + "/partnerorder" +"/deleteorder",

     //Organization Account Order
     fetchAllActiveOrgAccountOrder: environment.baseUrl + "/orgorder" +"/fetchallactive",
     fetchAllActiveOrgAccountOrderByBranchCode: environment.baseUrl + "/orgorder" +"/fetchorgaccountorderbybranchcode",
     addOrgAccountOrder: environment.baseUrl + "/orgorder" +"/addbill",
     fetchOrganizationOrderSummaryByDateRange: environment.baseUrl + "/orgorder" +"/fetchsummarybydaterange",
     fetchOrganizationOrderSummaryByOrgIdDateRange: environment.baseUrl + "/orgorder" +"/fetchsummarybyorgiddaterange",
     downloadOrganizationOrderSummaryByOrgIdDateRangeExcel: environment.baseUrl + "/orgorder" +"/downloadsummarybyorgiddaterangeexcel",
     sendEmailSummaryByOrgIdDateRange: environment.baseUrl + "/orgorder" +"/emailorgordersummarybydaterange",
     deleteOrgOrderTransaction:environment.baseUrl + "/orgorder" +"/deleteorder",

     

       //organization
    addOrganizationAccount: environment.baseUrl + "/organization" +"/add",
    updatedOrganizationAccount: environment.baseUrl + "/organization" +"/update",
    fetchAllOrganizationAccounts: environment.baseUrl + "/organization" +"/fetchall",
    findByOrganizationId: environment.baseUrl + "/organization" +"/findbyorgid",
    activateOrganization: environment.baseUrl + "/organization" +"/activate",
    deactivateOrganization: environment.baseUrl + "/organization" +"/deactivate",
    deleteOrganization: environment.baseUrl + "/organization" +"/delete",
    fetchOrgAccountByBranchCode: environment.baseUrl + "/organization" +"/fetchorgaccountsbybranchcode",
    fetchByOrganizationId: environment.baseUrl + "/orgorder" +"/fetchbyorganizationid",
    fetchOrgOrderSummaryByEmail: environment.baseUrl + "/organization" +"/emailorgordersummarybydaterange",
    
    


        //branches
        addNewBranch: environment.baseUrl + "/branches" +"/add",
        updateBranch: environment.baseUrl + "/branches" +"/update",
        fetchAllBranches: environment.baseUrl + "/branches" +"/fetchall",
        findByBranchId: environment.baseUrl + "/branches" +"/findbyid",
        activateBranch: environment.baseUrl + "/branches" +"/activate",
        deactivateBranch: environment.baseUrl + "/branches" +"/deactivate",
        deleteBranch: environment.baseUrl + "/branches" +"/delete",
        linkbranchhospital:environment.baseUrl + "/branches" +"/linkbranchhospital",
        unlinkbranchhospital:environment.baseUrl + "/branches" +"/unlinkbranchhospital",
        linkbranchorgaccount:environment.baseUrl + "/branches" +"/linkbranchorgaccount",
        unlinkbranchorgaccount:environment.baseUrl + "/branches" +"/unlinkbranchorgaccount",
        manageBranchSummaryAlerts: environment.baseUrl + "/branches" +"/managesettings",
        fetchBranchSummaryAlerts: environment.baseUrl + "/branches" +"/fetchsettings",
        fetchComissionAlertEnabledBranches: environment.baseUrl + "/branches" +"/fetchcomissionenabledbranches",
        sendComissionAlertDayByDaySummary: environment.baseUrl + "/branches" +"/sendemaildaybydaysummarybydaterangebranchcode",
        

       


        fetchAllActiveBranchAccountOrder: environment.baseUrl + "/branchorder" +"/fetchallactive",
        addBranchAccountOrder: environment.baseUrl + "/branchorder" +"/addbill",
        fetchBranchOrderSummaryByDateRange: environment.baseUrl + "/branchorder" +"/fetchsummarybydaterange",
        fetchBranchOrderSummaryByBranchIdDateRange: environment.baseUrl + "/branchorder" +"/fetchsummarybybranchiddaterange",
        downloadBranchOrderSummaryByBranchIdDateRangeExcel: environment.baseUrl + "/branchorder" +"/downloadsummarybybranchiddaterangeexcel",
        deleteBranchOrderTransaction:environment.baseUrl + "/branchorder" +"/deleteorder",

   

    //cash expense
    addCashExpense: environment.baseUrl + "/expenses" +"/add",
    fetchCashExpenseByDate: environment.baseUrl + "/expenses" +"/findbydate",
    fetchCashExpenseByAnyRange: environment.baseUrl + "/expenses" +"/findbydaterange",
    fetchCashExpenseByBranchAndDate: environment.baseUrl + "/expenses" +"/findbybranchanddate",

      //pitty cash
      addPittyCashTransaction: environment.baseUrl + "/pittyexpense" +"/addpittycashtransaction",
      fetchPittyCashByDate: environment.baseUrl + "/pittyexpense" +"/fetchpittycashtransactionbydate",
      fetchPittyCashByBranchCodeAndDate: environment.baseUrl + "/pittyexpense" +"/fetchpittycashtransactionbybranchanddate",
      fetchPittyCashByAnyRange: environment.baseUrl + "/pittyexpense" +"/fetchpittycashtransaction",

      //food wastage
      addFoodWastage: environment.baseUrl + "/foodwastage" +"/add",
      fetchFoodWastageByAnyRange: environment.baseUrl + "/foodwastage" +"/findbydaterange",
      fetchFoodWastageByAnyRangeBranchCode: environment.baseUrl + "/foodwastage" +"/findbydaterangebranchcode",

      //Bill Orders
      addWalkInOrderBill: environment.baseUrl + "/walkin" + "/addbill",
      addPreKotOrderBill: environment.baseUrl + "/prekot" + "/addbill",
      migratePreKotBill: environment.baseUrl + "/walkin" + "/migratebill",
      addBedOrderBill: environment.baseUrl + "/bedorder" + "/addbill",
      addDoctorOrderBill: environment.baseUrl + "/doctororder" + "/addbill",
      fetchWalkIn: environment.baseUrl + "/walkin" + "/fetchallactive",
      fetchPreKot: environment.baseUrl + "/prekot" + "/fetchall",
      fetchPreKotBranchCode: environment.baseUrl + "/prekot" + "/fetchallbranchcode",
      addComments: environment.baseUrl + "/prekot" + "/addcomment",
      linkemployee: environment.baseUrl + "/prekot" + "/linkemployee",
      deleteWalkInOrderTransaction:environment.baseUrl + "/walkin" + "/deleteorder",

      //Bill Reports
      activebillSummaryByDate: environment.baseUrl + "/billreport" + "/activebydaterange",
      activebillSummaryByDateBranchCode: environment.baseUrl + "/billreport" + "/activebydaterangebranchcode",
      bedOrderSummaryByDate: environment.baseUrl + "/billreport" + "/bedordersdaybyday",
      patientWiseSummaryByDate: environment.baseUrl + "/bedorder" + "/fetchpatientslistbydate",
      patientWiseSummaryByDateHospitalId: environment.baseUrl + "/bedorder" + "/fetchpatientslistbydatehospitalid",
      patientWiseSummaryByDateBranchCode: environment.baseUrl + "/bedorder" + "/fetchpatientslistbydatebranchcode",
      overAllSummaryByDate: environment.baseUrl + "/billreport" + "/overallsummarydaybyday",
      overAllSummaryByDateBranchCode: environment.baseUrl + "/billreport" + "/overallsummarydaybydaybranchcode",
      couponSummaryByDate: environment.baseUrl + "/billreport" + "/couponsummarydaybyday",
      couponSummaryByDateBranchCode: environment.baseUrl + "/billreport" + "/couponsummarydaybydaybranchcode",
      cashierLogoutSummaryByDate: environment.baseUrl + "/logout" + "/cashiersummarybydaterange",
      cashierLogoutSummaryByDateBranchCode: environment.baseUrl + "/logout" + "/cashiersummarybydaterangebranchcode",
      fetchDoctorCreditSummary: environment.baseUrl + "/doctororder" + "/fetchoverallcreditsummary",
      fetchallunsettledorders: environment.baseUrl + "/bedorder" + "/fetchallunsettled",
      fetchallunstelledorderbyhospitalid: environment.baseUrl + "/bedorder" + "/fetchallunsettledbyhospitalid",

      addAdjustedOverallOrdersDaySummary: environment.baseUrl + "/billreport" + "/adjustoveralldaysummary",
      addAdjustedCouponDaySummary: environment.baseUrl + "/billreport" + "/adjustcouponcount",
      addAdjustedBedOrderDaySummary: environment.baseUrl + "/billreport" + "/adjustbedorders",
      
      menuItemSalesSummaryByDateRange:environment.baseUrl + "/billreport" + "/menuitemsalessummarybydaterange",
      menuItemSalesSummaryByDateRangeBranchCode:environment.baseUrl + "/billreport" + "/menuitemsalessummarybydaterangebranchcode",

      fetchConsolidatedComissionSummaryByDateRangeBranchCode:environment.baseUrl + "/billreport" + "/fetchprofitsharingconsolidatedsummarybydaterangebranchcode",
      fetchDayByDayComissionSummaryByDateRangeBranchCode:environment.baseUrl + "/billreport" + "/fetchprofitsharingdaybydaysummarybydaterangebranchcode",

      //Logout Summary
      logoutsummary: environment.baseUrl + "/logout" + "/summarise",
      logoutsummaryByBranchCode: environment.baseUrl + "/logout" + "/summarisebybranchcode",

       //OTP Sms
       sendotp: environment.baseUrl + "/otp" + "/send",

      //Checklist
      fetchfoodsamplingSummary: environment.baseUrl + "/checklist/fetchfoodsampingdatabydaterange",
      fetchCookingTempSummary: environment.baseUrl + "/checklist/fetchcookingtempdatabydaterange",
      fetchFreezerTempSummary: environment.baseUrl + "/checklist/fetchfreezertempdatabydaterange",
      fetchKitchenCleaningSummary: environment.baseUrl + "/checklist/fetchkitchencleaningdatabydaterange",
      fetchPersonalHygieneSummary: environment.baseUrl + "/checklist/fetchpersonalhygeinedatabydaterange",
      fetchSesnoryEvalSummary: environment.baseUrl + "/checklist/fetchsensoryevaldatabydaterange",
      fetchPotWashCleaningSummary: environment.baseUrl + "/checklist/fetchpotwashcleaningdatabydaterange",
      fetchChlorineSummary: environment.baseUrl + "/checklist/fetchchlorinesanitisationdatabydaterange",

      fetchfoodsamplingSummaryBranchCode: environment.baseUrl + "/checklist/fetchfoodsampingdatabydaterangebranchcode",
      fetchCookingTempSummaryBranchCode: environment.baseUrl + "/checklist/fetchcookingtempdatabydaterangebranchcode",
      fetchFreezerTempSummaryBranchCode: environment.baseUrl + "/checklist/fetchfreezertempdatabydaterangebranchcode",
      fetchKitchenCleaningSummaryBranchCode: environment.baseUrl + "/checklist/fetchkitchencleaningdatabydaterangebranchcode",
      fetchPersonalHygieneSummaryBranchCode: environment.baseUrl + "/checklist/fetchpersonalhygeinedatabydaterangebranchcode",
      fetchSesnoryEvalSummaryBranchCode: environment.baseUrl + "/checklist/fetchsensoryevaldatabydaterangebranchcode",
      fetchPotWashCleaningSummaryBranchCode: environment.baseUrl + "/checklist/fetchpotwashcleaningdatabydaterangebranchcode",
      fetchChlorineSummaryBranchCode: environment.baseUrl + "/checklist/fetchchlorinesanitisationdatabydaterangebranchcode",

      //print
      printExtraBedOrderSummary: "http://localhost:8080/print/bedordersummary",
      printbill: "http://localhost:8080/print/bill",
      printbillKOT: "http://localhost:8080/print/billkot",
      printbillsplitKOT: "http://localhost:8080/print/billsplitkot",
      printkot: "http://localhost:8080/print/kot",
      printsplitkot: "http://localhost:8080/print/splitkot",
      shutdownprint: "http://localhost:8080/print/shutdown",


      //Assets
      uploadAssetItemExcel: environment.baseUrl + "/fixedassets" + "/uploadexcel",
      fixedassetaddnew: environment.baseUrl + "/fixedassets" + "/insert",
      fixedassetactivate: environment.baseUrl + "/fixedassets" + "/activate",
      fixedassetdeactivate: environment.baseUrl + "/fixedassets" + "/deactivate",
      fixedassetdelete: environment.baseUrl + "/fixedassets" + "/delete",
      fixedassetfindbyassetcode: environment.baseUrl + "/fixedassets" + "/findbyassetcode",
      fixedassetfetchallactive: environment.baseUrl + "/fixedassets" + "/fetchallactive",
      fixedassetfetchall: environment.baseUrl + "/fixedassets" + "/fetchall",
      fixedassetfetchallactiveinvcode: environment.baseUrl + "/fixedassets" + "/fetchallactivebyinvcode",
      fixedassetfetchallinvcode: environment.baseUrl + "/fixedassets" + "/fetchallbyinvcode",

      //Assets Transaction
      fixedassettranadd: environment.baseUrl + "/fixedassetstran" + "/add",
      fixedassettranaccept: environment.baseUrl + "/fixedassetstran" + "/accept",
      fixedassettranreject: environment.baseUrl + "/fixedassetstran" + "/reject",
      fixedassettrandelete: environment.baseUrl + "/fixedassetstran" + "/delete",
      fixedassettranfetchallapprovedassetcode: environment.baseUrl + "/fixedassetstran" + "/fetchallapprovedbyassetcode",
      fixedassettranfetchallapprovedassetcodeinvcode: environment.baseUrl + "/fixedassetstran" + "/fetchallapprovedbyassetcodeinvcode",
      fixedassettranfetchallnewassetcode: environment.baseUrl + "/fixedassetstran" + "/fetchallnewbyassetcode",
      fixedassettranfetchallnewinvcode: environment.baseUrl + "/fixedassetstran" + "/fetchallnewtransinvcode",
      fixedassettranfetchallnew: environment.baseUrl + "/fixedassetstran" + "/fetchallnewtrans",
      fixedassettranalldebitassetcode: environment.baseUrl + "/fixedassetstran" + "/fetchalldebitbyassetcode",
      fixedassettranallcreditassetcode: environment.baseUrl + "/fixedassetstran" + "/fetchallcreditbyassetcode",
      fixedassettranallacceptedassetcode: environment.baseUrl + "/fixedassetstran" + "/fetchallacceptedbyassetcode",
      fixedassettranallrejectedassetcode: environment.baseUrl + "/fixedassetstran" + "/fetchallrejectedbyassetcode",
      fixedassettranallacceptedassetcodeinvcode: environment.baseUrl + "/fixedassetstran" + "/fetchallacceptedbyassetcodeinvcode",
      fixedassettranallrejectedassetcodeinvcode: environment.baseUrl + "/fixedassetstran" + "/fetchallrejectedbyassetcodeinvcode",

      //KitchenInventoryDebits
      kitchendebitsnewrequest: environment.baseUrl + "/kitchendebits" + "/request",
      kitchendebitsnewbulkrequest: environment.baseUrl + "/kitchendebits" + "/bulkrequest",
      kitchendebitsacceptrequest: environment.baseUrl + "/kitchendebits" + "/accept",
      kitchendebitsrejectrequest: environment.baseUrl + "/kitchendebits" + "/reject",
      kitchendebitsdeleterequest: environment.baseUrl + "/kitchendebits" + "/delete",
      kitchendebitsfetchallnew: environment.baseUrl + "/kitchendebits" + "/fetchallnew",
      kitchendebitsfetchallnewbranchcode: environment.baseUrl + "/kitchendebits" + "/fetchallnewbranchcode",
      kitchendebitsfetchallrejected: environment.baseUrl + "/kitchendebits" + "/fetchallrejected",
      kitchendebitsfetchallrejectedbranchcode: environment.baseUrl + "/kitchendebits" + "/fetchallrejectedbranchcode",
      kitchendebitsfetchallbranchcodeDateRange: environment.baseUrl + "/kitchendebits" + "/fetchallbydaterangebranchcode",
      kitchendebitsfetchallDateRange: environment.baseUrl + "/kitchendebits" + "/fetchallbydaterange",


      linkmenutoday: environment.baseUrl + "/linkmenutoday" + "/link",
      bulklinkmenutoday: environment.baseUrl + "/linkmenutoday" + "/bulklink",
      unlinkmenutoday: environment.baseUrl + "/linkmenutoday" + "/unlink",
      fetchalllinkedmenutodaybranchcode: environment.baseUrl + "/linkmenutoday" + "/fetchbybranchcodeday",
      fetchallemenuitembydaybranchcode: environment.baseUrl + "/linkmenutoday" + "/fetchbybranchcodemenuiddayallday",


      linkfooditemtoday: environment.baseUrl + "/linkfooditemtoday" + "/link",
      bulklinkfooditemtoday: environment.baseUrl + "/linkfooditemtoday" + "/bulklink",
      unlinkfooditemtoday: environment.baseUrl + "/linkfooditemtoday" + "/unlink",
      fetchalllinkedfooditemtodaybranchcode: environment.baseUrl + "/linkfooditemtoday" + "/fetchbybranchcodeday",
      fetchallfooditemitembydaybranchcode: environment.baseUrl + "/linkfooditemtoday" + "/fetchbybranchcodefooditemiddayallday",



};