import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';
import { Item,Ingredient,Menu, FoodItem, MenuItemFoodItemLink, MenuMenuItemLink} from "../../bean/Menu";
import { MenuService } from '../../../service/menuServ/menu.service';
import { OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatTableDataSource, MatSnackBar } from '@angular/material';
import { BedOrderDetails, Bed, BedPaymentDetails, BedBillDetails, BedPayment } from '../../bean/Bed';
import { BedOrderService } from '../../../service/bed/bed-order.service';
import { MessageComponent } from '../../Generic/message/message.component';
import { DoctorOrder, DoctorPaymentDetails, DoctorBillDetails, Doctor, DoctorPayment, DoctorBillItems, BillPayment, DoctorCouponDetails } from '../../bean/Doctor';
import { DmangementService } from '../../../service/doctor/dmangement.service';
import { BillDetials, BillItems, CouponDetails, WalkIN } from '../../bean/Company';
import { Coupons } from '../../bean/Coupon';
import { CouponServiceService } from '../../../service/coupon/coupon-service.service';
import { AuthenticationService } from '../../../service/auth/authentication.service';

@Component({
    selector: 'PopupDoctorOrders',
    templateUrl: 'popupdoctororder.html',
})
export class PopupDoctorOrder {

    myControl = new FormControl();
    dataSource: MatTableDataSource<DoctorBillDetails>;
    

    selectedMenuItemIndex:number;
    tableData:DoctorOrder=new DoctorOrder();

    doctorPaymentData:DoctorPaymentDetails[];
    doctorCouponData:DoctorCouponDetails[];
    doctorBillData:DoctorBillDetails[];


    doctor:Doctor;

    displayPaymentView:boolean=false;

    submitted:boolean=false;

    advancePaymentSubmitted:boolean=false;
    discountPaymentSubmitted:boolean=false;

    walkInData:WalkIN;

    doctorPaymentForm: FormGroup;

    doctorAdvancePaymentForm: FormGroup;
    doctorDiscountPaymentForm: FormGroup;

    model:BillPayment=new BillPayment();

    modelAdvancePayment:BillPayment=new BillPayment();
    modelDiscountPayment:BillPayment=new BillPayment();


    doctorPayment:DoctorPayment;

    selectedIndex:number;
    selectedDoctorOrder:DoctorOrder;

    displayDetailView:boolean;

    displayAdvancePaymentView:boolean;
    displayDiscountPaymentView:boolean;

    invoiceNo:string;

    payments: string[] = ["None","Cash","Coupon","UPI"]

    cashPaymentView:boolean;
    couponPaymentView:boolean;
    couponsubmitted:boolean;
    couponNumber:string;
    doctorCouponPaymentForm:FormGroup;

    newCouponList:CouponDetails[]=[];

    paymentMode:string;

    selectedType:string;

    loading:boolean;

    username:string;
    name:string;

    discountVisible:boolean=false;


    constructor(private authService:AuthenticationService,private couponService:CouponServiceService,private _snackBar: MatSnackBar,private menuSerivce: MenuService,private formBuilder: FormBuilder,public dialogRef: MatDialogRef<PopupDoctorOrder>,
         @Inject(MAT_DIALOG_DATA) public data: Doctor,
        private mServiceMenu: DmangementService
        )
    {
       
        this.doctor=data;
        console.log("Doctor Id="+this.doctor.doctorId);

      
      
        this.dataSource = new MatTableDataSource(this.tableData.billdetails);

        this.username=this.authService.currentUserValue.username;
        this.name=this.authService.currentUserValue.firstName+" "+this.authService.currentUserValue.lastName;
       
     
    }

    ngOnInit() {

        this.displayAdvancePaymentView=false;
        this.displayDiscountPaymentView=false;

        this.newCouponList=[];
        this.paymentMode=this.payments[0];
        
        this.doctorPaymentForm = this.formBuilder.group({
          amount: ['', Validators.required],
          paymentMode: ['', Validators.required]
      });

      this.doctorAdvancePaymentForm = this.formBuilder.group({
        aamount: ['', Validators.required]
    });

    this.doctorDiscountPaymentForm = this.formBuilder.group({
      discountAmount: ['', Validators.required],
  });

    this.selectedType=this.payments[0];
    this.cashPaymentView=false;
    this.couponPaymentView=false;
    this.couponsubmitted=false;
    this.couponNumber="";
    this.newCouponList=[];
    this.doctorCouponPaymentForm= this.formBuilder.group({
        couponNumber: ['', Validators.required]
    });

      this.submitted=false;
      this.advancePaymentSubmitted=false;
      this.discountPaymentSubmitted=false;

     
          this.fetchDoctorOrder();
         
     
    }

    onPaymentModeSelectionChange(event)
    {
        if(event.target.value==this.payments[1])
        {
            this.discountVisible=true;
        }
        else
        {
            this.discountVisible=false;
        }

    }

    deleteCoupon(index:number)
    {
      this.newCouponList.splice(index, 1);
    }

    public onOptionsSelected(event) {
        const value = event.target.value;
        this.selectedType = value;
        if(this.selectedType==this.payments[0])
        {
          this.cashPaymentView=false;
          this.couponPaymentView=false;
          
        }
        else if(this.selectedType==this.payments[1])
        {
          this.cashPaymentView=true;
          this.couponPaymentView=false;
          this.discountVisible=true;
        }
        else if(this.selectedType==this.payments[2])
        {
          this.cashPaymentView=false;
          this.couponPaymentView=true;
         
        }
        else if(this.selectedType==this.payments[3])
        {
            this.cashPaymentView=true;
            this.couponPaymentView=false;
            this.discountVisible=true;
        }
      }

    findCouponByNumber(): void {

        this.couponsubmitted=true;
    
        if(this.doctorCouponPaymentForm.invalid)
        {
          return;
        }
         
       var data={"couponNo":this.couponNumber};
    
     console.log(data);
    
     this.couponService.findByCouponNumber(data).subscribe(        
       (response) => {
         console.log(response);
         if(response.responseCode)
         {
         
          var coupon:Coupons=response.responseObject as Coupons;
          
          var couponDetail:CouponDetails =new CouponDetails();
          couponDetail.branchCode=this.authService.currentUserValue.branch.branchCode;
         couponDetail.couponNumber=coupon.couponNumber;
         couponDetail.name=coupon.name;
         couponDetail.price=coupon.price;
         couponDetail.type=coupon.type
          this.newCouponList.push(couponDetail);
    
          this.doctorCouponPaymentForm.reset();
          this.couponsubmitted=false;
          
         }
         else
         {
         this.openSnackBar("No Coupon Exists for this Price");
    
         }
     },error=>{
       console.log(error);
         alert("No Coupon Exists for this Price");
     
    });
    
    }

    fetchDoctorOrder():void
    {
        var doctorId={"doctorId":this.doctor.doctorId};
        this.mServiceMenu.fetchDoctorOrder(doctorId).subscribe(data=>{
            console.log(data.responseObject);
            this.tableData = data.responseObject as DoctorOrder;
            this.selectedDoctorOrder=data.responseObject;
            this.doctorBillData=this.selectedDoctorOrder.billdetails;
            this.doctorPaymentData=this.selectedDoctorOrder.paymentdetails;
            this.doctorCouponData=this.selectedDoctorOrder.coupondetails;
            console.log( "Data"+this.selectedDoctorOrder);
            console.log( "Data"+this.tableData);
         });
    }

    printBill(bill:BedBillDetails)
    {
        console.log(bill);
        var walkInData=new WalkIN();
        walkInData.paymentAmount=bill.amountPaid
        walkInData.totalAmount=bill.totalAmount
        walkInData.discountAmount=0;
        
        walkInData.subscidised=bill.isSubscidised;
     //   walkInData.bedTransactionNo=this.selectedBed.bedTransactionNo;
        walkInData.billdetails=new BillDetials();
        walkInData.billdetails.invoiceNo=bill.invoiceNo;
        walkInData.billdetails.kotNo=bill.kotNo;
        walkInData.billdetails.totalItems=bill.totalItems;
        walkInData.billdetails.diffItemsCount=bill.diffItemsCount;
        walkInData.billdetails.amountPaid=bill.amountPaid;
        walkInData.billdetails.totalAmount=bill.totalAmount;

        walkInData.billdetails.createdDate=bill.createdDate;
        walkInData.billdetails.billitems=[];
        for (var bedBillItem of bill.billitems) {
            var billItem=new BillItems();
            billItem.totalPrice=bedBillItem.totalPrice;
            billItem.quantity=bedBillItem.quantity;
            billItem.price=bedBillItem.price;
            billItem.itemName=bedBillItem.itemName;
            billItem.menuItemCode=bedBillItem.menuItemCode;
            
            walkInData.billdetails.billitems.push(billItem);
       }


        walkInData.createdUsername=this.authService.currentUser.firstName+" "+this.authService.currentUser.lastName;
        walkInData.createdByName=this.authService.currentUserValue.firstName+" "+this.authService.currentUserValue.lastName;
     

    }

    
   
    viewBillDetails(i:number):void
    {
     //   this.selectedIndex=i;
        this.displayDetailView=true;
       // this.doctorBillData=this.tableData.billdetails;
       // this.doctorPaymentData=this.tableData.paymentdetails;

       this.doctorBillData=this.selectedDoctorOrder.billdetails;
       this.doctorPaymentData=this.selectedDoctorOrder.paymentdetails;

    }

    hideDetailView():void
    {
        this.displayDetailView=false;
    }
   

    newPayment(invoiceNo:string):void
    {
        
        this.displayPaymentView=true;
        this.invoiceNo=invoiceNo;
       
    }

    closePaymentView():void
    {
        this.displayPaymentView=false;
        this.doctorPaymentForm.reset();
        this.submitted=false;
       
    }

    showAddPaymentView():void
    {
        this.displayAdvancePaymentView=true;
        this.displayDiscountPaymentView=false;
    }

    showDiscountPaymentView()
    {
      this.displayAdvancePaymentView=false;
      this.displayDiscountPaymentView=true;
    }

    closeAdvancePaymentView()
    {
        this.displayAdvancePaymentView=false;
        this.displayDiscountPaymentView=false;
        this.doctorAdvancePaymentForm.reset();
    }

    closeDiscountPaymentView()
    {
        this.displayAdvancePaymentView=false;
        this.displayDiscountPaymentView=false;
        this.doctorDiscountPaymentForm.reset();
    }

    onAdvancePaymentSubmit()
    {
        this.advancePaymentSubmitted = true;

       
    
        // stop the process here if form is invalid
        if (this.doctorAdvancePaymentForm.invalid) {
            return;
        }

        this.modelAdvancePayment.paymentMode=this.paymentMode;

        if(this.modelAdvancePayment.amount<=0)
        {
            this.openSnackBar("Enter Valid Amount");
            return;
        }
        if(this.modelAdvancePayment.discount<0)
        {
            this.openSnackBar("Enter Valid Discount");
            return;
        }

        this.loading=true;
        this.walkInData=new WalkIN();
        this.walkInData.branchCode=this.authService.currentUserValue.branch.branchCode;
        this.modelAdvancePayment.branchCode=this.authService.currentUserValue.branch.branchCode;
        this.walkInData.billdetails=new BillDetials();
        this.walkInData.billdetails.branchCode=this.authService.currentUserValue.branch.branchCode;
        this.walkInData.billdetails.billitems=[];
        this.walkInData.paymentdetails=[];
        this.walkInData.createdUsername=this.username;
        this.walkInData.createdByName=this.name;
        this.walkInData.paymentdetails.push(this.modelAdvancePayment);
        this.walkInData.coupondetails=[];
        this.walkInData.commentsdetails=[]
        this.walkInData.doctorId=this.doctor.doctorId;
        this.walkInData.paymentAmount=this.modelAdvancePayment.amount;
        this.walkInData.couponAmount=0;
        this.walkInData.totalAmount=0;

        this.walkInData.doctorName=this.doctor.firstName+" "+this.doctor.middleName+" "+this.doctor.lastName;
        this.walkInData.billdetails.amountPaid=this.modelAdvancePayment.amount;
        this.walkInData.billdetails.discountAmount=this.modelAdvancePayment.discount;
        
       
        var billItem =new BillItems();
        billItem.branchCode=this.authService.currentUserValue.branch.branchCode;
        billItem.itemSeqNo=1;
        billItem.itemName="payment";
        billItem.menuItemCode="payment"
        billItem.price=0;
        billItem.quantity=1;
        billItem.subscidisedPrice=0;
        billItem.totalPrice=0;
        this.walkInData.billdetails.billitems.push(billItem);
        console.log(this.walkInData);
        this.menuSerivce.addDoctorOrderBill(this.walkInData).subscribe(data=>{
          this.loading=false;
          if(data.responseCode)
          {
            this.walkInData.billdetails.invoiceNo=data.responseObject.invoiceNo;
            this.walkInData.billdetails.kotNo=data.responseObject.kotNo;
            this.openSnackBar(data.message);
            this.doctorAdvancePaymentForm.reset();
            this.ngOnInit();
         }
        },
        error=>
        {

          this.loading=false;
          alert('Payment Submit Failed, Please Try Again');
        }
        );
    }


    onDiscountPaymentSubmit()
    {
        this.discountPaymentSubmitted = true;

       
    
        // stop the process here if form is invalid
        if (this.doctorDiscountPaymentForm.invalid) {
            return;
        }

      

        if(this.modelDiscountPayment.discount<=0)
        {
            this.openSnackBar("Enter Valid Discount Amount");
            return;
        }
    
        this.modelDiscountPayment.amount=0;
        this.modelDiscountPayment.paymentMode="Discount";
        this.modelDiscountPayment.branchCode=this.authService.currentUserValue.branch.branchCode;
        
        this.loading=true;
        this.walkInData=new WalkIN();
        this.walkInData.branchCode=this.authService.currentUserValue.branch.branchCode;
        this.walkInData.billdetails=new BillDetials();
        this.walkInData.billdetails.billitems=[];
        this.walkInData.paymentdetails=[];
        this.walkInData.createdUsername=this.username;
        this.walkInData.createdByName=this.name;
        this.walkInData.paymentdetails.push(this.modelDiscountPayment);
        this.walkInData.coupondetails=[];
        this.walkInData.commentsdetails=[]
        this.walkInData.doctorId=this.doctor.doctorId;
        this.walkInData.paymentAmount=this.modelDiscountPayment.amount;
        this.walkInData.discountAmount=this.modelDiscountPayment.discount;
        this.walkInData.couponAmount=0;
        this.walkInData.totalAmount=0;

        
        this.walkInData.doctorName=this.doctor.firstName+" "+this.doctor.lastName;
        this.walkInData.billdetails.amountPaid=this.modelDiscountPayment.amount;
        this.walkInData.billdetails.discountAmount=this.modelDiscountPayment.discount;
        
       
        var billItem =new BillItems();
        billItem.itemSeqNo=1;
        billItem.itemName="payment";
        billItem.menuItemCode="payment"
        billItem.price=0;
        billItem.quantity=1;
        billItem.subscidisedPrice=0;
        billItem.totalPrice=0;
        this.walkInData.billdetails.billitems.push(billItem);
        console.log(this.walkInData);
        this.menuSerivce.addDoctorOrderBill(this.walkInData).subscribe(data=>{
          this.loading=false;
          if(data.responseCode)
          {
            this.walkInData.billdetails.invoiceNo=data.responseObject.invoiceNo;
            this.walkInData.billdetails.kotNo=data.responseObject.kotNo;
            this.openSnackBar(data.message);
            this.doctorDiscountPaymentForm.reset();
            this.ngOnInit();
         }
        },
        error=>
        {

          this.loading=false;
          alert('Payment Submit Failed, Please Try Again');
        }
        );
    }

    onPaymentSubmit() {
        this.submitted = true;
    
        // stop the process here if form is invalid
        if (this.doctorPaymentForm.invalid) {
            return;
        }

        if(this.model.amount<=0)
        {
            this.openSnackBar("Invalid Amount");
            return;
        }
    
        this.model.branchCode=this.authService.currentUserValue.branch.branchCode;
        this.doctorPayment=new DoctorPayment();
        this.doctorPayment.doctorId=this.selectedDoctorOrder.doctorId;
        this.doctorPayment.transactionNo=this.selectedDoctorOrder.transactionNo;
        this.doctorPayment.invoiceNo=this.invoiceNo;
        this.doctorPayment.createdUsername=this.username;
        this.doctorPayment.createdName=this.name;
        console.log("Doctor Id="+this.selectedDoctorOrder.doctorId);
        console.log("Trans No="+this.doctorPayment.transactionNo);
        this.doctorPayment.paymentdetails=this.model;
        console.log(this.doctorPayment);
        this.mServiceMenu.addDoctorPayment(this.doctorPayment).subscribe(data => {
          if(data.responseCode)
          {
          this.openSnackBar(data.message);
          this.displayPaymentView=false;
          this.doctorPaymentForm.reset();
          this.ngOnInit();
         // this.fetchDoctorOrder();
        
          }
          else{
            alert(data.message);
          }
        }
        );

       
      

      
      
      
    }

    openSnackBar(message: string) {
        this._snackBar.openFromComponent(MessageComponent, {
          data: message,
          duration: 5 * 1000
        });
      }
  
    
    onCloseClick(): void {
        this.dialogRef.close();
    }

    updateCouponAmount():number
    {
      var total=0;
   
      for(let i=0;i<this.newCouponList.length;i++)
      {
        total=total+this.newCouponList[i].price;
      }
     return total;
   
   
    }


    submitCouponPayment()
    {

        if(this.newCouponList.length<=0)
        {
            this.openSnackBar("No Coupons Added");
            return;
        }

        this.loading=true;
        this.walkInData=new WalkIN();

        this.walkInData.branchCode=this.authService.currentUserValue.branch.branchCode;
        this.walkInData.billdetails=new BillDetials();
        this.walkInData.billdetails.billitems=[];
        this.walkInData.paymentdetails=[]
        this.walkInData.coupondetails=[];
        
        this.walkInData.createdUsername=this.username;
        this.walkInData.createdByName=this.name;
        this.walkInData.coupondetails=this.newCouponList;
        this.walkInData.commentsdetails=[]
        this.walkInData.doctorId=this.doctor.doctorId;
        this.walkInData.paymentAmount=0;
        this.walkInData.couponAmount=this.updateCouponAmount();
        this.walkInData.doctorName=this.doctor.firstName+" "+this.doctor.middleName+" "+this.doctor.lastName;
        this.walkInData.billdetails.amountPaid=this.updateCouponAmount();
        this.walkInData.totalAmount=0;
        var billItem =new BillItems();
        billItem.itemSeqNo=1;
        billItem.itemName="payment";
        billItem.menuItemCode="payment"
        billItem.branchCode=this.authService.currentUserValue.branch.branchCode;
        billItem.price=0;
        billItem.quantity=1;
        billItem.subscidisedPrice=0;
        billItem.totalPrice=0;
        this.walkInData.billdetails.billitems.push(billItem);
        console.log(this.walkInData);
        this.menuSerivce.addDoctorOrderBill(this.walkInData).subscribe(data=>{
          this.loading=false;
          if(data.responseCode)
          {
            this.walkInData.billdetails.invoiceNo=data.responseObject.invoiceNo;
            this.walkInData.billdetails.kotNo=data.responseObject.kotNo;
            this.openSnackBar(data.message);
            this.doctorCouponPaymentForm.reset();
            this.doctorAdvancePaymentForm.reset();
            this.ngOnInit();
         }
        });
    }


    


    sendEmailSummary()
    {

      var doctorDetails={"doctorId":this.doctor.doctorId,
    "transactionNo":this.tableData.transactionNo};
      this.mServiceMenu.sendDoctorEmailSummary(doctorDetails).subscribe(data=>{
          this.openSnackBar("Email Summary Sent Successfully");
       });

    }



}