import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy, DatePipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { AppComponent } from './app.component';

// Import containers


import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';

const APP_CONTAINERS = [
  DefaultLayoutComponent,
  IadminLayoutComponent,
  billingLayoutComponent,
  SadminLayoutComponent,
  SupervisorLayoutComponent,
  HospitalAdminContainerComponent,
  KithchenAdminComponent
];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule
} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts';
import { MaterialModule} from './material';


///
import { ManagementModule} from "./views/inventory/management.module";
import { HttpClientModule } from '@angular/common/http';

import {  HTTP_INTERCEPTORS } from '@angular/common/http';

import { ErrorInterceptor } from './interceptors/auth/ErrorInterceptor';
import { CommonInterceptor } from './interceptors/auth/httpInterceptor';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { MyLoaderComponent } from './views/general/my-loader/my-loader.component';

import { LoaderService } from './service/loader.service';
import { LoaderInterceptor } from './interceptors/loader-interceptor.service';
import { IadminLayoutComponent } from './containers/inventory-admin/iadmin-layout/iadmin-layout.component';
import { DefaultLayoutComponent } from './containers/default-layout/default-layout.component';
import { billingLayoutComponent } from './containers/billing/billing-layout.component';
import { SadminLayoutComponent } from './containers/super-admin/sadmin-layout.component';
import { SupervisorLayoutComponent } from './containers/supervisor/supervisor-layout.component';
import { KithchenAdminComponent } from './containers/kitchen/kitchenadmin.component';
import { BasicAuthInterceptor } from './service/auth/basic-auth.interceptor';
import { LogoutComponent } from './views/logout/logout.component';
import { LogoutsummaryComponent } from './views/logout/logoutsummary/logoutsummary.component';
import { ChangepasswordComponent } from './views/user/changepassword/changepassword.component';
import { PopupDoctorOrder } from './views/walk-in/popup/PopupDoctorOrder';
import { PopupBedOrders } from './views/walk-in/popup/PopupBedOrders';
import { AdjustoverallsummaryComponent } from './view/billreport/adjustoverallsummary/adjustoverallsummary.component';
import { PittycashComponent } from './views/expense/pittycash/pittycash.component';
import { CookingtempComponent } from './views/cookingtemp/cookingtemp/cookingtemp.component';
import { AfterloginselectionComponent } from './views/afterloginselection/afterloginselection.component';
import { ConfirmPopup } from './views/popup/ConfirmPopup';
import { HospitalAdminContainerComponent } from './containers/hospital/hospital-container.component';
import { FixedassetnewreqComponent } from './view/inventory/fixedassets/fixedassetnewreq/fixedassetnewreq.component';
import { ReceipelistComponent } from './views/kitchen/receipelist/receipelist.component';
import { DebitreceipeitemsComponent } from './views/kitchen/debitreceipeitems/debitreceipeitems.component';
import { ReciepedebitrequestsComponent } from './views/kitchen/reciepedebitrequests/reciepedebitrequests.component';
import { CommentPopup } from './views/popup/CommentPopup';
import { KitchenOrderDetailsPopup } from './views/kitchen/popup/KitchenOrderDetailsPopup';
import { AlertPopup } from './views/popup/AlertPopup';
import { DayfooditemsrequestComponent } from './views/kitchen/dayfooditemsrequest/dayfooditemsrequest.component';
import { NgxBarcodeModule } from 'ngx-barcode';
import { FoodprepstepsComponent } from './view/menu/food-item/foodprepsteps/foodprepsteps.component';
import { ReciepeprepstepsComponent } from './views/kitchen/reciepeprepsteps/reciepeprepsteps.component';



@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
    MaterialModule,
    HttpClientModule,
    ManagementModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    P404Component,
    P500Component,
    LoginComponent,
    RegisterComponent,
    MyLoaderComponent,
    LogoutComponent,
    LogoutsummaryComponent,
    ChangepasswordComponent,
    PopupDoctorOrder,
    PopupBedOrders,
    AdjustoverallsummaryComponent,
    PittycashComponent,
    CookingtempComponent,
    AfterloginselectionComponent,
    ConfirmPopup,
    CommentPopup,
    AlertPopup,
    KitchenOrderDetailsPopup,
    FixedassetnewreqComponent,
    ReceipelistComponent,
    DebitreceipeitemsComponent,
    ReciepedebitrequestsComponent,
    DayfooditemsrequestComponent,
    FoodprepstepsComponent,
    ReciepeprepstepsComponent
  ],
  entryComponents: [LogoutsummaryComponent,ChangepasswordComponent,PopupDoctorOrder,PopupBedOrders, ConfirmPopup,CommentPopup,KitchenOrderDetailsPopup,AlertPopup],
  providers: [
    DatePipe,
    {
    provide: [
     
      LocationStrategy,
       { provide: HTTP_INTERCEPTORS, useClass: CommonInterceptor, multi: true },
       { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
    ],
    useClass: HashLocationStrategy,

  }
  ,
  LoaderService, { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
