import { Component, OnInit } from '@angular/core';
import { ViewChild, Input } from '@angular/core';
import { ManagementService } from '../../../service/inventory/inventory.service';
import { InventoryLocations, InventoryData, InventoryTransfer, InventoryTransferRequest, InventoryTransData } from '../../bean/Inventory';
import { MatRadioButton } from '@angular/material';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MessageComponent } from "../../Generic/message/message.component";
import { MatDialog } from '@angular/material/dialog';
import { TransferInventory } from "../popups/transferInventory";
import { AuthenticationService } from '../../../service/auth/authentication.service';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {


  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

 

  dataSource: MatTableDataSource<InventoryTransferRequest>;

 
  constructor(private authService:AuthenticationService,private mService: ManagementService, private _snackBar: MatSnackBar,
    public dialog: MatDialog) {
  

    this.dataSource = new MatTableDataSource(this.allMenuData);
  }

 

  allMenuData: InventoryTransferRequest[] = [];
  updateTyep: boolean = false;


  actions: string[] = ['Update', 'Activate', 'De-Activate', 'Delete'];


  model: InventoryLocations = new InventoryLocations();

  superAdmin:boolean=false;
  superVisor:boolean=false;
  inventoryAdmin:boolean=false;

  ngOnInit() {

    this.superAdmin=false;
    this.superVisor=false;
    this.inventoryAdmin=false;
   
    if(this.authService.currentUserValue.role.role=='ROLE_SUPER_ADMIN')
    {
      
       this.superAdmin=true;
    }
    else if(this.authService.currentUserValue.role.role=='ROLE_SUPERVISOR')
    {
    this.superVisor=true;
    }
    else if(this.authService.currentUserValue.role.role=='ROLE_INVENTORY_ADMIN')
    {
    this.inventoryAdmin=true;
    }



    if(this.inventoryAdmin)
    {
      
    var formData;
    formData={"inventoryCode": this.authService.currentUserValue.role.details};

    console.log(formData);
    this.mService.fetchAllPendingTransferRequestByInventoryCode(formData).
      subscribe(result => {
        var value= result.responseObject as InventoryTransferRequest []; 
        console.log(value);
        this.allMenuData = value;
        this.dataSource = new MatTableDataSource(this.allMenuData);
        console.log(this.allMenuData);
      
      });
    }
    else
    {
      this.mService.fetchAllPendingTransferRequest().
      subscribe(result => {
        var value= result.responseObject as InventoryTransferRequest []; 
        console.log(value);
        this.allMenuData = value;
        this.dataSource = new MatTableDataSource(this.allMenuData);
        console.log(this.allMenuData);
      
      });
    }

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }


  displayedColumns: string[] = ['toInventoryCode', 'itemCode', 'units', 'quantity', 'transferredDate', 'active','transferComments'];
  columnsToDisplay: string[] = this.displayedColumns.slice();
  data: InventoryTransferRequest[] = this.allMenuData;

 


  



  


 






  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }



  openSnackBar(message: string) {
    this._snackBar.openFromComponent(MessageComponent, {
      data: message,
      duration: 5 * 1000
    });
  }


  transferInventory(data: InventoryLocations )
  {
    var localData = new InventoryTransData();
    localData.fromInventoryCode=data.inventoryCode;

    const dialogRef = this.dialog.open(TransferInventory, {
      width: '450px',
      data: localData
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      localData = result;
      this.mService.transferInventory(localData).subscribe(val => {
      this.openSnackBar("Inventory Transfered Successfully ....!!!!");
      this.ngOnInit();
      }); 
    });
    
  }


  acceptRequest(data:InventoryTransferRequest)
{
  var val:any={
    "id": data.id,
    "comments":"value accpeted"
  }
    this.mService.acceptItemRequest(val).subscribe(value=>{

     
      this.openSnackBar("Request Accepted Succesfully .....!!!!");

      this.ngOnInit();
    });
}

  rejectRequest(data: InventoryTransferRequest)
  {

   
    var val: any = {
      "id": data.id,
      "comments": "value Rejected"
    }
    this.mService.rejectItemRequest(val).subscribe(value => {
     
      this.openSnackBar("Request Rejected Successfully .....!!!!");

      this.ngOnInit()
    })
  }

}
