import { BedBillDetails, BedOrderDetails, BedPaymentDetails } from './Bed';
import {DoctorOrder } from './Doctor';
import { Comments } from './Comments';
import { OrgAccountOrder } from './OrgAccountOrder';
import { DirectSaleItems } from './Menu';

export class Company
{
    _id:string;
    companyName:string;
    gstIn:string;
    sgst:number;
    cgst:number;
    address:string;

}



/* export class Menu
{
    _id:string;
    name:string;
    image:string;
    price:number;
    couponAttached:boolean;
}

export class Customer
{
    _id:string;
    name:string;
    mobileNo:number;
    emailId:number;
} */

 export interface CustomerItem
{
    _id:string;
    itemName:string;
    quantity:number;
    price:number;
    singleTotalPrice:number;
    itemSeqNo:number;
    totalPrice: number;
    // constructor(_id: string;itemName: string; price: number)
    // {
    //     this._id=_id;
    //     this.itemName=itemName;
    //     this.price=price;
    //     this.quantity=1;
    //     this.singleTotalPrice=price;
    // }

} 

 export class OrderedItems
{
    _id:string;
    itemList: CustomerItem[];
    totalPrice:number;
    totalItem:number;
    netPrice:number;
    sgst:number;
    cgst:number;
    paymentType:string;
    paidType:string;
    couponApplied:boolean;
    orderDate:Date;
    toBePayedLater:boolean;
    companyDetails:Company;
} 


export class WalkIN {

    subscidised:boolean;
    optype:string;
    wardNumber:String;
    doctorName:string;
    partnerName:string;
    doctorId:string;
    hospitalId:string;
    billType:string;
    bedNumber:string;
    bedTransactionNo:string;
    patientName:string;
    organizationId:string;
	organizationName:string;
    totalAmount: number=0;
    paymentAmount: number=0;
    couponAmount: number=0;
    discountAmount:number=0;
    billdetails: BillDetials=new BillDetials();
    //used only for billing
    bedextrabilldetails: BedBillDetails[]=[];
    paymentdetails: PaymentDetails[];
    bedextrapaymentdetails:BedPaymentDetails[]=[];
    coupondetails: CouponDetails[];
    others:Others;

    branchId:string;
    branchCode:string;
    branchName:string;

    commentsdetails:Comments[];

    transactionNo:string;
    kotPrinted:boolean;
    billPrinted:boolean;
    comments:string;
    createdDate:string;
    updatedDate:string;
    employeeName:string;
    employeeId:string;

    createdUsername:string;
    createdByName:string;

    customerName:string;
    

}

export class Others
{
    //bedItem:Bed;
    bedItem:BedOrderDetails;
    docItem:DoctorOrder;
    //docItem:Doctor;
    patientName:BedOrderDetails;

    orgItem:OrgAccountOrder;
    partnerName:string;

}

export class BillDetials
{
    branchCode:string;
    invoiceNo:string;
    kotNo:string;
    amountPaid:number;
    discountAmount:number;
    totalAmount:number;
    paymentMode:string;
    diffItemsCount:number;
    totalItems:number;
    createdDate:string;
    createdByName:string;
    createdUsername:string;
    billitems:BillItems[];
}


export class BillItems
{
    branchCode:string;
    itemSeqNo:number;
    itemName:string;
    menuItemCode:string;
    quantity:number;
    subscidisedPrice:number;
    price:number;
    totalPrice:number;
    createdByName:string;
    createdUsername:string;
    autoDebitIngredients:boolean;
    directsaleitems:DirectSaleItems[];
    parcel:boolean;

}

export class WalkINData {
    responseCode: boolean;
    message: string;
    responseObject: any[];
}

export class PaymentGroup
{
    exactpayment:boolean;
    couponVisible:boolean;
    amount:number;
    closeOnly:boolean;
    payments:PaymentDetails[];
    coupons:CouponDetails[];
}

export class PaymentDetails
{
    amount: number;
    discount:number;
    paymentMode: string;
    paymentRefId: string;
    branchCode:String;
}
export class CouponDetails{
    branchCode:string;
    type: string;
    name: string;
    price: number;
    couponNumber: string;
}

