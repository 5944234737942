import { Component, OnInit } from '@angular/core';
import { ViewChild, Input } from '@angular/core';
import { ManagementService } from '../../../service/inventory/inventory.service';
import { InventoryBalanceDetails, InventoryData, SubInventorySBalanceDetails, InventoryTransfer, InventoryItem} from '../../bean/Inventory';
import { MatRadioButton } from '@angular/material';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MessageComponent } from "../../Generic/message/message.component";
import { Router } from '@angular/router';
import { GlobalVariables } from '../../../service/GloabalVariables.service';
@Component({
  selector: 'app-balance-details',
  templateUrl: './balance-details.component.html',
  styleUrls: ['./balance-details.component.css']
})
export class BalanceDetailsComponent implements OnInit {
  
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  dataSource: MatTableDataSource<SubInventorySBalanceDetails>;
  invData: InventoryItem=new InventoryItem();
  loadingSpinner:boolean=false;

  constructor(private router: Router,private mService: ManagementService, private _snackBar: MatSnackBar) {

    this.invData = this.mService.getInventoryItem();
  
  }

  addItems: boolean = false;

  allMenuData: SubInventorySBalanceDetails[] = [];
  updateTyep: boolean = false;


  actions: string[] = ['Update', 'Activate', 'De-Activate', 'Delete'];


  model: InventoryBalanceDetails = new InventoryBalanceDetails();

  ngOnInit() {

    this.findBalance(this.invData).subscribe(data => {
      this.model = data.responseObject as unknown as InventoryBalanceDetails ;
      this.allMenuData = this.model.subInventoryBalances
      console.log(this.allMenuData);
      this.dataSource = new MatTableDataSource(this.allMenuData);
      this.loadingSpinner = false;
    });
    this.addItems = false;
    this.updateTyep = false;
    this.model = new InventoryBalanceDetails();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }


  
  data: SubInventorySBalanceDetails[] = this.allMenuData;

  addItem() {
    this.addItems = !this.addItems;
  }

  updateItem(data: InventoryBalanceDetails) {
    this.addItems = !this.addItems;
    this.updateTyep = true;
    console.log(data);
    /* this.mService.getMenuData(data).subscribe(data => {
      this.model = data;
    }); */
    this.model = data;
  }


  findBalance(data:InventoryItem)
  {
    var formData: any = 
    {
      "itemCode": data.itemCode
    };
    console.log(formData)
    return this.mService.fetchAllItemBalanceDetailsByItemCode(formData);
  }




  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }



  openSnackBar(message: string) {
    this._snackBar.openFromComponent(MessageComponent, {
      data: message,
      duration: 5 * 1000
    });
  }


  fromDate:string;
  toDate:string;

  seachByAny()
  {
    this.loadingSpinner=true;
    var data: any = {
      "itemCode": this.invData.itemCode,
    }
    this.mService.searchBalanceDetailsByDate(data).subscribe(data => {

      var model = data.responseObject as unknown as InventoryBalanceDetails;
      this.allMenuData = model.subInventoryBalances
    
    });
  
  }

  seachByDate()
  {
    this.loadingSpinner=true;
    var data: any = {
      "itemCode": this.invData.itemCode,
      "startDate": this.fromDate,
      "endDate": this.toDate
    }
    this.mService.searchBalanceDetailsByDate(data).subscribe(data => {

      var model = data.responseObject as unknown as InventoryBalanceDetails;
      this.allMenuData = model.subInventoryBalances
  
 
    });
  
  }

}



