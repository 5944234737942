import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-fixedassetcomments',
  templateUrl: './fixedassetcommentpopup.component.html',
})
export class FixedAssetCommentPopup implements OnInit {

  comments:string;
  message:string
 

  constructor(public dialog: MatDialog, public dialogRef: MatDialogRef<FixedAssetCommentPopup>,
    @Inject(MAT_DIALOG_DATA) public data:string){ 
      if(data!=null&&data!=undefined)
      {
      this.message=data;
      }
    }

 
  

  ngOnInit() {

  }


onSaveClicked(comments:string)
{
  this.dialogRef.close(comments);
}


onCloseClick()
{
  this.dialogRef.close(null);

}
}
