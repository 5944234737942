import {Component } from '@angular/core';
import { billingNavITems } from '../../billingnav';
import { GlobalVariables } from '../../service/GloabalVariables.service';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../service/auth/authentication.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent {

  multipleRoles:boolean;

  constructor(private _route: Router,private authService:AuthenticationService)
  {
    if(this.authService.currentUserValue==null)
    {
      this._route.navigate(['login']);
    }

    if(this.authService.currentUserValue.roles.length>1||this.authService.currentUserValue.branches.length>1)
    {
     
      this.multipleRoles=true;
    
    }

  }

  public sidebarMinimized = false;
  public navItems = billingNavITems;

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }
  logOut()
  {
    console.log("logging Out");
    this._route.navigate(['/logout']);
  }


  roleBranchChange()
  {
    this._route.navigate(['/afterlogin']);
  }


}
