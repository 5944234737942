import { INavData } from '@coreui/angular';

export const kitchenNavItems: INavData[] = [
  {
    name: 'Reciepe Information',
    url: 'kitchenadmin',
    children: [
      {
        name: 'Bulk Request Reciepe Ingredients',
        url: 'bulkreceipedebits'
      },
      {
        name: 'Request Reciepe Ingredients',
        url: 'debitreceipe',
      },
      {
        name: 'View Submitted Requests Requests',
        url: 'receipedebits'
      }
      ,
      {
        name: 'View Reciepe Preperation Details',
        url: 'prepstepdetails'
      }
    ]
  }
];
