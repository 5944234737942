import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../service/auth/authentication.service';
import { GlobalVariables } from '../../service/GloabalVariables.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  constructor(private route: Router,private authenticationService: AuthenticationService,private globalVariable:GlobalVariables) { }

  ngOnInit() {
    this.authenticationService.logout();

    this.globalVariable.allDoctorList=null;
    this.globalVariable.allBedList=null;
    this.globalVariable.allHospitalList=null;
    this.globalVariable.allOrgAccountList=null;
    this.globalVariable.allBranchAccountList=null;
  }


  navigateToLogin()
  {
    this.route.navigate(['/login']);
  }

}
