import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { InventoryItem, InventoryItemTransactions, InventoryTransData, TransType, InventoryTransfer, InventoryDebitData, InventoryCreditData} from "../../bean/Inventory";
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MessageComponent } from "../../Generic/message/message.component";
import { MatDialog } from '@angular/material/dialog';
import { PopUpDebit} from "../popups/PopUpDebit";
import { PopUpTrans } from "../popups/PopUpTrans";
import { PopUpCredit } from "../popups/popUpCredit";
import { ManagementService } from "../../../service/inventory/inventory.service";
import { DatePipe } from '@angular/common';
import { AuthenticationService } from '../../../service/auth/authentication.service';


@Component({
  selector: 'app-transaction',
  templateUrl: './transaction.component.html',
  styleUrls: ['./transaction.component.css'],
  providers: [DatePipe]
})
export class TransactionComponent implements OnInit {

  
  transactionList:InventoryItemTransactions[];
  invData: InventoryItem=new InventoryItem();
  displayedColumns: string[] = ['inventoryCode', 'foodItemCode', 'units',"unitQuantity", 'totalQuantity','balance', 'price', 'type','expiryDate','alertBeforeDays', 'createdDate', 'createdUser', ];
  columnsToDisplay: string[] = this.displayedColumns.slice();
  transData:InventoryTransData;
  debitData:InventoryDebitData;
  creditData:InventoryCreditData;

  fromDate:string;
  toDate:string;

  itemCode:string;

  todaysDate = new Date();
  sTodaysDate:string;

  superAdmin:boolean=false;
  superVisor:boolean=false;
  inventoryAdmin:boolean=false;


  constructor(private router: Router, public dialog: MatDialog, 
    private _snackBar: MatSnackBar,private authService:AuthenticationService,
    private mService:ManagementService,private datePipe: DatePipe) { 
      

     
  
   
  }

  ngOnInit() {

    this.superAdmin=false;
    this.superVisor=false;
    this.inventoryAdmin=false;

    this.transactionList=[];
   
    if(this.authService.currentUserValue.role.role=='ROLE_SUPER_ADMIN')
    {
      
       this.superAdmin=true;
    }
    else if(this.authService.currentUserValue.role.role=='ROLE_SUPERVISOR')
    {
    this.superVisor=true;
    }
    else if(this.authService.currentUserValue.role.role=='ROLE_INVENTORY_ADMIN')
    {
    this.inventoryAdmin=true;
    }

  this.invData = this.mService.getInventoryItem();
  this.sTodaysDate = this.datePipe.transform(this.todaysDate, 'yyyy-MM-dd');
  this.fromDate=this.sTodaysDate;
  this.toDate=this.sTodaysDate;

  this.itemCode=this.invData.itemCode;

  if(this.invData == undefined)
  {
    this.router.navigate(['inventory/mangement']);
    return;
  }
  
  if(this.superAdmin||this.superVisor)
  {
  this.fetchByItemCode();
  }
  else  if(this.inventoryAdmin)
  {
    this.fetchByInventoryItemCode();
  }
  }
  
  openCreditDialog()
  {

    this.creditData=new InventoryCreditData();
    this.creditData.itemCode = this.itemCode;
    this.creditData.inventoryCode=this.authService.currentUserValue.role.details;
    this.creditData.type = TransType.CREDIT.toString();
    this.creditData.totalQuantity = 0;
    const dialogRef = this.dialog.open(PopUpCredit, {
      maxWidth: '600px', maxHeight:'600px',
      data: this.creditData
    });

    dialogRef.afterClosed().subscribe(result => {

      console.log('The dialog was closed');
      this.creditData = result;
      this.creditData.itemCode = this.itemCode;
    this.creditData.inventoryCode=this.authService.currentUserValue.role.details;
    this.creditData.createdUser=this.authService.currentUserValue.firstName+" "+this.authService.currentUserValue.lastName;
      console.log( this.creditData);
      if (result == undefined || result.units == undefined || result.units == null ) return;

      this.creditData.totalQuantity = (this.creditData.units * this.creditData.unitQuantity);

      this.mService.addtransaction(this.creditData).subscribe(value => {
        this.openSnackBar(value.message+"....!!!!");
        this.ngOnInit();
        error=>{
          this.openSnackBar(value.message + "....!!!!");
        }
      });
    });

   
  }

  openDebitDialog()
  {
    this.debitData = new InventoryDebitData();
    this.debitData.inventoryCode=this.authService.currentUserValue.role.details;
    this.debitData.itemCode = this.itemCode;
    this.debitData.type = TransType.DEBIT.toString();
    console.log("Balance="+this.invData.balance);
    this.debitData.totalQuantity =this.invData.totalQuantity;
    this.debitData.units=this.invData.units;
   // console.log("....credit");
    const dialogRef = this.dialog.open(PopUpDebit, {
      maxWidth: '600px', maxHeight:'600px',
      data: this.debitData
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');

      if (result == undefined ||result.units == undefined || result.units == null) return;


      this.debitData = result;
      this.debitData.inventoryCode=this.authService.currentUserValue.role.details;
      this.debitData.createdUser=this.authService.currentUserValue.firstName+" "+this.authService.currentUserValue.lastName;
      this.debitData.itemCode = this.itemCode;
      this.debitData.totalQuantity = this.debitData.totalQuantity;
      console.log(this.debitData);

      this.mService.addtransaction(this.debitData).subscribe(value => {
      

       
        this.openSnackBar(value.message+"...!!!");
        this.ngOnInit();
      
      }
      
      );
    });
  }

  openTransferDialog()
  {

    this.transData = new InventoryTransData();
    const dialogRef = this.dialog.open(PopUpTrans, {
      width: '600px',
      data: this.transData
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');

      this.transData = result;
     // var tranfer: InventoryTransfer=new InventoryTransfer();

     if(this.transData!=null)
     {
      
      this.transData.fromInventoryCode=this.authService.currentUserValue.role.details;
      this.transData.itemCode = this.invData.itemCode;
      this.transData.transferedUser=this.authService.currentUserValue.firstName+" "+this.authService.currentUserValue.lastName;
      this.transData.units=(this.invData.units*this.transData.quantity)/this.invData.totalQuantity;
    
      this.mService.transferInventory(this.transData).subscribe(val => {
        this.openSnackBar("Inventory Item Transfered Successfully ....!!!!");
        this.ngOnInit();
        
      });
    } 
    });
  }


  deleteTransaction(id:String)
  {
    var param={"id":id};
    this.mService.deleteTransaction(param).subscribe(val => {
      console.log(val);
      this.openSnackBar("Transaction Deleted Successfully ....!!!!");
      this.ngOnInit();
      
    });
  }

  applyFilter(event: Event) {
   
  }

  openSnackBar(message: string) {
    this._snackBar.openFromComponent(MessageComponent, {
      data: message,
      duration: 5 * 1000
    });
  }


  saveTransactions(data:InventoryTransData)
  {
    this.mService.addTrasactionItem(data).subscribe(value=>{

    });
  }


  fetchByInventoryItemCode()
  {
    var data:any={
      "inventoryCode":this.authService.currentUserValue.role.details,
      "itemCode": this.invData.itemCode
    }
    this.mService.findByInventoryItemCode(data).subscribe(data=>{
      console.log(data);
      this.invData= data.responseObject as unknown as InventoryItem;
      if(this.invData.totalQuantity<1)
      {
       
      if(this.invData.measurementUnit.toLowerCase()=='kg')
      {
        this.invData.totalQuantity=this.invData.totalQuantity*1000;
        this.invData.measurementUnit='GRAMS';
      }
      if(this.invData.measurementUnit.toLowerCase()=='litres')
      {
        this.invData.totalQuantity=this.invData.totalQuantity*1000;
        this.invData.measurementUnit='LITRES';
      }
      }
      this.transactionList =  this.invData.inventoryItemTransactions;
      this.updateWeightDetails();
    });
  }

  fetchByItemCode()
  {
    var data:any={
      "itemCode": this.invData.itemCode
    }
    this.mService.findByItemCode(data).subscribe(data=>{
      this.invData= data.responseObject as unknown as InventoryItem;
      if(this.invData.totalQuantity<1)
      {
     
      if(this.invData.measurementUnit.toLowerCase()=='kg')
      {
        this.invData.totalQuantity=this.invData.totalQuantity*1000;
        this.invData.measurementUnit='GRAMS';
      }
      if(this.invData.measurementUnit.toLowerCase()=='litres')
      {
        this.invData.totalQuantity=this.invData.totalQuantity*1000;
        this.invData.measurementUnit='LITRES';
      }
      }
      this.transactionList =  this.invData.inventoryItemTransactions;
      this.updateWeightDetails();
    });
  }


  findByInventoryItemCodeDateRange()
  {
    var data:any={
      "inventoryCode":this.authService.currentUserValue.role.details,
      "itemCode": this.invData.itemCode,
      "startDate":this.fromDate,
      "endDate":this.toDate
    }
    this.mService.findByInventoryItemCodeByDate(data).subscribe(data=>{
      this.invData= data.responseObject as unknown as InventoryItem;
      if(this.invData.totalQuantity<1)
      {
      
      if(this.invData.measurementUnit.toLowerCase()=='kg')
      {
        this.invData.measurementUnit='GRAMS';
        this.invData.totalQuantity=this.invData.totalQuantity*1000;
      }
      if(this.invData.measurementUnit.toLowerCase()=='litres')
      {
        this.invData.measurementUnit='LITRES';
        this.invData.totalQuantity=this.invData.totalQuantity*1000;
      }
      }
      this.transactionList =  this.invData.inventoryItemTransactions;
      this.updateWeightDetails();
    });
  }

  findByItemCodeDateRange()
  {
    var data:any={
      "itemCode": this.invData.itemCode,
      "startDate":this.fromDate,
      "endDate":this.toDate
    }
    this.mService.findByItemCodeByDate(data).subscribe(data=>{
      this.invData= data.responseObject as unknown as InventoryItem;
      if(this.invData.totalQuantity<1)
      {
      if(this.invData.measurementUnit.toLowerCase()=='kg')
      {
        this.invData.measurementUnit='GRAMS';
        this.invData.totalQuantity=this.invData.totalQuantity*1000;
      }
      if(this.invData.measurementUnit.toLowerCase()=='litres')
      {
        this.invData.measurementUnit='LITRES';
        this.invData.totalQuantity=this.invData.totalQuantity*1000;
      }
      }
      this.transactionList =  this.invData.inventoryItemTransactions;

      this.updateWeightDetails();
    
    });
  }

  updateWeightDetails()
  {
    for(let i=0;i<this.transactionList.length;i++)
    {
      if(this.transactionList[i].totalQuantity<1)
      {
    if(this.invData.measurementUnit.toLowerCase()=='kg')
      {
        this.transactionList[i].totalQuantityMeasurementUnit='GRAMS';
      
          this.transactionList[i].totalQuantity=this.transactionList[i].totalQuantity*1000;
          
        
      }
      else if(this.invData.measurementUnit.toLowerCase()=='litres')
      {
        this.transactionList[i].totalQuantityMeasurementUnit='ML';
        if(this.transactionList[i].totalQuantity<1)
        {
          this.transactionList[i].totalQuantity=this.transactionList[i].totalQuantity*1000;
          
        }
      }
    }
    if(this.transactionList[i].unitQuatity<1)
    {
      if(this.invData.measurementUnit.toLowerCase()=='kg')
      {
      this.transactionList[i].unitQuatity=this.transactionList[i].unitQuatity*1000;
      this.transactionList[i].unitQuantityMeasurementUnit='GRAMS';
      }
      else if(this.invData.measurementUnit.toLowerCase()=='litres')
      {
        this.transactionList[i].unitQuatity=this.transactionList[i].unitQuatity*1000;
        this.transactionList[i].unitQuantityMeasurementUnit='ML';
      }
      
    }

   

    console.log("Balance=",this.transactionList[i].balance);
   
    if(this.transactionList[i].balance<1)
    { 
    if(this.invData.measurementUnit.toLowerCase()=='kg')
      {
        this.transactionList[i].balanceMeasurementUnit='GRAMS';
       
          this.transactionList[i].balance=this.transactionList[i].balance*1000;
      }
      else if(this.invData.measurementUnit.toLowerCase()=='litres')
      {
        this.transactionList[i].balanceMeasurementUnit='ML';
      
          this.transactionList[i].balance=this.transactionList[i].balance*1000;
      }
    }

    }
    
  }

}


