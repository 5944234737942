import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AuthenticationService } from '../../../service/auth/authentication.service';
import { UserAccountService } from '../../../service/useraccount/useraccount.service';
import { ChangePassword } from '../../bean/ChangePassword';

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.css']
})
export class ChangepasswordComponent implements OnInit {

  model:ChangePassword;

  changePasswordForm: FormGroup;
  submitted = false;

  constructor(private userService:UserAccountService,private authService:AuthenticationService,private formBuilder: FormBuilder,public dialogRef: MatDialogRef<ChangepasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ChangePassword) {
   
   }

  ngOnInit() {
    this.model=new ChangePassword();

    this.submitted=false;

    this.changePasswordForm = this.formBuilder.group({
      oldPassword: ['', Validators.required],
      newPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required],
    });

  }

  onSubmit()
  {
    this.submitted=true;

    if(this.model.newPassword!=this.changePasswordForm.value.confirmPassword)
    {
      return;
    }

    if(this.model.oldPassword!=this.authService.currentUserValue.password)
    {
      return;
    }

    if (this.changePasswordForm.invalid) {
    
      return;
    }


    this.model.username=this.authService.currentUserValue.username;
    console.log(this.model.username);
   

    this.userService.changePassword(this.model).subscribe(data => {

      if(data.responseCode)
      {
        this.dialogRef.close(this.model);
      }
    

    });
  

   
  }

  onCloseClick(): void {
    this.dialogRef.close(null);
  }

}
