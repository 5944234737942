import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';
import { Menu} from "../../bean/Menu";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { MessageComponent } from '../../Generic/message/message.component';
import { ManagementService } from '../../../service/inventory/inventory.service';

@Component({
    selector: 'PopupUploadInventoryItemFile',
    templateUrl: 'uploadinventoryitemfile.html'

})
export class PopupUploadInventoryItemFile {

    fileToUpload: File = null;

    
   
    submitted:boolean=false;
    inventoryItemFileUploadForm: FormGroup;


    constructor(public dialogRef: MatDialogRef<PopupUploadInventoryItemFile>,
         @Inject(MAT_DIALOG_DATA) public data: Menu,private _snackBar: MatSnackBar,private formBuilder: FormBuilder,
        private inventoryService: ManagementService)
    {

    }

    handleFileInput(files: FileList) {
        this.fileToUpload = files.item(0);
    }

  
    onSubmit()
    {
        this.submitted=true;
        if(this.inventoryItemFileUploadForm.invalid&&this.submitted)
        return
        else
        this.inventoryService.uploadInventoryItemFile(this.fileToUpload).subscribe(data => {
            if(data.responseCode===true)
            {
                this.openSnackBar(data.message);
                this.dialogRef.close();
            }
            else{
                this.openSnackBar('Error Uploading Excel..');
            }

            this.ngOnInit();
        })
    }

    ngOnInit() {

        this.submitted=false;
        this.inventoryItemFileUploadForm = this.formBuilder.group({
         
          file: ['', Validators.required],
    
      });
    
      this.inventoryItemFileUploadForm.reset();
    }

    openSnackBar(message: string) {
        this._snackBar.openFromComponent(MessageComponent, {
          data: message,
          duration: 5 * 1000
        });
      }

   

  

  
    
    onCloseClick(): void {
        this.dialogRef.close();
    }


}