import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { AuthenticationService } from '../../../service/auth/authentication.service';
import { BranchserviceService } from '../../../service/branch/branchservice.service';
import { ExpenseserviceService } from '../../../service/expense/expenseservice.service';
import { BranchAccountOrder } from '../../bean/Branches';
import { CashExpense } from '../../bean/CashExpense';
import { PittyCash, PittyCashData, PittyCashVO } from '../../bean/PittyCash';
import { MessageComponent } from '../../Generic/message/message.component';

@Component({
  selector: 'app-pittycash',
  templateUrl: './pittycash.component.html',
  styleUrls: ['./pittycash.component.css']
})
export class PittycashComponent implements OnInit {

  expenseForm: FormGroup;
  submitted = false;
  amountSubmitted = false;

  addItems: boolean = false;
  addCreditItems:boolean=false;

  allTransactionData: PittyCash[] = [];
  tempAllTransactionData: PittyCash[] = [];

  model: PittyCash= new PittyCash();


  todaysDate = new Date();
  fromDate:string;
  toDate:string;

  expensesList: string[] = ["Biscuit", "Bread","Cleaning Materials","Coffee/Tea Powder","Dairy","Fees","Fixed Assets",
  "Fruits","Gas","Groceries","Juice","Maintenance - Repairs","Other Overheads",
  "Packing Material","Pest Service","Petrol / Auto","Eggs",
  "Rent","Salary","Salary Advance","Stationary","Uniform",
  "Utility","Vegetables","Water"];

  dairySubType:string[] = ["Milk","Curd","Paneer","Cheese"];

  feesType:string[] = ["GST","Tax","Renewal"];

  fixedAssetsType:string[] = ["Utencils","Equipments"];

  jucieType:string[] = ["Tropicana","Pepsi"];

  vegetableType:string[] = ["Fresh Veg","Coconut","Potato","Onions","Greens"];

  
  packingMaterialType:string[] = ["Plastic","Bio Degradable"];

  rentType:string[] = ["Canteen","Staff Room"];
  
  utilityType:string[] = ["Canteen","Staff Room"];


  subExpenseList:string[];
  subTypeEnabled:boolean=false;

  searchForm:FormGroup;
  pittyCashCreditForm:FormGroup;

  formSubmitted:boolean;

  subExpenseTypeSelected:boolean;

  pittyCashVO:PittyCashVO;

  creditAmount:number;

  creditComments:string;

 username:string;
 name:string;

 superAdmin:boolean;

 selectedBranchIdIndex:number;

  selectedBranchCode:string;

  selectedBranchName:string;

  branchSelected:boolean;

  branchList:BranchAccountOrder[]=[];

  constructor(private authService:AuthenticationService,private branchService: BranchserviceService,private formBuilder: FormBuilder,private mService: ExpenseserviceService, private _snackBar: MatSnackBar,private datePipe: DatePipe) {

    this.todaysDate=new Date();
    this.fromDate = this.datePipe.transform(this.todaysDate, 'yyyy-MM-dd');
    this.toDate=this.fromDate = this.datePipe.transform(this.todaysDate, 'yyyy-MM-dd');

    this.username=this.authService.currentUserValue.username;
    this.name=this.authService.currentUserValue.firstName+" "+this.authService.currentUserValue.lastName;

    if(this.authService.currentUserValue.role.role!=null&&this.authService.currentUserValue.role.role=="ROLE_SUPER_ADMIN")
    {
    this.superAdmin=true;
    }

   }

   onExpenseTypeSelected(event)
   {
     this.model.expenseType=event.value;

     this.subExpenseList=[];
     
     this.subTypeEnabled=true;

     this.model.subExpenseType==null;

     if(this.model.expenseType=="Dairy")
     {
       
       this.subExpenseList=this.dairySubType;
     }
     else  if(this.model.expenseType=="Fees")
     {
       this.subExpenseList=this.feesType;
     }
     else  if(this.model.expenseType=="Fixed Assets")
     {
       this.subExpenseList=this.fixedAssetsType;
     }
     else  if(this.model.expenseType=="Juice")
     {
       this.subExpenseList=this.jucieType;
     }
     else  if(this.model.expenseType=="Vegetables")
     {
       this.subExpenseList=this.vegetableType;
     }

     else  if(this.model.expenseType=="Packing Material")
     {
       this.subExpenseList=this.packingMaterialType;
     }
     else  if(this.model.expenseType=="Rent")
     {
       this.subExpenseList=this.rentType;
     }
     else  if(this.model.expenseType=="Utility")
     {
       this.subExpenseList=this.utilityType;
     }
     else
     {
      this.subTypeEnabled=false;
       this.model.subExpenseType="-----";
     }
     
   }

  ngOnInit() {

    this.addItems=false;
    this.addCreditItems=false;

    this.submitted=false;
    this.amountSubmitted=false;
    this.subExpenseTypeSelected=false;

    this.model = new PittyCash();

    this.searchForm = this.formBuilder.group({
      fromDate: ['', Validators.required],
      toDate: ['', Validators.required],
  });


    this.expenseForm = this.formBuilder.group({
      expenseType: ['', Validators.required],
      amount: ['', Validators.required],
      comments: ['', [Validators.required]]
  });

  this.pittyCashCreditForm = this.formBuilder.group({
    creditAmount: ['', Validators.required],
    creditComments: ['', Validators.required]
});


this.selectedBranchIdIndex=-1;
    this.fetchAllBranchList();
  //this.fetchPittyCash();

  this.allTransactionData = [];
  this.tempAllTransactionData= [];
  
  }


  updatedSelectedBranch()
  {
    var selectedBranchCode=this.authService.currentUserValue.branch.branchCode;
    for(let i=0;i<this.branchList.length;i++)
    {
      if(selectedBranchCode==this.branchList[i].branchCode)
      {
        this.selectedBranchIdIndex=i+1;
        this.selectedBranchCode=selectedBranchCode;
        this.selectedBranchName=this.branchList[i].branchName
        this.branchSelected=true;
        break;
      }
    }

   // this.fetchLinkedOrganizationAccountByBranchCode();
   this.search();

  }

  fetchAllBranchList()
  {
    this.branchService.fetchAllActiveBranchAccountOrders().subscribe(response => {

      if(response.responseCode==true)
      {
       
        this.branchList=response.responseObject;
        this.updatedSelectedBranch();
      
      }
     
      else
      {
        this.openSnackBar('Error Fetching Report')
      }
    
    });
  }

  onBranchSelected()
  {
   
    this.branchSelected=false;
    if(this.selectedBranchIdIndex!=-1)
    {
      if(this.selectedBranchIdIndex>0)
      {
        this.selectedBranchCode=this.branchList[this.selectedBranchIdIndex-1].branchCode;
        this.selectedBranchName=this.branchList[this.selectedBranchIdIndex-1].branchName;
      }
    this.branchSelected=true;
    }
   
  }

  search()
  {
    if(this.selectedBranchIdIndex==-1)
    {
      this.openSnackBar('Select Branch');
      return;
    }
    if(this.selectedBranchIdIndex==0)
    {
      console.log('Search All Reports');
      this.fetchPittyCash();
    }
    else
    {
     console.log('Search Branch Reports='+this.selectedBranchCode);
    this.fetchPittyCashTransactionsByBranchCodeDateRange();
    }
  }





  onSubExpenseTypeSelected(event)
  {
    this.model.subExpenseType=event.value;
    this.subExpenseTypeSelected=true;

  }
  

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    var filterResult: PittyCash[] = this. tempAllTransactionData.filter(tran => 
      tran.expenseType.toLowerCase().startsWith(filterValue) ||tran.subExpenseType.toLowerCase().startsWith(filterValue)
      ||tran.comments.toLowerCase().startsWith(filterValue)
      ||tran.type.toLowerCase().startsWith(filterValue));
  
      if(filterValue=='')
      {
        this. allTransactionData=this.tempAllTransactionData;
      }
      else
      {
      if(filterResult.length>0)
      {
  this. allTransactionData=filterResult;
      }
      else
      {
        this. allTransactionData=this. tempAllTransactionData;
      }
    }
  }


  fetchPittyCashTransactionsByBranchCodeDateRange()
  {
    var jsonData={"startDate":this.fromDate,"endDate":this.toDate,"branchCode":this.selectedBranchCode};
  this.mService.fetchPittyCashDetailsByBranchCodeandDate(jsonData).subscribe(data => {
    console.log(data);
   var recievedData = data.responseObject as PittyCashVO;

   this.pittyCashVO=recievedData;

   this.allTransactionData = recievedData.transanctions;
   this.tempAllTransactionData=this.allTransactionData;

  
  });
  }


  fetchPittyCash()
  {
    var jsonData={"startDate":this.fromDate,"endDate":this.toDate};
  this.mService.fetchPittyCashDetailsByDate(jsonData).subscribe(data => {
    console.log(data);
   var recievedData = data.responseObject as PittyCashVO;

   this.pittyCashVO=recievedData;

   this.allTransactionData = recievedData.transanctions;
   this.tempAllTransactionData=this.allTransactionData;

  
  });
  }



  addItem() {
    this.addItems = !this.addItems;
    this.addCreditItems=false;
  }

  addCashItem()
  {
    this.addCreditItems=!this.addCreditItems;
    this.addItems=false;
  }


  openSnackBar(message: string) {
    this._snackBar.openFromComponent(MessageComponent, {
      data: message,
      duration: 5 * 1000
    });
  }

  seachByDate()
  {

    this.formSubmitted=true;

   
    if(this.searchForm.invalid)
    {
      return;
    }
   
   
    
    //this.fetchPittyCash();
    this.fetchPittyCashTransactionsByBranchCodeDateRange();
  
  }

  onSubmit() {
    this.submitted = true;

    if(this.model.subExpenseType==null)
    {
    this.subExpenseTypeSelected=false;
    return;
    }

    // stop the process here if form is invalid
    if (this.expenseForm.invalid) {
        return;
    }

    this.model.branchCode=this.authService.currentUserValue.branch.branchCode;
    this.model.createdUsername=this.username;
    this.model.name=this.name;
    this.model.type="DEBIT";
    this.mService.savePittyCashData(this.model).subscribe(data => {
      if(data.responseCode)
      {
      this.openSnackBar(data.message);
     this.ngOnInit();
      }
      else{
        alert(data.message);
      }
    }
    );
  
}

onAddCreditSubmit() {
  this.amountSubmitted = true;



  // stop the process here if form is invalid
  if (this.pittyCashCreditForm.invalid) {
      return;
  }

  var pittyCash=new PittyCash();
  pittyCash.amount=this.creditAmount;
  pittyCash.expenseType="-----";
  pittyCash.subExpenseType="-----";
  pittyCash.type="CREDIT";
  pittyCash.comments=this.creditComments;
  pittyCash.createdUsername=this.username;
  pittyCash.name=this.name;
  
  pittyCash.branchCode=this.authService.currentUserValue.branch.branchCode;

  this.mService.savePittyCashData(pittyCash).subscribe(data => {
    if(data.responseCode)
    {
    this.openSnackBar(data.message);
   this.ngOnInit();
    }
    else{
      alert(data.message);
    }
  }
  );

}

}
