import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { Console } from 'console';
import { AuthenticationService } from '../../service/auth/authentication.service';
import { Branch } from '../bean/Branches';
import { ApplicationRoles, LinkedBranches } from '../bean/UserAccount';
import { MessageComponent } from '../Generic/message/message.component';

@Component({
  selector: 'app-afterloginselection',
  templateUrl: './afterloginselection.component.html',
  styleUrls: ['./afterloginselection.component.css']
})
export class AfterloginselectionComponent implements OnInit {


  branchesArray:LinkedBranches[];
  rolesArray:ApplicationRoles[];

  afterLoginForm: FormGroup;

  submitted:boolean;

  multipleBranches:boolean;
  multipleRoles:boolean;

  constructor(private formBuilder: FormBuilder,private authenticationService: AuthenticationService,private route: Router,
    private snackBar: MatSnackBar) {
   
   }

  ngOnInit() {

    this.submitted=false;

    this.afterLoginForm = this.formBuilder.group({
      branch: ['', Validators.required],
      role: ['', Validators.required],
      
  });

  if (this.authenticationService.currentUserValue!=null&&this.authenticationService.currentUserValue.username!=null&&this.authenticationService.currentUserValue.username!=undefined) 
  { 
 
    if(this.authenticationService.currentUserValue.roles.length>1)
    {
     
      this.multipleRoles=true;
    
    }
    else
    {
      this.authenticationService.currentUserValue.role=this.authenticationService.currentUserValue.roles[0];
      this.authenticationService.loggedIn(this.authenticationService.currentUserValue);
      this.afterLoginForm.get('role').setValue(this.authenticationService.currentUserValue.roles[0].role);
    }
    if(this.authenticationService.currentUserValue.branches.length>1)
    {
     
      this.multipleBranches=true;
     
    }
    else
    {
      this.authenticationService.currentUserValue.branch=this.authenticationService.currentUserValue.branches[0];
      this.authenticationService.loggedIn(this.authenticationService.currentUserValue);
      this.afterLoginForm.get('branch').setValue(this.authenticationService.currentUserValue.branches[0].branchId);
    }

    if(this.authenticationService.currentUserValue.roles.length<=1
      &&
      this.authenticationService.currentUserValue.branches.length<=1)
    {
      this.authenticationService.currentUserValue.role=this.authenticationService.currentUserValue.roles[0];
      this.authenticationService.currentUserValue.branch=this.authenticationService.currentUserValue.branches[0];

      this.authenticationService.loggedIn(this.authenticationService.currentUserValue);
      this.verifyRoleAndRoute(this.authenticationService.currentUserValue.role);

    }
   
    else
    {
    this.branchesArray=this.authenticationService.currentUserValue.branches;
    this.rolesArray=this.authenticationService.currentUserValue.roles;
    }
  }



  }

  openSnackBar(message: string) {
    this.snackBar.openFromComponent(MessageComponent
      , {
      data: message,
      duration: 5 * 1000
    });
  }

  onSubmit()
  {
    this.submitted=true;
    if(this.afterLoginForm.invalid)
    {
      this.openSnackBar("Select Branch and Roles to Continue");
      return;
    }
    var branchId=this.afterLoginForm.get('branch').value;
   

    this.branchesArray.forEach(element => {
      
      if(element.branchId==branchId)
      {
        console.log('b='+branchId);
        this.authenticationService.currentUserValue.branch=element;
      }
      
    });
    var role=this.afterLoginForm.get('role').value;

  

    this.rolesArray.forEach(element => {
      
      if(element.role==role)
      {
        console.log('R='+role);
        this.authenticationService.currentUserValue.role=element;
      }
      
    });

    this.authenticationService.loggedIn(this.authenticationService.currentUserValue);

   
    this.verifyRoleAndRoute(this.authenticationService.currentUserValue.role);
    
  }


  verifyRoleAndRoute(role:ApplicationRoles) 
  {
    console.log('R='+role.role);
    if(role.role=="ROLE_CASHIER")
   {
     this.route.navigate(['/billing']);
   }
   else if(role.role=="ROLE_SUPERVISOR")
   {
     this.route.navigate(['/supervisor']);
   }
   else if(role.role=="ROLE_SUPER_ADMIN")
   {
     this.route.navigate(['/superadmin']);
   }
   else if(role.role=="ROLE_INVENTORY_ADMIN")
   {
     this.route.navigate(['/iadmin']);
   }
   else if(role.role=="ROLE_HOSPITAL_ADMIN")
   {
     this.route.navigate(['/hadmin']);
   }
   else if(role.role=="ROLE_KITCHEN_ADMIN")
   {
     this.route.navigate(['/kitchenadmin']);
   }
 }

 logout()
 {
   console.log("logging Out");
   this.route.navigate(['/logout']);
   
 }

}
