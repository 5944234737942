import { INavData } from '@coreui/angular';

export const billingNavITems: INavData[] = [
  
  {
    
    name: 'Billing Management',
    url: 'billing',
    icon: 'icon-star',
    children: [
     /*  {
        name: 'Counter Sales',
         url: 'walkIn/selectedMenu/Counter Sale',
        icon: 'icon-layers'
      }, */
      
     /*  {
        name: 'Bed Orders',
        url: 'walkIn/selectedMenu/Bed Order',
        icon: 'icon-cursor'
      },
      {
        name: 'Doctors Orders',
        url: 'walkIn/selectedMenu/Doctor Order',
        icon: 'icon-bell'
      }, */
      {
        name: 'Counter Sales',
        url: 'newwalkIn',
        icon: 'icon-cursor'
      },
      {
        name: 'Bed Info',
        url: 'beddetails',
        icon: 'icon-cursor'
      },
      {
        name: 'Doctor Info',
        url: 'report',
        icon: 'icon-pencil'
      },
      {
        name: 'In Complete Pre Kot Orders',
        url: 'prekotorders',
        icon: 'icon-pencil'
      },
      {
        name: 'Bulk Upload Bed Orders',
        url: 'addbulkbedorder',
        icon: 'icon-pencil'
      },
     
       {
        name: 'Add Food Wastage',
        url: 'addfoodwastage',
        icon: 'icon-bell'
      }, 
      {
        name: 'Assign Bed Order Menu',
        url: 'assignbedordermenu',
        icon: 'icon-pencil'
      },
      {
        name: 'Todays Order Summary',
        url: 'daysummary',
        icon: 'icon-bell'
      },
      {
        name: 'Manage Pitty Cash',
        url: 'viewpittycash',
        icon: 'icon-layers'
      },
      
      
    ]
  }


];
