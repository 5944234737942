import { formatDate } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AuthenticationService } from '../../../service/auth/authentication.service';
import { GlobalVariables } from '../../../service/GloabalVariables.service';
import { LogoutService } from '../../../service/logout/logout.service';
import { CashExpenseData } from '../../bean/CashExpense';
import { CashierSessionSummary } from '../../bean/CashierSessionSummary';
import { LogoutCashierSummaryDate } from '../../bean/LogoutCashierSummaryDate';

@Component({
  selector: 'app-logoutsummary',
  templateUrl: './logoutsummary.component.html',
  styleUrls: ['./logoutsummary.component.css']
})
export class LogoutsummaryComponent implements OnInit {

  model:CashierSessionSummary;

  summaryForm: FormGroup;
  submitted = false;

  loading=false;

  constructor(private logoutService:LogoutService,private authService:AuthenticationService,private formBuilder: FormBuilder,public dialogRef: MatDialogRef<LogoutsummaryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CashierSessionSummary,private globalVariable:GlobalVariables) {
   
   }

  ngOnInit() {
    this.model=new CashierSessionSummary();

    this.submitted=false;

    this.summaryForm = this.formBuilder.group({
      submittedCouponCount: ['', Validators.required],
      submittedCash: ['', Validators.required],
    });

  }

  onSubmit()
  {
    this.submitted=true;

    if (this.summaryForm.invalid) {
    
      return;
    }


    this.model.branchCode=this.authService.currentUserValue.branch.branchCode;
    this.model.loggedInTime=this.authService.currentUserValue.loggedInTime;
    this.model.username=this.authService.currentUserValue.username;
    this.model.name=this.authService.currentUserValue.firstName+" "+this.authService.currentUserValue.lastName;

    var currentDate=new Date();
    var dFromDate=this.authService.currentUserValue.loggedInTime;
    let date=dFromDate.split(' ');

    let dateCompoments=date[0].split("-");
    var year: number=+(dateCompoments[2]);
    var month: number=+(dateCompoments[1]);
    var day: number=+(dateCompoments[0]);

    const dateObj=new Date(year,month-1,day,0,0,0,0);

    var toDate= formatDate(currentDate, 'yyyy-MM-dd', 'en-US');

    this.model.loggedOutTime=formatDate(currentDate, 'dd-MM-yyyy HH:mm:ss', 'en-US');

    var param=new LogoutCashierSummaryDate();
    param.startDate=formatDate(dateObj, 'yyyy-MM-dd', 'en-US');
   // param.startDate=toDate;
    console.log(param.startDate);
    param.endDate=toDate;
    param.summary=this.model;

    this.loading=true;
    //this.logoutService.logoutSummary(param).subscribe(data => {
      this.logoutService.logoutSummaryByBranchCode(param).subscribe(data => {
      if(data.responseCode)
      {
        this.globalVariable.allDoctorList=null;
    this.globalVariable.allBedList=null;
    this.globalVariable.allHospitalList=null;
    this.globalVariable.allOrgAccountList=null;
    this.globalVariable.allBranchAccountList=null;
        this.dialogRef.close("success");
      }
      else
      {
        this.loading=false;
        alert(data.message);
        this.dialogRef.close("closed");
      }
    
      this.loading=false;
    });
  

   
  }

  onCloseClick(): void {
    this.dialogRef.close("closed");
  }

}
