import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BedOrderDetails } from '../views/bean/Bed';
import { BranchAccountOrder } from '../views/bean/Branches';
import { WalkIN } from '../views/bean/Company';
import { DoctorOrder } from '../views/bean/Doctor';
import { Hospital } from '../views/bean/Hospital';
import { FoodItem, Item, Menu } from '../views/bean/Menu';
import { OrgAccountOrder } from '../views/bean/OrgAccountOrder';

@Injectable({ providedIn: 'root' })
export class GlobalVariables {

    

    completedBedOrders:WalkIN[]=[];

    bedOrderList:BedOrderDetails[];

    selectedHospitalId:string;
  selectedHospitalName:string;
  hospitalList:Hospital[];
 //bedOrderList:BedOrderDetails[]=[];

  selectedMenuItemIndex:number;

  menuCode:string;

  selectedHospitalIdIndex:number=-1;

  hospitalSearchsubmitted:boolean;

  hospitalSearchForm: FormGroup;

  menuList:Menu[]=[];

  menuItemsList:Item[]=[];

  //completedBedOrders:WalkIN[]=[];

  assignedMenuCode:string;
  assignedMenuName:String;

  hospitalSelected:boolean;

    private walkIn:WalkIN;

    private prekotbill:boolean;

    allDoctorList:DoctorOrder[]=[];
 allHospitalList:Hospital[]=[];
 allBedList:BedOrderDetails[]=[];
 allOrgAccountList:OrgAccountOrder[]=[];
 allBranchAccountList:BranchAccountOrder[]=[];
 listOfActiveBills:WalkIN[]=[];

   
    getWalkIn()
    {
        return this.walkIn;
    }

    setWalkIn(walkin:WalkIN)
    {
        this.walkIn=walkin;
    }

    setPreKot(data:boolean)
    {
        this.prekotbill=data;
    }

    getPreKot()
    {
        return this.prekotbill;
    }

}