import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from '../auth/authentication.service';
import { BranchesResponseData } from '../../views/bean/Branches';
import { api } from '../../../environments/api';

@Injectable({
  providedIn: 'root'
})
export class BranchserviceService {

  httpOptions;

  formDataHttpOptions = {
    headers: new HttpHeaders({ 'Content-Type': undefined })
  }

  constructor(private http: HttpClient,private authService:AuthenticationService) {
    let httpHeadersContentTypeJson= new HttpHeaders({'Content-Type':'application/json',
    "Authorization":"Basic "+this.authService.currentUserValue.authdata});
    this.httpOptions = {
      headers: httpHeadersContentTypeJson
    };
   }


   saveBranchSummaryAlertsConfig(data: any): Observable<BranchesResponseData> {
    return this.http.post<BranchesResponseData>(api.manageBranchSummaryAlerts, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  fetchBranchSummaryAlertsConfig(data: any): Observable<BranchesResponseData> {
    return this.http.post<BranchesResponseData>(api.fetchBranchSummaryAlerts, data, this.httpOptions).pipe(catchError(this.handleError));
  }
  
  saveBranchData(data: any): Observable<BranchesResponseData> {
    return this.http.post<BranchesResponseData>(api.addNewBranch, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  updateBranchAccountData(data: any): Observable<BranchesResponseData> {
    return this.http.post<BranchesResponseData>(api.updateBranch, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  findBranchById(data: any): Observable<BranchesResponseData> {
    return this.http.post<BranchesResponseData>(api.findByBranchId, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  
  fetchAllBranches(): Observable<BranchesResponseData> {
    return this.http.get<BranchesResponseData>(api.fetchAllBranches, this.httpOptions).pipe(catchError(this.handleError));
  }

  activate(data:any): Observable<BranchesResponseData> {
    return this.http.post<BranchesResponseData>(api.activateBranch,data, this.httpOptions).pipe(catchError(this.handleError));
  }

  deactivate(data:any): Observable<BranchesResponseData> {
    return this.http.post<BranchesResponseData>(api.deactivateBranch,data, this.httpOptions).pipe(catchError(this.handleError));
  }

    
  delete(data:any): Observable<BranchesResponseData> {
    return this.http.post<BranchesResponseData>(api.deleteBranch,data, this.httpOptions).pipe(catchError(this.handleError));
  }

  addNewBranchAccountOrder(data:any): Observable<BranchesResponseData> {
    return this.http.post<BranchesResponseData>(api.addBranchAccountOrder,data, this.httpOptions).pipe(catchError(this.handleError));
  }

  fetchSummaryByDateRange(data:any): Observable<BranchesResponseData> {
    return this.http.post<BranchesResponseData>(api.fetchBranchOrderSummaryByDateRange,data, this.httpOptions).pipe(catchError(this.handleError));
  }

  fetchSummaryByBranchIdDateRange(data:any): Observable<BranchesResponseData> {
    return this.http.post<BranchesResponseData>(api.fetchBranchOrderSummaryByBranchIdDateRange,data, this.httpOptions).pipe(catchError(this.handleError));
  }

  fetchAllActiveBranchAccountOrders(): Observable<BranchesResponseData> {
    return this.http.get<BranchesResponseData>(api.fetchAllActiveBranchAccountOrder, this.httpOptions).pipe(catchError(this.handleError));
  }

  
  
  fetchAllBranchAccounts(): Observable<BranchesResponseData> {
    return this.http.get<BranchesResponseData>(api.fetchAllBranches, this.httpOptions).pipe(catchError(this.handleError));
  }

  fetchComissionAlertsEnabledBranches(): Observable<BranchesResponseData> {
    return this.http.get<BranchesResponseData>(api.fetchComissionAlertEnabledBranches, this.httpOptions).pipe(catchError(this.handleError));
  }


  linkHospitalBranch(data:any): Observable<BranchesResponseData> {
    return this.http.post<BranchesResponseData>(api.linkbranchhospital,data, this.httpOptions).pipe(catchError(this.handleError));
  }

  unlinkHospitalBranch(data:any): Observable<BranchesResponseData> {
    return this.http.post<BranchesResponseData>(api.unlinkbranchhospital,data, this.httpOptions).pipe(catchError(this.handleError));
  }

  linkOrgAccountBranch(data:any): Observable<BranchesResponseData> {
    return this.http.post<BranchesResponseData>(api.linkbranchorgaccount,data, this.httpOptions).pipe(catchError(this.handleError));
  }

  unlinkOrgAccountBranch(data:any): Observable<BranchesResponseData> {
    return this.http.post<BranchesResponseData>(api.unlinkbranchorgaccount,data, this.httpOptions).pipe(catchError(this.handleError));
  }

  sendComissionDayByDaySummary(data:any): Observable<BranchesResponseData> {
    return this.http.post<BranchesResponseData>(api.sendComissionAlertDayByDaySummary,data, this.httpOptions).pipe(catchError(this.handleError));
  }

  deleteBranchOrderTransaction(data:any): Observable<any> {
    return this.http.post<any>(api.deleteBranchOrderTransaction,data, this.httpOptions).pipe(catchError(this.handleError));
  
  }

  sendemaildaybydaysummarybydaterangebranchcode

  handleError(error: HttpErrorResponse):Observable<any>{

    if(error==undefined||error.error==undefined||error.error.message==undefined)
    {
      alert('Check Your Internet or Please Try Again');
    }
    else
    {
    alert(error.error.message);
    }
    return null;
    }
}
