import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../../service/auth/authentication.service';
import { FixedAssetService } from '../../../../service/fixedasset/fixedassets.service';
import { ManagementService } from '../../../../service/inventory/inventory.service';
import { AssetItem, AssetItemSummary, AssetItemTransaction } from '../../../bean/Assets';
import { InventoryLocations } from '../../../bean/Inventory';
import { Location } from '@angular/common';

@Component({
  selector: 'app-fixedassetitemview',
  templateUrl: './fixedassetitemview.component.html',
  styleUrls: ['./fixedassetitemview.component.css']
})
export class FixedassetitemviewComponent implements OnInit {

  private inventoryLocations:InventoryLocations[];

  inventoryCode:string;
  inventoryName:string;


  summary:AssetItemSummary;

  approvedTransactionList: AssetItemTransaction[] = [];
  rejectedTransactionList: AssetItemTransaction[] = [];
  balance:number;

  urlData:string;

  superAdmin:boolean=false;
  superVisor:boolean=false;
  inventoryAdmin:boolean=false;

  locationSelected:boolean;
  selectedLocationIdIndex:number;

  asset:AssetItem;

  
  constructor(private authService:AuthenticationService,private inventoryService: ManagementService,
              private fixedAssetService: FixedAssetService, 
              public dialog: MatDialog,private location: Location) { 

     
    this.asset=this.fixedAssetService.getAssetItem();
   

    this.superAdmin=false;
    this.superVisor=false;
    this.inventoryAdmin=false;
   
    if(this.authService.currentUserValue.role.role=='ROLE_SUPER_ADMIN')
    {
       this.superAdmin=true;
    }
    else if(this.authService.currentUserValue.role.role=='ROLE_SUPERVISOR')
    {
    this.superVisor=true;
    this.inventoryCode=this.authService.currentUserValue.role.details;
    }
    else if(this.authService.currentUserValue.role.role=='ROLE_INVENTORY_ADMIN')
    {
    this.inventoryAdmin=true;
    this.inventoryCode=this.authService.currentUserValue.role.details;
    }
  }

  goBack() {
    this.location.back();
  }
  
  onLocationSelected()
  {
   
    this.locationSelected=false;
    if(this.selectedLocationIdIndex!=-1)
    {
      if(this.selectedLocationIdIndex>0)
      {
        this.inventoryCode=this.inventoryLocations[this.selectedLocationIdIndex-1].inventoryCode;
        this.inventoryName=this.inventoryLocations[this.selectedLocationIdIndex-1].inventoryName;
      }
    this.locationSelected=true;
    }
   
  }

 
  ngOnInit() {

    


  this.inventoryCode=this.fixedAssetService.getInventoryCode();

  console.log(this.inventoryCode);
  

   if(this.authService.currentUserValue.role.role=="ROLE_INVENTORY_ADMIN"||this.authService.currentUserValue.role.role=="ROLE_SUPERVISOR")
  {
    this.fetchAllApprovedTransactionInventoryCode();
    
  }
  else
  {
  if(this.inventoryCode==undefined||this.inventoryCode==null)
   {
    this.fetchAllApprovedTransaction();
   }
   else
   {
    this.fetchAllApprovedTransactionInventoryCode();
   }
  }
   
  }

  fetchAllApprovedTransaction()
  {
    var param={"assetCode":this.asset.assetCode};
    this.fixedAssetService.assetItemTransactionFetchallApprovedAssetCode(param).subscribe(data => {

      this.summary= data.responseObject as AssetItemSummary;
      this.balance=this.summary.balance;
      this.approvedTransactionList = this.summary.assetslist;
      this.rejectedTransactionList = this.summary.rejectedlist;
      console.log(data);
    });
  }

  fetchAllApprovedTransactionInventoryCode()
  {
    var param={"inventoryCode":this.inventoryCode,"assetCode":this.asset.assetCode};
    this.fixedAssetService.assetItemTransactionFetchallApprovedAssetCodeInvCoce(param).subscribe(data => {
      this.summary= data.responseObject as AssetItemSummary;
      this.balance=this.summary.balance;
      this.approvedTransactionList = this.summary.assetslist;
      this.rejectedTransactionList = this.summary.rejectedlist;
      console.log(data);
    });
  }

  

}
