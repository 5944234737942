import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';
import { Item,Ingredient,Menu, FoodItem, MenuItemFoodItemLink, MenuMenuItemLink} from "../../bean/Menu";
import { MenuService } from '../../../service/menuServ/menu.service';
import { OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatTableDataSource, MatSnackBar } from '@angular/material';
import { BedOrderDetails, Bed, BedPaymentDetails, BedBillDetails, BedPayment, BedOrderTransactionVO } from '../../bean/Bed';
import { BedOrderService } from '../../../service/bed/bed-order.service';
import { MessageComponent } from '../../Generic/message/message.component';
import { BillDetials, BillItems, WalkIN, WalkINData } from '../../bean/Company';
import { BillPayment } from '../../bean/Doctor';
import { AuthenticationService } from '../../../service/auth/authentication.service';
import { PrintService } from '../../../service/print/print.service';

@Component({
    selector: 'PopupBedOrders',
    templateUrl: 'popupbedorder.html',
})
export class PopupBedOrders {

    myControl = new FormControl();
    dataSource: MatTableDataSource<BedOrderDetails>;

    activePatient:boolean;
    

    selectedMenuItemIndex:number;
    tableData:BedOrderDetails[]=[];

    tempTableData:BedOrderDetails[]=[];

    bedPaymentData:BedPaymentDetails[];
    bedBillData:BedBillDetails[];

    bedExtraPaymentData:BedPaymentDetails[];
    bedExtraBillData:BedBillDetails[];


    bed:Bed;

    displayDetailView:boolean=false;
    displayPaymentView:boolean=false;
    displayAddNewPatient:boolean=false;

    submitted:boolean=false;
    patientSubmitted:boolean=false;

    bedAdvancePaymentForm: FormGroup;
    bedReturnPaymentForm: FormGroup;
    bedDiscountPaymentForm: FormGroup;
    patientForm: FormGroup;

    model:BedPaymentDetails=new BedPaymentDetails();

    patient:BedOrderDetails=new BedOrderDetails();

    selectedBed:BedOrderDetails;

    bedPayment:BedPayment;

    selectedIndex:number;

    invoiceNo:string;

    payments: string[] = ["None","Cash","UPI"];

    orderTypes: string[] = ["Extra Bed Orders","Regular Patient Orders"];
    selectedOrder:string;

    activeBed:boolean=false;


    displayReturnPaymentView:boolean=false;

    displayAdvancePaymentView:boolean=false;

    displayDiscountPaymentView:boolean=false;


    returnPaymentSubmitted:boolean=false;

    advancePaymentSubmitted:boolean=false;

    discountPaymentSubmitted:boolean=false;

    walkInData:WalkIN;

    modelAdvancePayment:BillPayment=new BillPayment();

    modelReturnPayment:BillPayment=new BillPayment();

    modelDiscountPayment:BillPayment=new BillPayment();

    selectedBedTransactionNo:string;

    orderFlavours:string[]=["BBMP","NORMAL"];

    edit:boolean;

    loading:boolean;

    username:string;
    name:string;
    discountVisible:boolean;


    constructor(private authService:AuthenticationService,private _snackBar: MatSnackBar,private formBuilder: FormBuilder,public dialogRef: MatDialogRef<PopupBedOrders>,
         @Inject(MAT_DIALOG_DATA) public data: Bed,
        private mServiceMenu: BedOrderService,private printService: PrintService
        )
    {
        this.dataSource = new MatTableDataSource(this.tableData);
        this.bed=data;
        this.activeBed=this.bed.activePatient;

        this.activePatient=false;

        console.log("Bed Number="+this.bed.bedNumber)

        this.edit=false;
       
        this.username=this.authService.currentUserValue.username;
        this.name=this.authService.currentUserValue.firstName+" "+this.authService.currentUserValue.lastName;
       
     
    }

    onOrderTypeClick(order:any)
    {
        this.selectedOrder=order;
    }

    ngOnInit() {

        this.displayAdvancePaymentView=false;
        this.displayReturnPaymentView=false;
        this.displayAddNewPatient=false;
        this.displayDiscountPaymentView=false;

        this.bedReturnPaymentForm = this.formBuilder.group({
            ramount: ['', Validators.required],
        });

        this.bedDiscountPaymentForm = this.formBuilder.group({
            discountAmount: ['', Validators.required],
        });

        this.bedAdvancePaymentForm = this.formBuilder.group({
          aamount: ['', Validators.required],
          apaymentMode: ['', Validators.required]
      });

      this.patientForm = this.formBuilder.group({
        name: ['', Validators.required],
        phone: ['', Validators.required],
        orderFlavour: ['', Validators.required]
    });

    if(this.bed.bedNumber!=null)
    {
        this.fetchBedOrder();
    }
    }


    onPaymentModeSelectionChange(event)
    {
        if(event.target.value!=this.payments[0])
        {
            this.discountVisible=true;
        }
        else
        {
            this.discountVisible=false;
        }

    }

    sendSummaryReport(order:BedOrderDetails):void
    {
        var beddetails={"wardNumber":this.bed.wardNumber,"bedNumber":this.bed.bedNumber,"hospitalId":this.bed.hospitalId,"transactionNumber":order.bedTransactionNo};
        this.mServiceMenu.sendBedOrderSummaryReportAsEmail(beddetails).subscribe(data=>{
            console.log(data.responseObject);
            this.openSnackBar("Email Summary Sent Successfully..");

         });
    }

    printExtraOrdersBillSummary(order:BedOrderDetails):void
    {
       
        order.createdUsername=this.authService.currentUser.firstName+" "+this.authService.currentUser.lastName;
        order.createdByName=this.authService.currentUserValue.firstName+" "+this.authService.currentUserValue.lastName;
       
        console.log(order);
        this.printService.printExtraOrderSummary(order).subscribe(data=>{
            console.log(data);
            this.dialogRef.close();
      
          });

          this.selectedOrder=this.orderTypes[0];
        

    }

    printBill(bill:BedBillDetails)
    {
        console.log(bill);
        var walkInData=new WalkIN();
        walkInData.bedNumber=this.selectedBed.bedNumber;
        walkInData.wardNumber=this.selectedBed.wardNumber;
        walkInData.hospitalId=this.selectedBed.hospitalId;
        walkInData.paymentAmount=bill.amountPaid
        walkInData.totalAmount=bill.totalAmount
        walkInData.discountAmount=0;
        
        walkInData.subscidised=bill.isSubscidised;
        walkInData.bedTransactionNo=this.selectedBed.bedTransactionNo;
        walkInData.billdetails=new BillDetials();
        walkInData.billdetails.invoiceNo=bill.invoiceNo;
        walkInData.billdetails.kotNo=bill.kotNo;
        walkInData.billdetails.totalItems=bill.totalItems;
        walkInData.billdetails.diffItemsCount=bill.diffItemsCount;
        walkInData.billdetails.amountPaid=bill.amountPaid;
        walkInData.billdetails.totalAmount=bill.totalAmount;

        walkInData.billdetails.createdDate=bill.createdDate;
        walkInData.billdetails.billitems=[];
        for (var bedBillItem of bill.billitems) {
            var billItem=new BillItems();
            billItem.totalPrice=bedBillItem.totalPrice;
            billItem.quantity=bedBillItem.quantity;
            billItem.price=bedBillItem.price;
            billItem.itemName=bedBillItem.itemName;
            billItem.menuItemCode=bedBillItem.menuItemCode;
            
            walkInData.billdetails.billitems.push(billItem);
       }


        walkInData.createdUsername=this.authService.currentUser.firstName+" "+this.authService.currentUser.lastName;
        walkInData.createdByName=this.authService.currentUserValue.firstName+" "+this.authService.currentUserValue.lastName;
        walkInData.customerName=this.selectedBed.patientName;
  
        this.printService.printBill(walkInData).subscribe(data=>{
            console.log(data);
            this.dialogRef.close();
      
          });

    }

    fetchBedOrder():void
    {
        this.activePatient=false;
        var bedNumber={"wardNumber":this.bed.wardNumber,"bedNumber":this.bed.bedNumber,"hospitalId":this.bed.hospitalId};
        this.mServiceMenu.getBedOrderByBedNo(bedNumber).subscribe(data=>{
            console.log(data.responseObject);
            this.tableData = data.responseObject;
            this.tempTableData=this.tableData;
            if(this.tableData.length>0)
            {
            this.activePatient=true;
            }
            if(this.displayDetailView)
            {
                this.viewBillDetails(this.selectedIndex);
            }

         });
      
    }

    onSettlePaymentSubmit()
    {
        this.returnPaymentSubmitted = true;
    
        // stop the process here if form is invalid
        if (this.bedReturnPaymentForm.invalid) {
            return;
        }

        if(this.modelReturnPayment.amount<=0)
        {
            this.openSnackBar("Enter Valid Amount");
            return;
        }


        this.walkInData=new WalkIN();
        this.walkInData.branchCode=this.authService.currentUserValue.branch.branchCode;
        this.walkInData.billdetails=new BillDetials();
        this.walkInData.billdetails.billitems=[];
        this.walkInData.paymentdetails=[]
        this.modelReturnPayment.branchCode=this.authService.currentUserValue.branch.branchCode;
        this.modelReturnPayment.amount=-(this.modelReturnPayment.amount);
        this.modelReturnPayment.paymentMode="Cash";
        this.walkInData.paymentdetails.push(this.modelReturnPayment);
        this.walkInData.coupondetails=[];
        this.walkInData.commentsdetails=[]
        this.walkInData.createdUsername=this.username;
        this.walkInData.createdByName=this.name;
        this.walkInData.bedNumber=this.bed.bedNumber;
        this.walkInData.wardNumber=this.bed.wardNumber;
        this.walkInData.hospitalId=this.bed.hospitalId;
        this.walkInData.bedTransactionNo=this.selectedBedTransactionNo;
        this.walkInData.paymentAmount=-(this.modelReturnPayment.amount);
        this.walkInData.couponAmount=0;
        this.walkInData.billdetails.amountPaid=-(this.modelReturnPayment.amount);
        this.walkInData.totalAmount=0;
        var billItem =new BillItems();
        billItem.branchCode=this.authService.currentUserValue.branch.branchCode;
        billItem.itemSeqNo=1;
        billItem.itemName="Returned Amount";
        billItem.menuItemCode="Returned Amount"
        billItem.price=0;
        billItem.quantity=1;
        billItem.subscidisedPrice=0;
        billItem.totalPrice=0;
        this.walkInData.billdetails.billitems.push(billItem);
        console.log(this.walkInData);
        this.mServiceMenu.addBedAdvancePayment(this.walkInData).subscribe(data=>{
          if(data.responseCode)
          {
            this.openSnackBar(data.message);
            this.bedReturnPaymentForm.reset();
            this.ngOnInit();
         }
        });
    }


    onAdvancePaymentSubmit()
    {
        this.advancePaymentSubmitted = true;
    
        // stop the process here if form is invalid
        if (this.bedAdvancePaymentForm.invalid) {
            return;
        }

        if(this.modelAdvancePayment.amount<0)
        {
            this.openSnackBar("Enter Valid Amount");
            return;
        }

      /*  if(this.modelAdvancePayment.paymentMode==this.payments[1])
        {
            var totalPayment=(+this.modelAdvancePayment.amount)+(+this.modelAdvancePayment.discount);
            var balance=(+this.selectedBed.totalExtraAmount)-(+this.selectedBed.amountExtraPaid)
            if(totalPayment>balance)
            {
                this.openSnackBar("Payment is Greater Than Balance");
                return;
            }
        }*/
        this.modelAdvancePayment.discount=0;

        this.loading=true;
        this.walkInData=new WalkIN();
        this.walkInData.branchCode=this.authService.currentUserValue.branch.branchCode;
        this.walkInData.billdetails=new BillDetials();
        this.walkInData.billdetails.branchCode=this.authService.currentUserValue.branch.branchCode;
        this.walkInData.billdetails.billitems=[];
        this.walkInData.paymentdetails=[]
        this.modelAdvancePayment.branchCode=this.authService.currentUserValue.branch.branchCode;
        this.walkInData.paymentdetails.push(this.modelAdvancePayment);
        this.walkInData.coupondetails=[];
        this.walkInData.commentsdetails=[];
        this.walkInData.createdUsername=this.username;
        this.walkInData.createdByName=this.name;
        this.walkInData.bedNumber=this.bed.bedNumber;
        this.walkInData.wardNumber=this.bed.wardNumber;
        this.walkInData.hospitalId=this.bed.hospitalId;
        this.walkInData.bedTransactionNo=this.selectedBedTransactionNo;
        this.walkInData.paymentAmount=this.modelAdvancePayment.amount;
        this.walkInData.discountAmount=0;
        this.walkInData.couponAmount=0;
        this.walkInData.totalAmount=0;

        this.walkInData.billdetails.amountPaid=this.modelAdvancePayment.amount;
        this.walkInData.billdetails.discountAmount=this.modelAdvancePayment.discount;
      
        var billItem =new BillItems();
        billItem.branchCode=this.authService.currentUserValue.branch.branchCode;
        billItem.itemSeqNo=1;
        billItem.itemName="payment";
        billItem.menuItemCode="payment"
        billItem.price=0;
        billItem.quantity=1;
        billItem.subscidisedPrice=0;
        billItem.totalPrice=0;
        this.walkInData.billdetails.billitems.push(billItem);
        console.log(this.walkInData);
        this.mServiceMenu.addBedAdvancePayment(this.walkInData).subscribe(data=>{
            this.loading=false;
          if(data.responseCode)
          {
            this.openSnackBar(data.message);
            this.bedAdvancePaymentForm.reset();
            this.ngOnInit();
         }
       
        },
        error=>
        {
            this.loading=false;
            alert("Payment Not Successful, Please Try Again");
           
        }
        );
    }


    onDiscountPaymentSubmit()
    {
        
        this.discountPaymentSubmitted = true;
    
        // stop the process here if form is invalid
        if (this.bedDiscountPaymentForm.invalid) {
            return;
        }

        if(this.modelDiscountPayment.discount<=0)
        {
            this.openSnackBar("Enter Valid Discount Amount");
            return;
        }

      

      /*  if(this.modelAdvancePayment.paymentMode==this.payments[1])
        {
            var totalPayment=(+this.modelAdvancePayment.amount)+(+this.modelAdvancePayment.discount);
            var balance=(+this.selectedBed.totalExtraAmount)-(+this.selectedBed.amountExtraPaid)
            if(totalPayment>balance)
            {
                this.openSnackBar("Payment is Greater Than Balance");
                return;
            }
        }*/

        this.loading=true;
        this.modelDiscountPayment.amount=0;
        this.modelDiscountPayment.paymentMode="Discount"


        this.loading=true;
        this.walkInData=new WalkIN();
        this.walkInData.branchCode=this.authService.currentUserValue.branch.branchCode;
        this.walkInData.billdetails=new BillDetials();
        this.walkInData.billdetails.branchCode=this.authService.currentUserValue.branch.branchCode;
        this.walkInData.billdetails.billitems=[];
        this.walkInData.paymentdetails=[]
        this.modelDiscountPayment.branchCode=this.authService.currentUserValue.branch.branchCode;
        this.walkInData.paymentdetails.push(this.modelDiscountPayment);
        this.walkInData.coupondetails=[];
        this.walkInData.commentsdetails=[];
        this.walkInData.createdUsername=this.username;
        this.walkInData.createdByName=this.name;
        this.walkInData.bedNumber=this.bed.bedNumber;
        this.walkInData.wardNumber=this.bed.wardNumber;
        this.walkInData.hospitalId=this.bed.hospitalId;
        this.walkInData.bedTransactionNo=this.selectedBedTransactionNo;
        this.walkInData.paymentAmount=this.modelDiscountPayment.amount;
        this.walkInData.discountAmount=this.modelDiscountPayment.discount;
        this.walkInData.couponAmount=0;
        this.walkInData.totalAmount=0;

        this.walkInData.billdetails.amountPaid=this.modelDiscountPayment.amount;
        this.walkInData.billdetails.discountAmount=this.modelDiscountPayment.discount;
      
        var billItem =new BillItems();
        billItem.branchCode=this.authService.currentUserValue.branch.branchCode;
        billItem.itemSeqNo=1;
        billItem.itemName="payment";
        billItem.menuItemCode="payment"
        billItem.price=0;
        billItem.quantity=1;
        billItem.subscidisedPrice=0;
        billItem.totalPrice=0;
        this.walkInData.billdetails.billitems.push(billItem);
        console.log(this.walkInData);
        this.mServiceMenu.addBedAdvancePayment(this.walkInData).subscribe(data=>{
            this.loading=false;
          if(data.responseCode)
          {
            this.openSnackBar(data.message);
            this.bedDiscountPaymentForm.reset();
            this.ngOnInit();
         }
       
        },
        error=>
        {
            this.loading=false;
            alert("Payment Not Successful, Please Try Again");
           
        }
        );
    }

    closeAdvancePaymentView()
    {
        this.displayAdvancePaymentView=false;
        this.bedAdvancePaymentForm.reset();
    }

    closeReturnPaymentView()
    {
        this.displayReturnPaymentView=false;
        this.bedReturnPaymentForm.reset();
    }

    closeDiscountPaymentView()
    {
        this.displayDiscountPaymentView=false;
        this.bedDiscountPaymentForm.reset();
    }

    editPatientDetails(i:number):void
    {
        this.edit=true;
        this.selectedIndex=i;
        this.patient=this.tableData[i];
        console.log("Patient="+this.patient.patientName);
        this.displayAdvancePaymentView=false;
        this.displayReturnPaymentView=false;
        this.displayAddNewPatient=true;
        
        
    }

    showAddPaymentView(bedTranNo:string):void
    {
        this.selectedBedTransactionNo=bedTranNo;
        this.displayAdvancePaymentView=true;
        this.displayReturnPaymentView=false;
        this.displayDiscountPaymentView=false;
    }

    showReturnPaymentView(bedTranNo:string):void
    {
        this.selectedBedTransactionNo=bedTranNo;
        this.displayAdvancePaymentView=false;
        this.displayReturnPaymentView=true;
        this.displayDiscountPaymentView=false;
    }

    showDiscountPaymentView(bedTranNo:string):void
    {
        this.selectedBedTransactionNo=bedTranNo;
        this.displayAdvancePaymentView=false;
        this.displayReturnPaymentView=false;
        this.displayDiscountPaymentView=true;
    }

    addNewBedPatient():void
    {

        this.loading=true;
        if(!this.edit)
        {
        this.patientSubmitted = true;

        this.patient.bedNumber=this.bed.bedNumber;
        this.patient.wardNumber=this.bed.wardNumber;
        this.patient.hospitalId=this.bed.hospitalId;
        this.patient.hospitalName=this.bed.hospitalName;

        this.patient.createdUsername=this.username;
        this,this.patient.createdByName=this.name;

        console.log( this.patient.phoneNumber);

        console.log( this.patient);
    
        // stop the process here if form is invalid
        if (this.patientForm.invalid) {
            return;
        }
        this.mServiceMenu.addNewBedPatient(this.patient).subscribe(data=>{
            this.loading=false;
            if(data.responseCode==true)
            {
            this.openSnackBar(data.message);
            this.displayAddNewPatient=false;
            this.patientForm.reset();
            this.patientSubmitted=false;
            this.fetchBedOrder();
            this.displayDetailView=false;
            }
            else{
              
              alert(data.message);
            }
            
         });

      
        }
        else
        {
            this.patientSubmitted=true;
           var bedOrderTransactionVO={"bedTransactionNo":this.patient.bedTransactionNo,
           "orderFlavour":this.patient.orderFlavour,"patientName":this.patient.patientName,"phoneNumber":this.patient.phoneNumber,"email":this.patient.email};
           console.log("BedOrderTransactionVO="+bedOrderTransactionVO.orderFlavour);
            this.mServiceMenu.editPatientDetails(bedOrderTransactionVO).subscribe(data=>{
                this.loading=false;
                if(data.responseCode==true){
                this.openSnackBar(data.message);
                this.displayAddNewPatient=false;
                this.patientForm.reset();
                this.patientSubmitted=false;
                this.fetchBedOrder();
                this.displayDetailView=false;
                }
                else{
                  alert(data.message);
                }
                
             });

        }
    }

    
    viewBillDetails(i:number):void
    {
        this.selectedOrder=this.orderTypes[0];
        this.selectedIndex=i;
       
        this.selectedBed=this.tableData[i];

        if(this.selectedBed.orderFlavour!=null||this.selectedBed.orderFlavour!=undefined)
        {
        this.displayDetailView=true;
        this.bedPaymentData=this.tableData[i].bedpaymentdetails;
        this.bedBillData=this.tableData[i].billdetails;
        this.bedExtraPaymentData=this.tableData[i].bedextrapaymentdetails;
        this.bedExtraBillData=this.tableData[i].billextradetails;
        this.tableData=[this.selectedBed];
        }
        else
        {
            alert('Edit Patient Details update Patient OrderType (BBMP/NORMAL),By Going to Action Edit Details After that You can Use View Orders')
        }

    }

    dischargePatient(i:number):void
    {

       
        this.selectedBed=this.tableData[i];
      //  var balance=this.selectedBed.totalExtraAmount-this.selectedBed.amountExtraPaid;
      /*  if(balance==0)
        {*/
        this.mServiceMenu.discharge(this.tableData[i]).subscribe(data=>{
            console.log(data.message);
            this.openSnackBar(data.message);
            this.displayDetailView=false;
            this.displayPaymentView=false;
            this.displayAddNewPatient=false;
            this.displayReturnPaymentView=false;
            this.displayAdvancePaymentView=false;
            this.ngOnInit();
         });
        /*}
        else
        {*/
        //alert("Cant Discharge Patient, Since Balance is Not Settled");
        //}
        



    }

    hideDetailView():void
    {
        this.displayDetailView=false;
        this.tableData=this.tempTableData;
    }

    newPayment(invoiceNumber:string):void
    {
        console.log("invoice="+invoiceNumber);
        this.invoiceNo=invoiceNumber;
        this.displayPaymentView=true;
       
    }

    closePaymentView():void
    {
        this.displayPaymentView=false;
        this.bedAdvancePaymentForm.reset();
        this.submitted=false;
       
    }

    onPaymentSubmit() {
        this.submitted = true;
    
        // stop the process here if form is invalid
        if (this.bedAdvancePaymentForm.invalid) {
            return;
        }
    
        this.bedPayment=new BedPayment();
        this.bedPayment.branchCode=this.authService.currentUserValue.branch.branchCode;
        this.bedPayment.createdUsername=this.username;
        this.bedPayment.createdByName=this.name;
        this.bedPayment.invoiceNo=this.invoiceNo;
        this.bedPayment.hospitalId=this.bed.hospitalId;
        this.bedPayment.hospitalName=this.bed.hospitalName;
        this.bedPayment.bedNumber=this.data.bedNumber;
        this.bedPayment.bedTransactionNo=this.tableData[0].bedTransactionNo;
        this.bedPayment.paymentdetails=this.model;
        this.mServiceMenu.addBedPayment(this.bedPayment).subscribe(data => {
          if(data.responseCode==true)
          {
          this.openSnackBar(data.message);
          this.displayPaymentView=false;
          this.fetchBedOrder();
        
          }
          else{
            alert(data.message);
          }
        }
        );

        this.bedAdvancePaymentForm.reset();
        this.submitted=false;
      
      
    }

    openSnackBar(message: string) {
        this._snackBar.openFromComponent(MessageComponent, {
          data: message,
          duration: 5 * 1000
        });
      }
  
    
    onCloseClick(): void {
        this.dialogRef.close();
    }

    showAddPatientView():void
    {

      
        this.patient=new BedOrderDetails();    
        this.patientForm.reset();
        this.edit=false;
        this.displayAddNewPatient=true;
    


      
    }

    hideAddPatientView():void
    {
        this.displayAddNewPatient=false;
        if(!this.edit)
        {
        this.patientForm.reset();
        }
        
    }


}