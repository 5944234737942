import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';import { api } from '../../../environments/api';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from '../auth/authentication.service';
import { APIResponse, AssetItem, AssetItemTransaction } from '../../views/bean/Assets';

@Injectable({
  providedIn: 'root'
})
export class FixedAssetService {

  fixedAssetItem:AssetItem;
  inventoryCode:string;

  getInventoryCode():string
  {
    return this.inventoryCode;
  }

  setInventoryCode(invCode:string)
  {
    this.inventoryCode=invCode;
  }


  getAssetItem():AssetItem
  {
    return this.fixedAssetItem;
  }

  setAssetItem(item:AssetItem)
  {
    this.fixedAssetItem=item;
  }

 
  httpOptions;

  formDataHttpOptions={
    headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
  }

  constructor(private http: HttpClient,private authService:AuthenticationService) {
    let httpHeadersContentTypeJson= new HttpHeaders({'Content-Type':'application/json',
    "Authorization":"Basic "+this.authService.currentUserValue.authdata});
    this.httpOptions = {
      headers: httpHeadersContentTypeJson
    };
   }

 

uploadAssetItemExcel(fileToUpload: File,createdUser:any): Observable<any> {
  const formData: FormData = new FormData();
  formData.append('file', fileToUpload, fileToUpload.name);
  formData.append('createdUser',createdUser);
  return this.http.post<any>(api.uploadAssetItemExcel, formData).pipe(catchError(this.handleError));
}

  addNewAssetItem(data: any): Observable<APIResponse> {
    return this.http.post<any>(api.fixedassetaddnew, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  activateFixedAssetItem(data: any): Observable<APIResponse> {
    return this.http.post<any>(api.fixedassetactivate, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  deactivateFixedAssetItem(data: any): Observable<APIResponse> {
    return this.http.post<any>(api.fixedassetdeactivate, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  deleteFixedAssetItem(data: any): Observable<APIResponse> {
    return this.http.post<any>(api.fixedassetdelete, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  fixedAssetItemFindbyAssetCode(data: any): Observable<APIResponse> {
    return this.http.post<any>(api.fixedassetfindbyassetcode, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  fixedAssetItemFetchAllActive(): Observable<APIResponse> {
    return this.http.get<any>(api.fixedassetfetchallactive, this.httpOptions).pipe(catchError(this.handleError));
  }
 
  fixedAssetItemFetchAll(): Observable<APIResponse> {
    return this.http.get<any>(api.fixedassetfetchall, this.httpOptions).pipe(catchError(this.handleError));
  }

  fixedAssetItemFetchAllInventoryCode(data: any): Observable<APIResponse> {
    return this.http.post<any>(api.fixedassetfetchallinvcode,data, this.httpOptions).pipe(catchError(this.handleError));
  }

  fixedAssetItemFetchAllActiveInventoryCode(data: any): Observable<APIResponse> {
    return this.http.post<any>(api.fixedassetfetchallactiveinvcode,data, this.httpOptions).pipe(catchError(this.handleError));
  }

  


  
  addNewAssetItemTransaction(data: AssetItemTransaction): Observable<APIResponse> {
    return this.http.post<any>(api.fixedassettranadd, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  assetItemTransactionAccept(data: any): Observable<APIResponse> {
    return this.http.post<any>(api.fixedassettranaccept, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  assetItemTransactionReject(data: any): Observable<APIResponse> {
    return this.http.post<any>(api.fixedassettranreject, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  assetItemTransactionDelete(data: any): Observable<APIResponse> {
    return this.http.post<any>(api.fixedassettrandelete, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  assetItemTransactionFetchallApprovedAssetCode(data: any): Observable<APIResponse> {
    return this.http.post<any>(api.fixedassettranfetchallapprovedassetcode, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  assetItemTransactionFetchallApprovedAssetCodeInvCoce(data: any): Observable<APIResponse> {
    return this.http.post<any>(api.fixedassettranfetchallapprovedassetcodeinvcode, data, this.httpOptions).pipe(catchError(this.handleError));
  }
 
  assetItemTransactionFetchallNewAssetCode(data: any): Observable<APIResponse> {
    return this.http.post<any>(api.fixedassettranfetchallnewassetcode, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  assetItemTransactionFetchallNew(): Observable<APIResponse> {
    return this.http.get<any>(api.fixedassettranfetchallnew, this.httpOptions).pipe(catchError(this.handleError));
  }

  assetItemTransactionFetchallNewInventoryCode(data: any): Observable<APIResponse> {
    return this.http.post<any>(api.fixedassettranfetchallnewinvcode, data, this.httpOptions).pipe(catchError(this.handleError));
  }


  assetItemTransactionFetchallDebitAssetCode(data: any): Observable<APIResponse> {
    return this.http.post<any>(api.fixedassettranalldebitassetcode, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  assetItemTransactionFetchallCreditAssetCode(data: any): Observable<APIResponse> {
    return this.http.post<any>(api.fixedassettranallcreditassetcode, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  assetItemTransactionFetchallAceptedAssetCode(data: any): Observable<APIResponse> {
    return this.http.post<any>(api.fixedassettranallacceptedassetcode, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  assetItemTransactionFetchallRejectedAssetCode(data: any): Observable<APIResponse> {
    return this.http.post<any>(api.fixedassettranallrejectedassetcode, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  assetItemTransactionFetchallAceptedAssetCodeInvCoce(data: any): Observable<APIResponse> {
    return this.http.post<any>(api.fixedassettranallacceptedassetcodeinvcode, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  assetItemTransactionFetchallRejectedAssetCodeInvCode(data: any): Observable<APIResponse> {
    return this.http.post<any>(api.fixedassettranallrejectedassetcodeinvcode, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  
 downloadInventoryItemExcel() {
  this.http.get(api.downloadInventoryItemExcel, this.httpOptions).pipe(catchError(this.handleError));
}

  

  handleError(error: HttpErrorResponse):Observable<any>{
  
    if(error==undefined||error.error==undefined||error.error.message==undefined)
    {
      alert('Check Your Internet or Please Try Again');
    }
    else
    {
    alert(error.error.message);
    }
    return null;
    }
}
