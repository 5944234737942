import { Double } from "aws-sdk/clients/apigateway";

export class PittyCash
{
    id:string;
    branchCode:string;
    amount:number;
    balance:number;
    type:string;
    createdUsername: string;
    name: string;
    createdDate:string;
    expenseType:string;
    subExpenseType:String;
    comments:string;
}

export class PittyCashVO
{
balance:number;
totalCredits:number;
totalDebits:number;
transanctions:PittyCash[];
}




export class PittyCashData {
    responseCode: boolean;
    message: string;
    responseObject: PittyCashVO;
}