import {Component } from '@angular/core';
import { superAdminNavItems } from '../../superadminnav';
import { GlobalVariables } from '../../service/GloabalVariables.service';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../service/auth/authentication.service';
import { ChangepasswordComponent } from '../../views/user/changepassword/changepassword.component';
import { MatDialog, MatSnackBar } from '@angular/material';
import { MessageComponent } from '../../views/Generic/message/message.component';

@Component({
  selector: 'sadmin-dashboard',
  templateUrl: './sadmin-layout.component.html'
})
export class SadminLayoutComponent {
  public sidebarMinimized = false;
  public navItems = superAdminNavItems;

  multipleRoles:boolean;

  constructor(public dialog: MatDialog,private route: Router,private authService:AuthenticationService,private snackBar: MatSnackBar)
  {
    

  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  passwordChange()
  {

    const dialogRef = this.dialog.open(ChangepasswordComponent, {
      width: '800px',maxHeight:'600px'
    });
  
    
     dialogRef.afterClosed().subscribe((data) => {
  
      if(data!=null)
      {
        this.authService.currentUserValue.password=data.newPassword;
       
        this.openSnackBar("Password Change Successfully");
        this.route.navigate(['/logout']);
        dialogRef.close();
      }
      
  
  
  });

  }

  ngOnInit()
  {
    /* if (this.authService.currentUserValue==null||this.authService.currentUserValue==undefined||this.authService.currentUserValue.username==null||this.authService.currentUserValue.username==undefined||this.authService.currentUserValue.role.role=="ROLE_INVENTORY_ADMIN"||this.authService.currentUserValue.role.role=="ROLE_CASHIER") { 
      this.route.navigate(['/logout']);
    } */

    if(this.authService.currentUserValue.roles!=undefined||this.authService.currentUserValue.roles.length>1)
    {
      this.multipleRoles=true;
    }

    else if(this.authService.currentUserValue.branches!=undefined||this.authService.currentUserValue.branches.length>1)
    {
     
      this.multipleRoles=true;
    
    }
  }

  openSnackBar(message: string) {
    this.snackBar.openFromComponent(MessageComponent, {
      data: message,
      duration: 5 * 1000
    });
  }

  logout()
  {
    console.log("logging Out");
    this.route.navigate(['/logout']);
  }


  roleBranchChange()
  {
    this.route.navigate(['/afterlogin']);
  }

}




