import { Component, OnInit } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { threadId } from 'worker_threads';
import { AuthenticationService } from '../../../service/auth/authentication.service';
import { ManagementService } from '../../../service/inventory/inventory.service';
import { KitchenDebitService } from '../../../service/kitchendebit/kitchendebits.service';
import { MenuService } from '../../../service/menuServ/menu.service';
import { InventoryLocations } from '../../bean/Inventory';
import { FoodItem, KitchenDebitOrderDetails, KitchenInventoryDebits, KitchenInventoryOrders, LinkedFoodItemBean } from '../../bean/Menu';
import { MessageComponent } from '../../Generic/message/message.component';
import { AlertPopup } from '../../popup/AlertPopup';
import { ConfirmPopup } from '../../popup/ConfirmPopup';


@Component({
  selector: 'app-dayfooditemsrequest',
  templateUrl: './dayfooditemsrequest.component.html',
  styleUrls: ['./dayfooditemsrequest.component.css']
})
export class DayfooditemsrequestComponent implements OnInit {

  private inventoryLocations:InventoryLocations[];

  inventoryCode:string;
  inventoryName:string;

  selectedBranchCode:string;
  selectedBranchName:string;
  selectedDayOfWeek:string;


  allSelectedFoodItemData: FoodItem[] = [];

  allFoodList:KitchenDebitOrderDetails[]=[];

  kitchenOrderList:KitchenInventoryOrders[]=[];

  totalItems:number;
  countItems:number;
  

  constructor(private authService:AuthenticationService, private inventoryService: ManagementService,private kitchenDebitService: KitchenDebitService,private menuService: MenuService, private snackBar: MatSnackBar,public dialog: MatDialog,
    public router: Router) { 
    
    
    }

    reset()
    {
      this.ngOnInit();
    }

  ngOnInit() {

    this.selectedDayOfWeek=this.getDayOfWeek();
    this.selectedBranchCode=this.authService.currentUserValue.branch.branchCode;
    this.selectedBranchName=this.authService.currentUserValue.branch.branchName;
    this.allFoodList=[];
    this.countItems=0;
    this.totalItems=0;

    this.countItems=this.kitchenOrderList.length;

    this.fetchLinkedFoodItemFortheDayBranchCode();

    this.inventoryService.getAllInventoryLocationItems().subscribe(data => {
      console.log(data.responseObject);
      this.inventoryLocations = data.responseObject;
      for(var i=0;i<this.inventoryLocations.length;i++)
      {
        if(this.inventoryCode==this.inventoryLocations[i].inventoryCode)
        {
          this.inventoryName=this.inventoryLocations[i].inventoryName;
          break;
        }
      }
    });

  }


  getDayOfWeek():string
               {
                const d = new Date();
                let day = d.getDay();

               

                if(day==0)
                {
                  return 'SUNDAY';
                }
               
                else if(day==1)
                {
                  return 'MONDAY';
                }
                else if(day==2)
                {
                  return 'TUESDAY';
                }
                else if(day==3)
                {
                  return 'WEDNESDAY';
                }
                else if(day==4)
                {
                  return 'THRUSDAY';
                }
                else if(day==5)
                {
                  return 'FRIDAY';
                }
                else if(day==6)
                {
                  return 'SATURDAY';
                }

               }
 

  fetchLinkedFoodItemFortheDayBranchCode()
  {
    var param={'branchCode':this.selectedBranchCode,'day':this.selectedDayOfWeek};
    this.menuService.fetchalllinkedFoodItemToDayBranchCode(param).subscribe(data=>{
      this.allSelectedFoodItemData = data.responseObject as FoodItem[];
      for(let i=0;i<this.allSelectedFoodItemData.length;i++)
      {
       let details=new KitchenDebitOrderDetails();
       details.foodItemCode=this.allSelectedFoodItemData[i].foodItemCode;
       details.foodItemName=this.allSelectedFoodItemData[i].foodItemName;
       details.personCount=1;
       details.ingredients=this.allSelectedFoodItemData[i].ingredientDetails;
       details.selected=false;
       this.allFoodList.push(details);
      }
      this.totalItems=this.allFoodList.length;
      this.allSelectedFoodItemData=[];

    });
  
    }

  

  

  openSnackBar(message: string) {
    this.snackBar.openFromComponent(MessageComponent, {
      data: message,
      duration: 5 * 1000
    });
  }

  add(foodItem:KitchenDebitOrderDetails)
  {
   
  

     let order= this.generateInventoryDebitData(foodItem);
     foodItem.selected=true;
     this.kitchenOrderList.push(order);
    
   this.countItems=this.kitchenOrderList.length;

  }

  remove(foodItem:KitchenDebitOrderDetails)
  {
    for(let i=0;i<this.kitchenOrderList.length;i++)
    {
      if(this.kitchenOrderList[i].foodItemCode===foodItem.foodItemCode)
      {
        foodItem.selected=false;
        this.kitchenOrderList.splice(i,1);
      }
    }
    this.countItems=this.kitchenOrderList.length;

  }

  submitSelected()
  {
    if(this.kitchenOrderList.length>0)
    {
      const dialogRef = this.dialog.open(ConfirmPopup, {
        width: '400px',maxHeight:'300px',data:{message:"Are you Sure to Request for "+this.countItems+" Items ?",description:"This will Send Request to Inventory for Requested Items"}
      });
  
      dialogRef.afterClosed().subscribe((result:string) => {
      
        
        if(result=='yes')
        {
          var param={'data':this.kitchenOrderList};
          this.kitchenDebitService.newBulkRequest(param).subscribe(data => {
            const dialogRef = this.dialog.open(AlertPopup, {
              width: '400px',maxHeight:'300px',data:{"icon":"success","message":"Request Sent Successfully to Approval"}
            });
            dialogRef.afterClosed().subscribe(data1=>{
            {
            this.ngOnInit();
            }});
          });
         
         
        }
  
    });

    }
    else
    {
      this.openSnackBar('No Items Added to Request');
    }
    
  }

  generateInventoryDebitData(order:KitchenDebitOrderDetails):KitchenInventoryOrders
  {
    let ingredients:KitchenInventoryDebits[]=[];
    for(var i=0;i<order.ingredients.length;i++)
    {
      
      var foodItem=new KitchenInventoryDebits();
      foodItem.branchCode=this.selectedBranchCode;
      foodItem.inventoryItemCode=order.ingredients[i].inventoryItemCode;
      foodItem.inventoryItemName=order.ingredients[i].inventoryItemName;
      foodItem.foodItemCode=order.foodItemCode;
      foodItem.foodItemName=order.foodItemName;
      foodItem.quantity=order.ingredients[i].quantity*order.personCount;
      foodItem.measurementUnit=order.ingredients[i].measurementUnit;
      foodItem.requestedUser=this.authService.currentUserValue.firstName+" "+this.authService.currentUserValue.lastName;
      ingredients.push(foodItem);
    }

     var invDebit=new KitchenInventoryOrders();
     invDebit.branchCode=this.selectedBranchCode;
     invDebit.inventoryCode=this.authService.currentUserValue.role.details;
     invDebit.inventoryName=this.inventoryName;
     invDebit.foodItemCode=order.foodItemCode;
     invDebit.foodItemName=order.foodItemName;
     invDebit.personCount=order.personCount;
     invDebit.kitchendebits=ingredients;
     invDebit.requestedUser=this.authService.currentUserValue.firstName+" "+this.authService.currentUserValue.lastName;
    
   return invDebit;

  }

 

 

}
