import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { api } from '../../../environments/api';
import { catchError, map, tap } from 'rxjs/operators';
import { UserAccount, UserAccountData } from '../../views/bean/UserAccount';
import { AuthenticationService } from '../auth/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class UserAccountService {

  httpOptions;

  formDataHttpOptions = {
    headers: new HttpHeaders({ 'Content-Type': undefined })
  }

  constructor(private http: HttpClient,private authService:AuthenticationService) {
    let httpHeadersContentTypeJson= new HttpHeaders({'Content-Type':'application/json',
    "Authorization":"Basic "+this.authService.currentUserValue.authdata});
    this.httpOptions = {
      headers: httpHeadersContentTypeJson
    };
   }

  save(data: UserAccount): Observable<UserAccountData> {
    return this.http.post<UserAccountData>(api.createUser, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  update(data: UserAccount): Observable<UserAccountData> {
    return this.http.post<UserAccountData>(api.updateUser, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  delete(data: any): Observable<any> {
    return this.http.post<any>(api.deleteUser, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  getAll(): Observable<UserAccountData> {
    return this.http.get<UserAccountData>(api.getAllUser).pipe(catchError(this.handleError));
  }

  block(data: any): Observable<UserAccountData> {
    return this.http.post<UserAccountData>(api.blockUser, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  unblock(data: any): Observable<UserAccountData> {
    return this.http.post<UserAccountData>(api.unblockUser, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  activate(data: any): Observable<UserAccountData> {
    return this.http.post<UserAccountData>(api.activateUser, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  deactivate(data: any): Observable<UserAccountData> {
    return this.http.post<UserAccountData>(api.deactivateUser, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  changePassword(data: any): Observable<UserAccountData> {
    return this.http.post<UserAccountData>(api.changePassword, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  handleError(error: HttpErrorResponse):Observable<any>{
    if(error==undefined||error.error==undefined||error.error.message==undefined)
    {
      alert('Check Your Internet or Please Try Again');
    }
    else
    {
    alert(error.error.message);
    }
    return null;
    }
}
