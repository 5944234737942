import { INavData } from '@coreui/angular';

export const HospitalAdminNavItems: INavData[] = [
  
 

  
  {
    name: 'Feedback',
    url: 'hadmin',
    children: [
      {
        name:'View Feedbacks',
        url: 'feedback/viewfeedback'
      }
    ]
  },
  {
    name: 'NABH Checklists',
    url: 'hadmin',
    children: [
      {
        name:'Report Cooking Temperature',
        url: 'nabhchecklist/reportcookingtemp'
      },
      {
        name:'Report Food Sampling',
        url: 'nabhchecklist/reportfoodsampling'
      },
      {
        name:'Report Freezer Temperature',
        url: 'nabhchecklist/reportfreezertemp'
      },
      {
        name:'Report Kitchen Cleaning',
        url: 'nabhchecklist/reportkitchencleaning'
      },
      {
        name:'Report Personal Hygiene',
        url: 'nabhchecklist/reportpersonalhygiene'
      },
      {
        name:'Report Sensory Evaluation',
        url: 'nabhchecklist/reportsensoryeval'
      },
      {
        name:'Report Chlorine Sanitisation',
        url: 'nabhchecklist/reportchlorine'
      },
      {
        name:'Report Pot Wash',
        url: 'nabhchecklist/reportpotwash'
      }
    ]
  }
];
