import { InventoryItem } from "./Inventory";

export class Menu {
    id: string;
    selected:boolean;
    menuCode: string;
    branchCode:string;
    name: string;
    updatedDate: string;
    createdDate: string;
    validityDate: string;
    locationDetails: string;
    active: boolean;
    createdUsername:string;
    description:string;
    createdName:string;
    updatedUsername: string;
    updatedName: string;
    deleted: boolean;
    category: string;
    itemCount:number; 
    menuItems: any[]
}

export class LinkedMenuBean
{
    day:string;
    menuId:string;
    branchCode:string;
}

export class LinkedFoodItemBean
{
    day:string;
    foodItemId:string;
    branchCode:string;
}



export class MenuData {
    responseCode: boolean;
    message: string;
    responseObject: any[]
}

export class Item{
    id: string;
    selected:boolean;
    branchCode:string;
    menuItemCode: string;
    menuItemName: string;
    menuCode: string;
    menuDescription: string;
    price: number;
    subscidisedPrice: number;
    discount: number;
    createdDate: string;
    updatedDate: string;
    createdUsername: string;
    createdName: string;
    updatedUsername: string;
    updatedName: string;
    active: boolean;
    deleted: boolean;
    foodItemDetails: FoodItemDetials[];
    directsaleitems:DirectSaleItems[];
    autoDebitIngredients:boolean;
    parcel:boolean;
}

export class DirectSaleItems
{
    	
debitValue:number;
inventoryCode:string;
inventoryItemCode:string;
inventoryItemName:string;

}

export class FoodItemDetials{
    id:string;
    count:number;
    foodItem:FoodItem;
}

export class Ingredient{
    id:string;
    branchCode:string;
    foodItemCode: string;
    inventoryItemCode: string;
    inventoryItemName: string;
    quantity: number;
    cost:number;
    totalOutputWeight:number;
    measurementUnit: string;
    createdDate:string;
    updatedDate:string;
}


export class FoodItem{

    selected:boolean;
    id: string;
    foodItemCode: string;
    foodItemName: string;
    unitServingQuantity:number;
    unitServingMeasurementUnit: string;
    outputQuantity:string;
    outputMeasurementUnit: string;
    cookingTime:string;
    outputPersonCount:string;
    expensePrice: number;
    createdDate: string;
    updatedDate: string;
    createdUsername: string;
    createdName: string;
    updatedUsername: string;
    updatedName: string;
    active: boolean;
    deleted: boolean;
    ingredientDetails: Ingredient[];
}

export class DishIngredient{

    id:string;
	
	dishCode:string;
	
	dishName:string;
	
	languageCode:string;
	
	ingredients:string;
	
    createdDate:string;

	updatedDate:string;
	
    createdUsername:string;
    createdByName:string;
	updatedByUsername:string;
	updatedByName:string;
}


export class DishPreperationStep{

   id: string;
	dishCode: string;
	dishName: string;
	
	languageCode: string;
	stepNo: number;
	details: string;
	
	createdDate: string;
	
	 updatedDate: string;
	createdUsername: string;
createdByName: string;
updatedByUsername: string;
	
	updatedByName: string;
}

export class MenuItemFoodItemLink{
    menuItemCode: string;
    foodItemCode: string;
    foodItemName:String;
    count: number;
}

export class MenuMenuItemLink{
    menuItemName: string;
    menuItemCode: string;
    menuCode:string;

}

export class KitchenDebitOrderDetails{
    foodItemCode: string;
    foodItemName: string;
    personCount:number;
    selected:boolean;
    ingredients:Ingredient[]

}

export class KitchenInventoryOrders
{
   id:string;
	branchCode:string;
    inventoryCode:string;
    inventoryName:string;
    foodItemCode:string;
    foodItemName:string;
    personCount:number;
    requestedUser:string;
    updatedUser:string;
    requestedDate:string;
	updatedDate:string;
    newrequest:boolean;
    approved:boolean;
    rejected:boolean;
	deleted:boolean;
	comments:string;
    kitchendebits:KitchenInventoryDebits[];
}

export class KitchenInventoryDebits
{
id:string;
branchCode:string;
inventoryItemCode:string;
inventoryItemName:string;
foodItemCode:string;
foodItemName:string;
quantity:number;
measurementUnit:string;
requestedUser:string;
updatedUser:string;
requestedDate:string;
updatedDate:string;
newrequest:boolean;
rejected:boolean;
deleted:boolean;
approved:boolean;
comments:string;
}