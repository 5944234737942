import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { KitchenInventoryOrders } from '../../bean/Menu';

@Component({
  selector: 'app-kitchenorderdetails',
  templateUrl: './kitchenorderdetailspopup.html',
})
export class KitchenOrderDetailsPopup implements OnInit {

  comments:string;
 


  constructor(public dialog: MatDialog, public dialogRef: MatDialogRef<KitchenOrderDetailsPopup>,
    @Inject(MAT_DIALOG_DATA) public data:KitchenInventoryOrders){ 

      for(let i=0;i<data.kitchendebits.length;i++)
      {

      if(data.kitchendebits[i].quantity<1)
    {
      data.kitchendebits[i].quantity=data.kitchendebits[i].quantity*1000;
      if(data.kitchendebits[i].measurementUnit.toLowerCase()=='kg')
      {
        data.kitchendebits[i].measurementUnit='GRAMS';
      }
      else if(data.kitchendebits[i].measurementUnit.toLowerCase()=='litres')
      {
        data.kitchendebits[i].measurementUnit='ML';
      }
    }
    }
    
  }
  

  ngOnInit() {

  }




onCloseClick()
{
  this.dialogRef.close(null);

}
}
