import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { InventoryDebitData } from '../../bean/Inventory';

import { MatSnackBar } from '@angular/material';
import { MessageComponent } from '../../Generic/message/message.component';

@Component({
    selector: 'PopupRejectRequest',
    templateUrl: 'rejecttransfer.html',
})
export class PopUpRejectRequest {

   
   comments:String;

   submitted:boolean=false;
   rejectTransferForm: FormGroup;

    constructor(public dialogRef: MatDialogRef<PopUpRejectRequest>,  private snackBar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) public data: InventoryDebitData,private formBuilder: FormBuilder
        )
    {      

        this.rejectTransferForm = this.formBuilder.group({
            
              comments: ['', Validators.required]
          });
       

    }


  
    
    onCancelClick(): void {
        this.dialogRef.close(null);
    }

    openSnackBar(message:string) {
        this.snackBar.openFromComponent(MessageComponent, {
          data: message,
          duration:5*1000
        });
      }
    

    onSubmit(): void {

        this.submitted=true;

        if(this.rejectTransferForm.invalid)
        {
            return;
        }
       
        this.dialogRef.close(this.comments);

    }

   

}