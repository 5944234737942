import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManagementComponent } from "./management/management.component";
import { MaterialModule } from '../../material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InventoryRoutingModule } from "./management.routing";
import { MessageComponent } from "../Generic/message/message.component";
import { LocationsComponent } from './locations/locations.component';
import { TransactionComponent } from './transaction/transaction.component';
import { PopUpCredit} from "./popups/popUpCredit";
import { PopUpDebit } from "./popups/PopUpDebit";
import { PopUpTrans } from "./popups/PopUpTrans";
import { DashboardComponent } from './dashboard/dashboard.component';
import { BalanceDetailsComponent } from './balance-details/balance-details.component';
import { TransferInventory} from "./popups/transferInventory";
import { ReportDetailsComponent } from './report-details/report-details.component';
import { RecoveryReportComponent } from './recovery-report/recovery-report.component';
import { IncomingrequestsComponent } from './incomingrequests/incomingrequests.component'
import { PopupUploadInventoryItemFile } from './popups/PopupUploadInventoryItemFile';
import { PopUpRejectRequest } from './popups/PopupRejectRequest';
import { PopUpAcceptRequest } from './popups/PopupAcceptRequest';
import { CreditReportComponent } from './credit-report/credit-report.component';
import { ItemrecoveryreportComponent } from './itemrecoveryreport/itemrecoveryreport.component';
import { AllsummaryrecoveryreportComponent } from './allsummaryrecoveryreport/allsummaryrecoveryreport.component';
import { FixedassetslistComponent } from './fixedassets/fixedassetslist/fixedassetslist.component';
import { FixedassetitemviewComponent } from './fixedassets/fixedassetitemview/fixedassetitemview.component';
import { FixedAssetDebitPopup } from './popups/fixedassets/FixedAssetDebitPopup';
import { FixedAssetCreditPopup } from './popups/fixedassets/FixedAssetCreditPopup';
import { PopupUploadAssetItemFile } from './popups/PopupUploadAssetItemFile';
import { FixedassetnewreqComponent } from './fixedassets/fixedassetnewreq/fixedassetnewreq.component';
import { FixedAssetExcelUploadPopup } from './popups/fixedassets/FixedAssetExcelUploadPopup';
import { FixedAssetCommentPopup } from './popups/fixedassets/prekotcomments/fixedassetcommentpopup.component';
@NgModule({
  declarations: [ManagementComponent, 
    MessageComponent, 
    LocationsComponent, 
    TransactionComponent,
    PopUpCredit, 
    PopUpDebit,
    PopUpTrans,
    PopUpRejectRequest,
    PopUpAcceptRequest,
    PopupUploadInventoryItemFile,
    TransferInventory,
    DashboardComponent,
    BalanceDetailsComponent,
    ReportDetailsComponent,
    RecoveryReportComponent,
    IncomingrequestsComponent,
    CreditReportComponent,
    ItemrecoveryreportComponent,
    AllsummaryrecoveryreportComponent,
    FixedassetslistComponent,
    FixedAssetDebitPopup,
    FixedAssetCreditPopup,
    FixedassetitemviewComponent,
    FixedAssetExcelUploadPopup,
  FixedassetnewreqComponent,
  FixedAssetCommentPopup],
  imports: [
    CommonModule,
    InventoryRoutingModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule
  ],
  entryComponents: [MessageComponent, PopUpCredit, PopUpDebit,PopUpRejectRequest,PopUpAcceptRequest, PopUpTrans, TransferInventory,PopupUploadInventoryItemFile
  ,AllsummaryrecoveryreportComponent,FixedAssetDebitPopup,FixedAssetCreditPopup,FixedAssetExcelUploadPopup,FixedAssetCommentPopup]
})
export class ManagementModule { }
