import { INavData } from '@coreui/angular';

export const superAdminNavItems: INavData[] = [
  
  {
    name: 'Inventory Management',
    url: 'sadmin',
    children: [
      {
        name: 'Inventory Location',
        url: 'inventory/location'
      },
      {
        name: 'Debit Report',
        url: 'inventory/report'
      },
      {
        name: 'Credit Report',
        url: 'inventory/creditreport'
      },
      {
        name: 'Inventory Items List',
        url: 'inventory/iitemslist'
      },
      {
        name: 'Incoming Transfers',
        url: 'inventory/incoming'
      },
      {
        name: 'Inventory Item Recovery Summary',
        url: 'inventory/invitemrecoverysummary'
      },
      {
        name: 'All Inventory Items Recovery Summary',
        url: 'inventory/allitemsrecoverysummary'
      }
      
    ]
  },
  {
    name: 'Fixed Asset Management',
    url: 'sadmin',
    children: [
      {
        name: 'Fixed Assets List',
        url: 'inventory/assetslist'
      },
      {
        name: 'Pending Approvals',
        url: 'inventory/assetpendingapprovals'
      }
    ]
  },
  {
    name: 'Kitchen Requests',
    url: 'sadmin',
    children: [
      {
        name: 'Manage Inventory Debit Requests',
        url: 'receipedebits'
      }
    ]
  },
  {
    name: 'Dishes & Menu Management',
    url: 'sadmin',
    children: [
      {
        name: 'Manage Menu Categories',
        url: 'menu/mangement'
      },
      {
        name: 'Manage Menu Options',
        url: 'menu/itemMangement'
      },
      {
        name: 'Manage Dishes',
        url: 'menu/foodItem'
      },
      {
        name: 'Manage Dish Preperation Steps',
        url: 'menu/prepsteps'
      },
      {
        name: 'Manage Day Wise Menu Categories',
        url: 'menu/managelinkmenutoday'
      },
      {
        name: 'Manage Day Wise Dishes',
        url: 'menu/managelinkfoodtoday'
      }
    ]
  },
  {
    name: 'Bill Reports',
    url: 'sadmin',
   
    children: [
      {
        name: 'Bill Summary By Date',
        url: 'billreport/summarybydate'
      },
      
      {
        name: 'Coupon Day By Day Summary',
        url: 'billreport/couponsummary'
      },
      {
        name: 'Patient Wise Summary',
        url: 'billreport/patientwisebedordersummary'
      },
      {
        name: 'Bed Order Day By Day Summary',
        url: 'billreport/bedordersummarybydate'
      },
      {
        name: 'Overall Day By Day Summary',
        url: 'billreport/overallsummary'
      },
      {
        name: 'Extra Bed Order Credit Summary',
        url: 'billreport/bedextraordersummary'
      },
      {
        name: 'Doctor/Staff Credit Summary',
        url: 'billreport/doctorcreditsummary'
      },
      {
        name: 'Organization Order Summary',
        url: 'billreport/orgordersummary'
      },
      {
        name: 'Manage Organization Payments',
        url: 'billreport/manageorgpayment'
      },
      {
        name: 'Branch Order Summary',
        url: 'billreport/branchordersummary'
      },
     
      {
        name: 'Partner Order Summary',
        url: 'billreport/partnerordersummary'
      },
      {
        name: 'Logout Summary By Date',
        url: 'billreport/logoutsummarybydate'
      },
       {
        name: 'Menu Items Sales Summary',
        url: 'billreport/menuitemsalesummary'
      },
      {
        name: 'Food Wastage Summary',
        url: 'billreport/foodwastagesummary'
      },
      
  {
    name: 'Comission Report',
    url: 'sadmin',
    children: [
   
      {
        name: 'Summary with Transactions',
        url: 'billreport/comissionconsolidatesummary'
      },
      {
        name: 'Date Wise Report',
        url: 'billreport/comissiondaybydaysummary'
      }
    ]
  }
    ]
  },
  {
    name: 'Doctors System',
    url: 'sadmin',
    children: [
      {
        name: 'View Doctors',
        url: 'doctors/mangement'
      }
    ]
  },
  {
    name: 'Coupon System',
    url: 'sadmin',
    children: [
     /*  {
        name: 'View Coupons',
        url: 'coupons/mangement'
      }, */
      {
        name: 'Add Coupon Logo',
        url: 'coupons/couponlogomanagement'
      },
      {
        name: 'Print Coupons',
        url: 'coupons/groupmangement'
      }
    ]
  },
  {
    name: 'Beds / Hospital Management',
    url: 'sadmin',
    children: [
      {
        name: 'View Bed Details',
        url: 'bed/mangement'
      },
      {
        name: 'Add/View Hospitals',
        url: 'bed/hospital'
      }
    ]
    
  },
  {
    name: 'Employee System',
    url: 'sadmin',
    children: [
      {
        name: 'Add/View Employees',
        url: 'employee/emanagement'
      }
    ]
    
  },
  {
    name: 'External Organization/Outlet Management',
    url: 'sadmin',
    children: [
      {
        name: 'View/Add External Organization',
        url: 'org/manangeorgaccounts'
      },
      {
        name: 'Add/Manage Outlets',
        url: 'branches/createbranch'
      }
    ]
  },
  {
    name: 'Feedback',
    url: 'sadmin',
    children: [
      {
        name:'Add Feedback Question',
        url: 'feedback/addquestion'
      },
      {
        name:'View Feedbacks',
        url: 'feedback/viewfeedback'
      }
    ]
  },
  {
    name: 'NABH Checklists',
    url: 'sadmin',
    children: [
      {
        name:'Report Cooking Temperature',
        url: 'nabhchecklist/reportcookingtemp'
      },
      {
        name:'Report Food Sampling',
        url: 'nabhchecklist/reportfoodsampling'
      },
      {
        name:'Report Freezer Temperature',
        url: 'nabhchecklist/reportfreezertemp'
      },
      {
        name:'Report Kitchen Cleaning',
        url: 'nabhchecklist/reportkitchencleaning'
      },
      {
        name:'Report Personal Hygiene',
        url: 'nabhchecklist/reportpersonalhygiene'
      },
      {
        name:'Report Sensory Evaluation',
        url: 'nabhchecklist/reportsensoryeval'
      },
      {
        name:'Report Chlorine Sanitisation',
        url: 'nabhchecklist/reportchlorine'
      },
      {
        name:'Report Pot Wash',
        url: 'nabhchecklist/reportpotwash'
      }
    ]
  },
  {
    name: 'User Accounts',
    url: 'sadmin',
    children: [
      {
        name: 'Create User Account',
        url: 'useraccount/management'
      }
    ]
  },
  {
    name: 'Expense Managment',
    url: 'sadmin',
    children: [
      {
        name: 'View / Manage Expense',
        url: 'expense/viewexpenses'
      }
      ,
  {
    name: 'View / Manage Pitty Cash',
    url: 'viewpittycash'
  }
    ]
  }
];
