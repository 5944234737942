import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ManagementComponent } from "./management/management.component";
import { LocationsComponent } from "./locations/locations.component";
import { TransactionComponent } from "./transaction/transaction.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { BalanceDetailsComponent } from "./balance-details/balance-details.component";
import { ReportDetailsComponent} from "./report-details/report-details.component";
import { RecoveryReportComponent} from "./recovery-report/recovery-report.component";
import { IncomingrequestsComponent } from './incomingrequests/incomingrequests.component';
import { CreditReportComponent } from './credit-report/credit-report.component';
import { ItemrecoveryreportComponent } from './itemrecoveryreport/itemrecoveryreport.component';
import { AllsummaryrecoveryreportComponent } from './allsummaryrecoveryreport/allsummaryrecoveryreport.component';
import { FixedassetslistComponent } from './fixedassets/fixedassetslist/fixedassetslist.component';
import { FixedassetnewreqComponent } from './fixedassets/fixedassetnewreq/fixedassetnewreq.component';
import { FixedassetitemviewComponent } from './fixedassets/fixedassetitemview/fixedassetitemview.component';
const routes: Routes = [
    {
        path: '',
        component: ManagementComponent,
        data: {
            title: 'Inventory Items'
        }
        
    },
    {
        path: 'iitemslist',
        component: ManagementComponent,
        data: {
            title: 'Inventory Items'
        }
        
    },
    {
        path: 'assetslist',
        component:FixedassetslistComponent,
        data: {
            title: 'Fixed Assets Item List'
        }
    },
    {
        path: 'assetitemview',
        component:FixedassetitemviewComponent,
        data: {
            title: 'Fixed Assets Item View'
        }
    },
    {
        path: 'assetpendingapprovals',
        component:FixedassetnewreqComponent,
        data: {
            title: 'Fixed Assets Item View'
        }
    },
    {
        path: 'location',
        component: LocationsComponent,
        data: {
            title: 'Inventory Locations'
        } 
    },
    {
        path: 'transaction',
        component: TransactionComponent,
        data: {
            title: 'Inventory Transactions'
        }
    },
    {
        path: 'itransfers',
        component: DashboardComponent,
        data: {
            title: 'Outgoing Transfer Requests'
        }
    },
    {
        path: 'incoming',
        component: IncomingrequestsComponent,
        data: {
            title: 'Incoming Transfer Request'
        }
    },
    {
        path: 'balance',
        component: BalanceDetailsComponent,
        data: {
            title: 'Inventory Balance Details'
        }
    },
    {
        path: 'report',
        component: ReportDetailsComponent,
        data: {
            title: 'Debit Report Details'
        }
    },
    {
        path: 'creditreport',
        component: CreditReportComponent,
        data: {
            title: 'Credit Report Details'
        }
    },
    {
        path: 'recovery',
        component: RecoveryReportComponent,
        data: {
            title: 'Item Recovery Details'
        }
    },
    {
        path: 'invitemrecoverysummary',
        component:ItemrecoveryreportComponent,
        data: {
            title: 'Item Wise Recovery Summary Report'
        }
    },
    {
        path: 'allitemsrecoverysummary',
        component:AllsummaryrecoveryreportComponent,
        data: {
            title: 'All Inventory Item Recovery Report'
        }
    }
    
    
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class InventoryRoutingModule { }
