import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';import { api } from '../../../environments/api';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from '../auth/authentication.service';
import { APIResponse, AssetItem, AssetItemTransaction } from '../../views/bean/Assets';

@Injectable({
  providedIn: 'root'
})
export class KitchenDebitService {

  httpOptions;

  formDataHttpOptions={
    headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
  }

  constructor(private http: HttpClient,private authService:AuthenticationService) {
    let httpHeadersContentTypeJson= new HttpHeaders({'Content-Type':'application/json',
    "Authorization":"Basic "+this.authService.currentUserValue.authdata});
    this.httpOptions = {
      headers: httpHeadersContentTypeJson
    };
   }

  newRequest(data: any): Observable<any> {
    return this.http.post<any>(api.kitchendebitsnewrequest, data, this.httpOptions);
  }

  newBulkRequest(data: any): Observable<any> {
    return this.http.post<any>(api.kitchendebitsnewbulkrequest, data, this.httpOptions);
  }

  acceptRequest(data: any): Observable<APIResponse> {
    return this.http.post<any>(api.kitchendebitsacceptrequest, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  rejectRequest(data: any): Observable<APIResponse> {
    return this.http.post<any>(api.kitchendebitsrejectrequest, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  deleteRequest(data: any): Observable<APIResponse> {
    return this.http.post<any>(api.kitchendebitsdeleterequest, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  fetchAllNew(): Observable<APIResponse> {
    return this.http.get<any>(api.kitchendebitsfetchallnew, this.httpOptions).pipe(catchError(this.handleError));
  }

  fetchAllNewBranchCode(data: any): Observable<APIResponse> {
    return this.http.post<any>(api.kitchendebitsfetchallnewbranchcode,data, this.httpOptions).pipe(catchError(this.handleError));
  }
 
  fetchAllNewRejected(): Observable<APIResponse> {
    return this.http.get<any>(api.kitchendebitsfetchallrejected, this.httpOptions).pipe(catchError(this.handleError));
  }

  fetchAllRejectedBranchCode(data: any): Observable<APIResponse> {
    return this.http.post<any>(api.kitchendebitsfetchallrejectedbranchcode,data, this.httpOptions).pipe(catchError(this.handleError));
  }

  fetchAllByDateRangeBranchCode(data: any): Observable<APIResponse> {
    return this.http.post<any>(api.kitchendebitsfetchallbranchcodeDateRange,data, this.httpOptions).pipe(catchError(this.handleError));
  }

  fetchAllByDateRange(data: any): Observable<APIResponse> {
    return this.http.post<any>(api.kitchendebitsfetchallDateRange,data, this.httpOptions).pipe(catchError(this.handleError));
  }

  

  handleError(error: HttpErrorResponse):Observable<any>{
  
    if(error==undefined||error.error==undefined||error.error.message==undefined)
    {
    
      alert('Check Your Internet or Please Try Again');
    }
    else
    {
    alert(error.error.message);
    }
    return null;
    }
}
