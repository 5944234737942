import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { ManagementService } from '../../../service/inventory/inventory.service';
import { InventoryItemConsumption, InventoryLocations } from '../../bean/Inventory';
import { MessageComponent } from '../../Generic/message/message.component';

@Component({
  selector: 'app-allsummaryrecoveryreport',
  templateUrl: './allsummaryrecoveryreport.component.html',
  styleUrls: ['./allsummaryrecoveryreport.component.css']
})
export class AllsummaryrecoveryreportComponent implements OnInit {

  fromDate: string;
  toDate: string;
  allInvItemConsumationData: InventoryItemConsumption[] = [];
  actions: string[] = ['Update', 'Activate', 'De-Activate', 'Delete'];

  selectedSearchType:string;
  searchTypes: string[] = ['Overall Summary', 'Summary By Date'];
  isOverallSummary:boolean;

  selectedLocationIdIndex:number;
  selectedInventoryName:string
  selectedInventoryCode:string
  private inventoryLocations:InventoryLocations[];



  constructor(private mService: ManagementService, 
    private _snackBar: MatSnackBar,
    public dialog: MatDialog) {

  }

 


  ngOnInit() {


    var currentDate=new Date();
    this.fromDate= formatDate(currentDate, 'yyyy-MM-dd', 'en-US');
    this.toDate= formatDate(currentDate, 'yyyy-MM-dd', 'en-US');

    this.isOverallSummary=true;
    this.selectedSearchType=this.searchTypes[0];

    this.selectedLocationIdIndex=0;
    
    this.fetchAllInventoryLocations();
  
  }

  onLocationSelected()
  {
    if(this.selectedLocationIdIndex!=-1)
    {
      if(this.selectedLocationIdIndex>0)
    {
        this.selectedInventoryCode=this.inventoryLocations[this.selectedLocationIdIndex-1].inventoryCode;
        this.selectedInventoryName=this.inventoryLocations[this.selectedLocationIdIndex-1].inventoryName;
    }
    
    }
   
  }

  fetchAllInventoryLocations()
  {
    this.mService.getAllActiveInventoryLocations().subscribe(data => {
      console.log(data.responseObject);
      this.inventoryLocations = data.responseObject;
    });
  }

  onSearchOptionChange()
  {
    if(this.selectedSearchType==this.searchTypes[0])
    {
      this.isOverallSummary=true;
    }
    else
    {
      this.isOverallSummary=false;
    }
   this.selectedSearchType=this.isOverallSummary?this.searchTypes[0]:this.searchTypes[1];
  }

 


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
 
  }



  openSnackBar(message: string) {
    this._snackBar.openFromComponent(MessageComponent, {
      data: message,
      duration: 5 * 1000
    });
  }


 


  fetchAllSummary()
  {
  
    if(this.selectedLocationIdIndex==-1)
    {
      this.openSnackBar('Select Inventory');
      return;
    }

    if(this.selectedLocationIdIndex==0)
    {
    this.mService.fetchAllInventoryItemsRecoveryAnyRange().
      subscribe(result => {
        this.allInvItemConsumationData = result.responseObject as unknown as InventoryItemConsumption[];
        console.log(this. allInvItemConsumationData);
    
      });
    }
    else
    {
      this.fetchAllSummaryInventoryLocation();
    }
  }

  fetchAllSummaryInventoryLocation()
  {
    var data: any = {
      "inventoryCode": this.selectedInventoryCode,
    }
    this.mService.fetchAllInventoryItemsRecoveryAnyRangeInventoryLocation(data).
      subscribe(result => {
        this.allInvItemConsumationData = result.responseObject as unknown as InventoryItemConsumption[];
        console.log(this. allInvItemConsumationData);
    
      });
  }

  searchByDate() {

    if(this.selectedLocationIdIndex==-1)
    {
      this.openSnackBar('Select Inventory');
      return;
    }
   
    if(this.selectedLocationIdIndex==0)
    {
    var data: any = {
      "startDate": this.fromDate,
      "endDate": this.toDate
    }
    this.mService.fetchAllInventoryItemsRecoveryDateRange(data).subscribe(result => {

      this.allInvItemConsumationData = result.responseObject as unknown as InventoryItemConsumption[];
      console.log(this. allInvItemConsumationData);
    });
  }
  else
  {
    this. searchByDateInventoryCode();
  }

  }

  searchByDateInventoryCode() {

   
    var data: any = {
      "inventoryCode":this.selectedInventoryCode,
      "startDate": this.fromDate,
      "endDate": this.toDate
    }
    this.mService.fetchAllInventoryItemsRecoveryDateRangeInventoryLocation(data).subscribe(result => {

      this.allInvItemConsumationData = result.responseObject as unknown as InventoryItemConsumption[];
      console.log(this. allInvItemConsumationData);
    });

  }

}
