import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Bed, BedData, BedPayment, BedOrderDetails, BedPaymentResponse, BulkBedOrder, BedOrderTransactionVO } from '../../views/bean/Bed';
import { api } from '../../../environments/api';
import { catchError } from 'rxjs/operators';
import { WalkIN } from '../../views/bean/Company';
import { AuthenticationService } from '../auth/authentication.service';


@Injectable({
  providedIn: 'root'
})
export class BedOrderService {

  

  httpOptions;

  formDataHttpOptions = {
    headers: new HttpHeaders({ 'Content-Type': undefined })
  }

 

  constructor(private http: HttpClient,private authService:AuthenticationService) {
  
    let httpHeadersContentTypeJson= new HttpHeaders({'Content-Type':'application/json',
    "Authorization":"Basic "+this.authService.currentUserValue.authdata});
    this.httpOptions = {
      headers: httpHeadersContentTypeJson
    };
   }

  saveData(data: Bed): Observable<BedData> {
    return this.http.post<BedData>(api.createBed, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  addBedAdvancePayment(data: WalkIN): Observable<BedPaymentResponse> {
    return this.http.post<BedPaymentResponse>(api.addExtraBedOrder, data, this.httpOptions).pipe(catchError(this.handleAndThrowError));
  }

  addBulkBedPayment(data: BulkBedOrder): Observable<BedPaymentResponse> {
    return this.http.post<BedPaymentResponse>(api.addBulkBedOrder, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  assignBedMenu(data: any): Observable<BedPaymentResponse> {
    return this.http.post<BedPaymentResponse>(api.assignbedmenu, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  /*  getDoctosData(data: string): Observable<BedData> {
     return this.http.post<BedData>(api.getAllDoctorById, data, this.httpOptions);
   } */

  /*  updateData(data: any): Observable<BedData> {
     return this.http.put<BedData>(api.updateDoctorCredit, data, this.httpOptions);
   } */
   

  deleteData(data: any): Observable<any> {
    return this.http.post<any>(api.deleteCoupons, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  getAllActiveBedOrdersByHospitalId(data:any): Observable<BedData> {
    return this.http.post<any>(api. fetchActiveBedOrdersbyhospitalid, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  getAssignedBedMenu(): Observable<any> {
    return this.http.get<any>(api.fetchAssignedBedMenu, this.httpOptions).pipe(catchError(this.handleError));
  }

  getAssignedBedMenuBranchCode(data:any): Observable<any> {
    return this.http.post<any>(api.fetchAssignedBedMenuBranchCode,data, this.httpOptions).pipe(catchError(this.handleError));
  }

  getAllActiveData(): Observable<BedData> {
    return this.http.get<BedData>(api.getAllActiveBed, this.httpOptions).pipe(catchError(this.handleError));
  }

  getAllActiveBedOrders(): Observable<BedData> {
    return this.http.get<BedData>(api.getAllActiveBedOrders, this.httpOptions).pipe(catchError(this.handleError));
  }

  getAllActiveBedOrdersByBranchCode(data:any): Observable<BedData> {
    return this.http.post<BedData>(api.getAllActiveBedOrdersByBranchCode,data, this.httpOptions).pipe(catchError(this.handleError));
  }

  
  getAll(): Observable<BedData> {
    return this.http.get<BedData>(api.getAllBed, this.httpOptions).pipe(catchError(this.handleError));
  }

  getAllByHospitalId(data:any): Observable<BedData> {
    return this.http.post<any>(api.getAllBedByHospitalId,data, this.httpOptions).pipe(catchError(this.handleError));
  }

  getAllActiveByHospitalId(data:any): Observable<BedData> {
    return this.http.post<any>(api.getAllActiveBedByHospitalId,data, this.httpOptions).pipe(catchError(this.handleError));
  }

  getBedOrderByBedNo(data:any): Observable<BedData> {
    return this.http.post<any>(api.getByBedNo,data, this.httpOptions).pipe(catchError(this.handleError));
  }

  sendBedOrderSummaryReportAsEmail(data:any): Observable<BedData> {
    return this.http.post<any>(api. sendBedExtraOrderSummaryReportAsEmail,data, this.httpOptions).pipe(catchError(this.handleError));
  }

  addNewBedPatient(data:BedOrderDetails): Observable<BedData> {
    return this.http.post<BedData>(api.addBedPatient,data, this.httpOptions).pipe(catchError(this.handleError));
  }

  editPatientDetails(data:BedOrderTransactionVO): Observable<BedData> {
    return this.http.post<BedData>(api.updateBedPatient,data, this.httpOptions).pipe(catchError(this.handleError));
  }

  addBedPayment(data:BedPayment): Observable<BedData> {
    return this.http.post<BedData>(api.addBedPayment,data, this.httpOptions).pipe(catchError(this.handleError));
  }

  activate(data: any): Observable<BedData> {
      return this.http.post<BedData>(api.activateBed, data, this.httpOptions).pipe(catchError(this.handleError));
    }
   
  deactivate(data: any): Observable<BedData> {
    return this.http.post<BedData>(api.deActivateBed, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  discharge(data: any): Observable<BedData> {
    return this.http.post<BedData>(api.discharge, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  deleteBedTransaction(data:any): Observable<any> {
      return this.http.post<any>(api.deleteBedOrderTransaction,data, this.httpOptions).pipe(catchError(this.handleError));
    
    }

    deleteBedExtraOrderTransaction(data:any): Observable<any> {
      return this.http.post<any>(api.deleteBedExtraOrderTransaction,data, this.httpOptions).pipe(catchError(this.handleError));
    
    }

  handleError(error: HttpErrorResponse):Observable<any>{
    return throwError(error);
    }

    handleAndThrowError(error: HttpErrorResponse):Observable<any>{
      return throwError(error);
      }
}

