import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { InventoryTransData, InventoryItem, InventoryLocations } from '../../bean/Inventory';
import { ManagementService } from '../../../service/inventory/inventory.service';
import { startWith, map } from 'rxjs/operators';

@Component({
    selector: 'PopUpTrans',
    templateUrl: 'transfer.html',
})
export class PopUpTrans {

    inventoryLocArray:InventoryLocations[];
    myControl = new FormControl();
    filteredOptions: Observable<string[]>;

    selectedInventoryCode:string;

    inventoryTransferForm: FormGroup;

    submitted:boolean;

    constructor(private formBuilder: FormBuilder,public dialogRef: MatDialogRef<PopUpTrans>,
        @Inject(MAT_DIALOG_DATA) public data: InventoryTransData,
        private mService: ManagementService
        )
    {

        
     
    }

    ngOnInit()
    {
        this.submitted=false;
        this.inventoryLocArray=[];
        this.selectedInventoryCode="None";

        this.inventoryTransferForm = this.formBuilder.group({
            toInventoryCode: ['', Validators.required],
            quantity: ['', Validators.required],
            transferComments: ['',Validators.required],
         
        });
       

        this.mService.getAllInventoryLocations().subscribe(data=>{
            this.inventoryLocArray = data.responseObject as InventoryLocations[];
          
   
            console.log(this.inventoryLocArray);
           });


    }
    
    onNoClick(): void {
        this.dialogRef.close(null);
    }

    onSubmit()
    {
        this.submitted=true;

        if(this.inventoryTransferForm.invalid||this.selectedInventoryCode=="None")
        {
            return;
        }

        this.data.toInventoryCode=this.selectedInventoryCode;
        this.dialogRef.close(this.data);

    }

   

}