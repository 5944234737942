import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Coupons,CouponData, CouponDataResponse, CouponInput, CouponLogo } from '../../views/bean/Coupon';
import { api } from '../../../environments/api';
import { catchError, map, tap } from 'rxjs/operators';
import { AuthenticationService } from '../auth/authentication.service';
import { CouponGroupDetails } from '../../views/bean/CouponGroupDetails';

@Injectable({
  providedIn: 'root'
})
export class CouponServiceService {

  selectedCouponLogo:CouponLogo;
  selectedCouponType:string;

  getSelectedCouponType():string
  {
    return this.selectedCouponType;
  }

  getSelectedCouponLogo():CouponLogo
  {
    return this.selectedCouponLogo;
  }

  setSelectedCouponLogo(item:CouponLogo)
  {
    this.selectedCouponLogo=item;
  }

  couponGroupDetails:CouponGroupDetails;

  getCouponGroupDetails():CouponGroupDetails
  {
    return this.couponGroupDetails;
  }

  setSelectedCouponType(data:string):string
  {
    return this.selectedCouponType=data;
  }

  setCouponGroupDetails(couponDetails:CouponGroupDetails)
  {
    this.couponGroupDetails=couponDetails;
  }

 

 httpOptions;
 

  constructor(private http: HttpClient,private authService:AuthenticationService) {
    let httpHeadersContentTypeJson= new HttpHeaders({'Content-Type':'application/json',
    "Authorization":"Basic "+this.authService.currentUserValue.authdata});
    this.httpOptions = {
      headers: httpHeadersContentTypeJson
    };
   }

  saveData(data: CouponInput): Observable<CouponData> {
    return this.http.post<CouponData>(api.createCoupons, data, this.httpOptions).pipe(catchError(this.handleError));
  }

 /*  getDoctosData(data: string): Observable<CouponData> {
    return this.http.post<CouponData>(api.getAllDoctorById, data, this.httpOptions);
  } */

 /*  updateData(data: any): Observable<CouponData> {
    return this.http.put<CouponData>(api.updateDoctorCredit, data, this.httpOptions);
  } */

  deleteData(data: any): Observable<any> {
    return this.http.post<any>(api.deleteCoupons, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  getAllActiveData(): Observable<CouponData> {
    return this.http.get<CouponData>(api.getAllActiveCoupons).pipe(catchError(this.handleError));
  }

  getAll(): Observable<CouponData> {
    return this.http.get<CouponData>(api.getAllCoupons).pipe(catchError(this.handleError));
  }

  getAllgnt(data:any): Observable<CouponData> {
    return this.http.post<CouponData>(api.getAllCouponsGNT,data,this.httpOptions).pipe(catchError(this.handleError));
  }


  getGroupedData(): Observable<CouponData> {
    return this.http.get<CouponData>(api.fetchGroupCoupon).pipe(catchError(this.handleError));
  }

/*   activateItem(data: FormData): Observable<CouponData> {
    return this.http.post<CouponData>(api.activateInventoryItem, data, this.httpOptions);
  }
 */

activate(data: any): Observable<CouponData> {
  return this.http.post<CouponData>(api.activateCoupons, data, this.httpOptions).pipe(catchError(this.handleError));
}

  deactivate(data: any): Observable<CouponData> {
    return this.http.post<CouponData>(api.deActivateCoupons, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  delete(data: any): Observable<CouponData> {
    return this.http.post<CouponData>(api.deleteCoupons, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  activateCouponGroup(data: any): Observable<CouponData> {
    return this.http.post<CouponData>(api.activateCouponGroup, data, this.httpOptions).pipe(catchError(this.handleError));
  }
  
    deactivateCouponGroup(data: any): Observable<CouponData> {
      return this.http.post<CouponData>(api.deActivateCouponGroup, data, this.httpOptions).pipe(catchError(this.handleError));
    }
  
    deleteCouponGroup(data: any): Observable<CouponData> {
      return this.http.post<CouponData>(api.deleteCouponGroup, data, this.httpOptions).pipe(catchError(this.handleError));
    }

  findByCouponNo(data: any): Observable<CouponData> {
    return this.http.post<CouponData>(api.findByCoupons, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  findByCouponPrice(data: any): Observable<CouponDataResponse> {
    return this.http
    .post<CouponData>(api.findByPrice, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  findByCouponNumber(data: any): Observable<CouponDataResponse> {
    return this.http
    .post<CouponData>(api.findactivebyCouponno, data, this.httpOptions).pipe(catchError(this.handleError));
  }

  findDistinctCouponGroup(): Observable<CouponData> {
    return this.http
    .get<CouponData>(api.fetchDistictCoupons,this.httpOptions).pipe(catchError(this.handleError));
  }

  findDistinctCouponGroupBranchCode(data:any): Observable<CouponData> {
    return this.http
    .post<CouponData>(api.fetchDistictCouponsbranchcode,data,this.httpOptions).pipe(catchError(this.handleError));
  }

  fetchByGroupDetails(data:any): Observable<CouponData> {
    return this.http
    .post<CouponData>(api.fetchByGroupDetails,data,this.httpOptions).pipe(catchError(this.handleError));
  }

  uploadCouponLogo(fileToUpload: File,logoName:any): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    formData.append('logoName',logoName);
    return this.http.post<any>(api.uploadCouponLogo, formData).pipe(catchError(this.handleError));
}

deleteCouponLogo(data:any): Observable<any> {
  return this.http
  .post<any>(api.deleteCouponLogo,data,this.httpOptions).pipe(catchError(this.handleError));
}

fetchAllCouponLogos(): Observable<any> {
  return this.http
  .post<any>(api.fetchAllCouponLogo,this.httpOptions).pipe(catchError(this.handleError));
}

fetchCouponLogoBase64ById(data:any): Observable<any> {
  return this.http
  .post<any>(api.fetchCouponLogoBase64ById,data,this.httpOptions).pipe(catchError(this.handleError));
}

  handleError(error: HttpErrorResponse):Observable<any>{

    if(error==undefined)
    {
      alert('Check Your Internet or Please Try Again');
    }
    else
    {
    alert(error);
    }
    return null;
    }
  

}
