import { Component, OnInit} from '@angular/core';
import { ManagementService } from '../../../service/inventory/inventory.service';
import { InventoryCreditData, InventoryDebitData, InventoryItem, InventoryLocations, TransType } from '../../bean/Inventory';
import { MatDialog} from '@angular/material';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessageComponent } from "../../Generic/message/message.component";
import { Router } from '@angular/router';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { AuthenticationService } from '../../../service/auth/authentication.service';
import { PopupUploadInventoryItemFile } from '../popups/PopupUploadInventoryItemFile';
import { api } from '../../../../environments/api';
import { PopUpCredit } from '../popups/popUpCredit';
import { PopUpDebit } from '../popups/PopUpDebit';
import { threadId } from 'worker_threads';
@Component({
  selector: 'app-management',
  templateUrl: './management.component.html',
  styleUrls: ['./management.component.css']
})
export class ManagementComponent implements OnInit {

  private currentInventory:InventoryLocations;

  inventoryCode:string='inventory1';

  submitted:boolean=false;

  measurementUnitList:string[]=["None","LITRES",'KG','UNIT COUNT'];

  selectedMeasurementUnit:string=null;

  selectedCategory:string;

  categoryArray:string[]=["None","VEGETABLES","GROCERY","FLOUR","SPICES","MASALA POWDER","SAUCE","CLEANING ITEMS","PACKING ITEMS","CHOCOLATE"];

  
  locationSelected:boolean;
  selectedLocationIdIndex:number;
  private inventoryLocations:InventoryLocations[];
  
  constructor(private formBuilder: FormBuilder,private authService:AuthenticationService,private mService: ManagementService, 
              private _snackBar: MatSnackBar,public dialog: MatDialog,
              private  _router: Router) { 

     
    this.inventoryCode=this.authService.currentUserValue.role.details;

    this.superAdmin=false;
    this.superVisor=false;
    this.inventoryAdmin=false;
   
    if(this.authService.currentUserValue.role.role=='ROLE_SUPER_ADMIN')
    {
      
       this.superAdmin=true;
    }
    else if(this.authService.currentUserValue.role.role=='ROLE_SUPERVISOR')
    {
    this.superVisor=true;
    }
    else if(this.authService.currentUserValue.role.role=='ROLE_INVENTORY_ADMIN')
    {
    this.inventoryAdmin=true;
    }
  }

  addItems: boolean = false;

  allInvItemData: InventoryItem[] = [];
  tempInvItemData: InventoryItem[] = [];
  updateTyep: boolean = false;

  actions:string[]=['Update','Activate','De-Activate','Delete'];


  model: InventoryItem = new InventoryItem();

  urlData:string;

  superAdmin:boolean=false;
  superVisor:boolean=false;
  inventoryAdmin:boolean=false;

  inventoryItemForm: FormGroup;

  debitData:InventoryDebitData;
  creditData:InventoryCreditData;
  invData: InventoryItem=new InventoryItem();


  ngOnInit() {

    this.selectedLocationIdIndex=0;

    this.selectedCategory=='None';
     this.selectedMeasurementUnit=='None';

    this.inventoryItemForm = this.formBuilder.group({
      name: ['', Validators.required],
      itemCode: ['', Validators.required],
      category:['',Validators.required],
      measurementUnit: ['', Validators.required]
  });

  this.submitted=false;
  this.inventoryItemForm.reset();

  this.mService.getAllActiveInventoryLocations().subscribe(data => {
    console.log(data.responseObject);
    this.inventoryLocations = data.responseObject;
  });

  

  if(this.authService.currentUserValue.role.role=="ROLE_SUPER_ADMIN"||this.authService.currentUserValue.role.role=="ROLE_SUPERVISOR")
  {
    this.fetchAllInventoryItems();
  }
  else  if(this.authService.currentUserValue.role.role=="ROLE_INVENTORY_ADMIN")
  {
   
    this.fetchAllInventoryItemsByInventoryCode();
  }

    this.addItems = false;
    this.updateTyep = false;
    this.model = new InventoryItem();
    this.model.category='None';
    this.model.measurementUnit='None';
    this.model.disableDirectDebit=false;
    
  }

fetchAllInventoryItems()
{
  this.mService.getAllInventoryItems().subscribe(data => {
    this.allInvItemData = data.responseObject;
    this.tempInvItemData=data.responseObject;
    console.log(this.allInvItemData);

    for(let i=0;i<this.allInvItemData.length;i++)
    {
      if(this.allInvItemData[i].totalQuantity<1)
    {
   
      if(this.allInvItemData[i].measurementUnit.toLowerCase()=='kg')
      {
        this.allInvItemData[i].totalQuantity=this.allInvItemData[i].totalQuantity*1000;
        this.allInvItemData[i].measurementUnit='GRAMS';
      }
      else if(this.allInvItemData[i].measurementUnit.toLowerCase()=='litres')
      {
        this.allInvItemData[i].totalQuantity=this.allInvItemData[i].totalQuantity*1000;
        this.allInvItemData[i].measurementUnit='ML';
      }
    }
  }

    this.tempInvItemData=this.allInvItemData;

      if(this.currentInventory !== undefined )
        {
          ///api need to be added
          console.log("api need to beadded");
        }
  });
}

fetchAllInventoryItemsByInventoryCode()
{
  var param={"inventoryCode":this.inventoryCode};
  this.mService.getAllInventoryItemsByInventoryCode(param).subscribe(data => {
    this.allInvItemData = data.responseObject;
   
    console.log("api need to beadded");
    console.log(data);

    for(let i=0;i<this.allInvItemData.length;i++)
    {
      if(this.allInvItemData[i].totalQuantity<1)
    {
   
      if(this.allInvItemData[i].measurementUnit.toLowerCase()=='kg')
      {
        this.allInvItemData[i].totalQuantity=this.allInvItemData[i].totalQuantity*1000;
        this.allInvItemData[i].measurementUnit='GRAMS';
      }
      else if(this.allInvItemData[i].measurementUnit.toLowerCase()=='litres')
      {
        this.allInvItemData[i].totalQuantity=this.allInvItemData[i].totalQuantity*1000;
        this.allInvItemData[i].measurementUnit='ML';
      }
    }

    this.tempInvItemData=this.allInvItemData;
    

    }

      if(this.currentInventory !== undefined )
        {
          ///api need to be added
          console.log("api need to beadded");
        }
  


   
  });
}

  onLocationSelected()
  {
   
    this.locationSelected=false;
    if(this.selectedLocationIdIndex!=-1)
    {
      if(this.selectedLocationIdIndex>0)
      {
        this.inventoryCode=this.inventoryLocations[this.selectedLocationIdIndex-1].inventoryCode;
      }
    this.locationSelected=true;
    }
   
  }

  search()
  {
    this.allInvItemData=[];
    this.tempInvItemData=[];
   
    if(this.selectedLocationIdIndex==0)
    {
      console.log('Search All Location');
      this.fetchAllInventoryItems();
    }
    else
    {
     console.log('Search All Inventory Reports='+this.inventoryCode);
    this.fetchAllInventoryItemsByInventoryCode();
    }
  }

  addItem() {
    this.addItems = !this.addItems;
    this.updateTyep = false;
    this.model = new InventoryItem();
  }

  update(data: InventoryItem) {
    this.addItems = !this.addItems;
    this.updateTyep = true;
    console.log(data);
    /* this.mService.getMenuData(data).subscribe(data => {
      this.model = data;
    }); */
    this.model=data;
  }


  deactivate(data:InventoryItem)
  {
 
    this.mService.deactivateInventoryItem(data).subscribe(result=>{
      this.openSnackBar(result.message);
      this.ngOnInit();
    });

  }




  activate(data:InventoryItem)
  {
   
    this.mService.activateInventoryItem(data).subscribe(result => {
      this.openSnackBar(result.message);
      this.ngOnInit();
    });
  }


  delete(data: InventoryItem) {
    this.mService.deleteInventoryItem(data).subscribe(result => {
      this.openSnackBar(result.message);
      this.ngOnInit();
    });
  }




  saveNewMenuItem() {

    this.submitted=true;
    if(this.inventoryItemForm.invalid || this.selectedCategory=='None' || this.selectedMeasurementUnit=='None')
    {
      return;
    }

  if (this.updateTyep) {
    this.mService.updateInventoryItem(this.model).subscribe(data => {
        this.openSnackBar("Data Updated Successfully ....!!!!");
        this.ngOnInit();
      });
    }

    else
    {
    this.mService.saveInventoryITem(this.model).subscribe(data => {
      
      this.openSnackBar("Data Saved Successfully ....!!!!");
      this.ngOnInit()
    }
    );
  } 
  }



  applyFilter(event: Event) {

    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    var filterResult:InventoryItem[] = this.tempInvItemData.filter(tran => 
      tran.name.toLowerCase().startsWith(filterValue) ||tran.itemCode.toLowerCase().startsWith(filterValue)
      ||tran.category.toLowerCase().startsWith(filterValue));
  
      if(filterValue=='')
      {
        this. allInvItemData=this.tempInvItemData;
      }
      else
      {
      if(filterResult.length>0)
      {
  this.allInvItemData=filterResult;
      }
      else
      {
        this. allInvItemData=this.tempInvItemData;
      }
    }
   
  }



  openSnackBar(message:string) {
    this._snackBar.openFromComponent(MessageComponent, {
      data: message,
      duration:5*1000
    });
  }


  transcations(data:InventoryItem)
  {
    this.mService.setInventoryItem(data);
    this.urlData=this._router.url;
    if(this.superAdmin)
    {
     
      this._router.navigate(['/superadmin/inventory/transaction'], { state:data});
    }
    else if(this.superVisor)
    {
     
      this._router.navigate(['/supervisor/inventory/transaction'], { state:data});
    }
    else
    {
   
      this._router.navigate(['/iadmin/inventory/transaction'], { state:data});
    }
  
  }


  balanceDetails(data: InventoryItem) {
    this.mService.setInventoryItem(data);
    this.urlData=this._router.url;
    if(this.superAdmin)
    {
      
      this._router.navigate(['/superadmin/inventory/balance'], { state:data});
    }
    else if(this.superVisor)
    {
     
    this._router.navigate(['/supervisor/inventory/balance'], { state: data });
    }
  }



  outgoingTransferPage()
  {
  
    this.urlData=this._router.url;
    if(this.superAdmin)
    {
      
      this._router.navigate(['/superadmin/inventory/itransfers']);
    }
    else if(this.superVisor)
    {
     
    this._router.navigate(['/supervisor/inventory/itransfers']);
    }
    else
    {
      this._router.navigate(['/iadmin/inventory/itransfers']);
    }
  }

  incomingTransferPage()
  {
    this.urlData=this._router.url;
    if(this.superAdmin)
    {
      
      this._router.navigate(['/superadmin/inventory/incoming']);
    }
    else if(this.superVisor)
    {
     
    this._router.navigate(['/supervisor/inventory/incoming']);
    }
    else
    {
      this._router.navigate(['/iadmin/inventory/incoming']);
    }
  }

  itemRevoeryDetails(data:InventoryItem)
  {
    this.mService.setInventoryItem(data);
    //this.urlData=this._router.url.substr(0,this._router.url.lastIndexOf("/"));
    this.urlData=this._router.url;
    if(this.superAdmin)
    {
     
  this._router.navigate(['/superadmin/inventory/recovery'], { state: data });
  }
  else if(this.superVisor)
  {
    this._router.navigate(['/supervisor/inventory/recovery'], { state: data });
  }
  else
  {
   
    this._router.navigate(['/iadmin/inventory/recovery'], { state: data });
  }
  }


  showFileUploadPopup()
  {
    const dialogRef = this.dialog.open(PopupUploadInventoryItemFile, {
      width: '450px',
      data:null
    });
  }

  downloadInventoryItemExcel()
  {
    //this.mService.downloadInventoryItemExcel();
    window.open(api.downloadInventoryItemExcel, "_blank");
  }

  openCreditDialog(invItem:InventoryItem)
  {

    this.creditData=new InventoryCreditData();
    this.creditData.itemCode = invItem.itemCode;
    this.creditData.inventoryCode=this.authService.currentUserValue.role.details;
    this.creditData.type = TransType.CREDIT.toString();
    this.creditData.totalQuantity = 0;
    const dialogRef = this.dialog.open(PopUpCredit, {
      maxWidth: '600px', maxHeight:'600px',
      data: this.creditData
    });

    dialogRef.afterClosed().subscribe(result => {

      console.log('The dialog was closed');
      this.creditData = result;
      this.creditData.itemCode = invItem.itemCode;
    this.creditData.inventoryCode=this.authService.currentUserValue.role.details;
    this.creditData.createdUser=this.authService.currentUserValue.firstName+" "+this.authService.currentUserValue.lastName;
      console.log( this.creditData);
      if (result == undefined || result.units == undefined || result.units == null ) return;

      this.creditData.totalQuantity = (this.creditData.units * this.creditData.unitQuantity);

      this.mService.addtransaction(this.creditData).subscribe(value => {
        this.openSnackBar(value.message+"....!!!!");
        this.ngOnInit();
        error=>{
          this.openSnackBar(value.message + "....!!!!");
        }
      });
    });

   
  }

  openDebitDialog(invItem:InventoryItem)
  {
    this.invData=new InventoryItem();
    this.debitData = new InventoryDebitData();
    this.debitData.inventoryCode=this.authService.currentUserValue.role.details;
    this.debitData.itemCode = invItem.itemCode;
    this.debitData.type = TransType.DEBIT.toString();
    console.log("Balance="+this.invData.balance);
    this.debitData.totalQuantity =this.invData.totalQuantity;
    this.debitData.units=this.invData.units;
   // console.log("....credit");
    const dialogRef = this.dialog.open(PopUpDebit, {
      maxWidth: '600px', maxHeight:'600px',
      data: this.debitData
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');

      if (result == undefined ||result.units == undefined || result.units == null) return;


      this.debitData = result;
      this.debitData.inventoryCode=this.authService.currentUserValue.role.details;
      this.debitData.createdUser=this.authService.currentUserValue.firstName+" "+this.authService.currentUserValue.lastName;
      this.debitData.itemCode = invItem.itemCode;
      this.debitData.totalQuantity = this.debitData.totalQuantity;
      console.log(this.debitData);

      this.mService.addtransaction(this.debitData).subscribe(value => {
      

       
        this.openSnackBar(value.message+"...!!!");
        this.ngOnInit();
      
      }
      
      );
    });
  }


}