import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';

//import { AuthGuard} from "./interceptors/auth/authentication"


import { IadminLayoutComponent } from './containers/inventory-admin/iadmin-layout/iadmin-layout.component';
import { SadminLayoutComponent } from './containers/super-admin/sadmin-layout.component';
import { billingLayoutComponent } from './containers/billing/billing-layout.component';
import { LogoutComponent } from './views/logout/logout.component';
import { SupervisorLayoutComponent } from './containers/supervisor/supervisor-layout.component';
import { PittycashComponent } from './views/expense/pittycash/pittycash.component';
import { AfterloginselectionComponent } from './views/afterloginselection/afterloginselection.component';
import { HospitalAdminContainerComponent} from './containers/hospital/hospital-container.component';
import { KithchenAdminComponent } from './containers/kitchen/kitchenadmin.component';
import { DebitreceipeitemsComponent } from './views/kitchen/debitreceipeitems/debitreceipeitems.component';
import { ReciepedebitrequestsComponent } from './views/kitchen/reciepedebitrequests/reciepedebitrequests.component';
import { DayfooditemsrequestComponent } from './views/kitchen/dayfooditemsrequest/dayfooditemsrequest.component';
import { ReciepeprepstepsComponent } from './views/kitchen/reciepeprepsteps/reciepeprepsteps.component';

export const routes: Routes = [

  
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'admin',
    redirectTo: 'sadmin',
    pathMatch: 'full',
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'afterlogin',
    component: AfterloginselectionComponent,
    data: {
      title: 'Selection Page'
    }
  },
  {
    path: 'logout',
    component: LogoutComponent,
    data: {
      title: 'Logout'
    }
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page'
    }
  },
  {
    path: 'superadmin',
    component:SadminLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      
      {
        path: '',
     
        loadChildren: () => import('./views/inventory/management.module').then(m => m.ManagementModule)
      },
      {
        path: 'viewpittycash',
        component: PittycashComponent,
        data: {
          title: 'Pitty Cash'
        }
      },
      {
        path: 'org',
     
        loadChildren: () => import('./views/organization/organization.module').then(m => m.OrganizationModule)
      },
      {
        path: 'expense',
     
        loadChildren: () => import('./views/expense/expense.module').then(m => m.ExpenseModule)
      },
      {
        path: 'feedback',
     
        loadChildren: () => import('./views/feedback/feedback.module').then(m => m.FeedbackModule)
      },
      {
        path: 'nabhchecklist',
        loadChildren: () => import('./views/nabhchecklist/nabhchecklist.module').then(m => m.NabhchecklistModule)
      },
      
      {
        path: 'employee',
     
        loadChildren: () => import('./views/employee/employee.module').then(m => m.EmployeeModule)
      },
      {
        path: 'billreport',
     
        loadChildren: () => import('./views/billreport/billreport.module').then(m => m.BillreportModule)
      },
      {
        path: 'inventory',
     
        loadChildren: () => import('./views/inventory/management.module').then(m => m.ManagementModule)
      },
      {
        path: 'doctors',
        loadChildren: () => import('./views/doctors/doctors.module').then(m => m.DoctorsModule)
      },
      {
        path: 'coupons',
        
        loadChildren: () => import('./views/coupon/coupon.module').then(m => m.CouponModule)
      },
//BedModule
      {
        path: 'bed',
        loadChildren: () => import('./views/bed/bed.module').then(m => m.BedModule)
      },
//MenuModule
      {
        path: 'menu',
        loadChildren: () => import('./views/menu/menu.module').then(m => m.MenuModule)
      },
      {
        path: 'useraccount',
        loadChildren: () => import('./views/user/user.module').then(m => m.UserModule)
      },
      {
        path: 'assignbedordermenu',
     
        loadChildren: () => import('./views/walk-in/walk-in.module').then(m => m.WalkInModule)
      },
      {
        path: 'branches',
     
        loadChildren: () => import('./views/branches/branches.module').then(m => m.BranchesModule)
      },
      {
        path: 'receipedebits',
        component: ReciepedebitrequestsComponent,
        data: {
          title: 'Receipe Inventory Debit Request List'
        }
      }
  /*    {
        path: 'billing',
        loadChildren: () => import('./views/expense/expense.module').then(m => m.ExpenseModule)
      },
      {
        path: 'foodwastage',
        loadChildren: () => import('./views/wastage/wastage.module').then(m => m.WastageModule)
      }*/
    ]
  },
  {
    path: 'hadmin',
    component:HospitalAdminContainerComponent,
    data: {
      title: 'Home'
    },
    children: [
      
      {
        path: '',
     
        loadChildren: () => import('./views/feedback/feedback.module').then(m => m.FeedbackModule)
      },
      {
        path: 'feedback',
     
        loadChildren: () => import('./views/feedback/feedback.module').then(m => m.FeedbackModule)
      },
      {
        path: 'nabhchecklist',
        loadChildren: () => import('./views/nabhchecklist/nabhchecklist.module').then(m => m.NabhchecklistModule)
      }
    ]
  },
  {
    path: 'supervisor',
    component:SupervisorLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      
      {
        path: '',
     
        loadChildren: () => import('./views/inventory/management.module').then(m => m.ManagementModule)
      },
      {
        path: 'inventory',
     
        loadChildren: () => import('./views/inventory/management.module').then(m => m.ManagementModule)
      },
      {
        path: 'doctors',
        loadChildren: () => import('./views/doctors/doctors.module').then(m => m.DoctorsModule)
      },
//BedModule
      {
        path: 'bed',
        loadChildren: () => import('./views/bed/bed.module').then(m => m.BedModule)
      },
//MenuModule
      {
        path: 'menu',
        loadChildren: () => import('./views/menu/menu.module').then(m => m.MenuModule)
      },
      {
        path: 'assignbedordermenu',
     
        loadChildren: () => import('./views/walk-in/walk-in.module').then(m => m.WalkInModule)
      },
      {
        path: 'feedback',
     
        loadChildren: () => import('./views/feedback/feedback.module').then(m => m.FeedbackModule)
      },
      {
        path: 'nabhchecklist',
        loadChildren: () => import('./views/nabhchecklist/nabhchecklist.module').then(m => m.NabhchecklistModule)
      },
      {
        path: 'receipedebits',
        component: ReciepedebitrequestsComponent,
        data: {
          title: 'Receipe Inventory Debit Request List'
        }
      }
    ]
  },
  {
    path: 'iadmin',
    component: IadminLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: '',
     
        loadChildren: () => import('./views/inventory/management.module').then(m => m.ManagementModule)
      }, 
      
      {
        path: 'inventory',
     
        loadChildren: () => import('./views/inventory/management.module').then(m => m.ManagementModule)
      },
      {
        path: 'viewpittycash',
        component: PittycashComponent,
        data: {
          title: 'Pitty Cash'
        }
      },
      {
        path: 'receipedebits',
        component: ReciepedebitrequestsComponent,
        data: {
          title: 'Receipe Inventory Debit Request List'
        }
      }
    ]
    
    
  },

  {
    path: 'kitchenadmin',
    component:KithchenAdminComponent,
   
    children: [
      {
        path: '',
        component: DayfooditemsrequestComponent,
        data: {
          title: 'Debit Receipe - Inventory'
        }
      },
      {
        path: 'debitreceipe',
        component: DebitreceipeitemsComponent,
        data: {
          title: 'Debit Receipe - Inventory'
        }
      },
      {
        path: 'bulkreceipedebits',
        component: DayfooditemsrequestComponent,
        data: {
          title: 'Bulk Debit Receipe - Inventory'
        }
      },
      {
        path: 'receipedebits',
        component: ReciepedebitrequestsComponent,
        data: {
          title: 'Receipe Inventory Debit Request List'
        }
      },
      {
        path: 'prepstepdetails',
        component:  ReciepeprepstepsComponent,
        data: {
          title: 'View Reciepe Preperation Details'
        }
      }
    ]
  },

  {
    path: 'billing',
    component: billingLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: '',
     
        loadChildren: () => import('./views/walk-in/walk-in.module').then(m => m.WalkInModule)
      },
      {
        path: 'viewpittycash',
        component: PittycashComponent,
        data: {
          title: 'Pitty Cash'
        }
      },
      {
        path: 'walkIn',
     
        loadChildren: () => import('./views/walk-in/walk-in.module').then(m => m.WalkInModule)
      },
      {
        path: 'addexpense',
     
        loadChildren: () => import('./views/expense/expense.module').then(m => m.ExpenseModule)
      },
      {
        path: 'addfoodwastage',
        loadChildren: () => import('./views/wastage/wastage.module').then(m => m.WastageModule)
      },
    ]
    
    
  },

  { path: '**', component: P404Component }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
