
/* export class UserAccount {
        id: string;
        firstName:string;
        lastName:string;
        middleName:string;
        username:string;
        password:string;
        email:string;
        phone:string;
        active:boolean;
        blocked:boolean;
            role:string;
            webaccess:boolean;
            appaccess:boolean;
            details:string;
      
} */

export class UserAccount {
    id: string;
    firstName:string;
    lastName:string;
    middleName:string;
    username:string;
    password:string;
    email:string;
    phone:string;
    active:boolean;
    blocked:boolean;
    roles:ApplicationRoles[];
    linkedbranches:LinkedBranches[];
    webaccess:boolean;
    appaccess:boolean;
    createdByName:string;
    createdByUsername:string;
    modifiedByUsername:string;
    modifiedByName:string;
  
}

export class LinkedBranches
{
    branchId:string;
    branchCode:string;
    branchName:string;
}

export class LoggedInUserAccountDetails {
    id: string;
    firstName:string;
    lastName:string;
    middleName:string;
    username:string;
    password:string;
    email:string;
    phone:string;
    active:boolean;
    blocked:boolean;
    roles:ApplicationRoles[];
    branches:LinkedBranches[];
    loggedInTime:string;
    authdata?: string;

    role:ApplicationRoles;
    branch:LinkedBranches;
  
}

export class ApplicationRoles
{
    role:string;
    details:string;
}

export class UserLoginResponse {
    responseCode: boolean;
    message: string;
    responseObject: any;
}


export class UserAccountData {
    responseCode: boolean;
    message: string;
    responseObject: any[]
}