import {Component } from '@angular/core';
import { navItems } from '../../../inventorynav';
import { GlobalVariables } from '../../../service/GloabalVariables.service';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../service/auth/authentication.service';
import { ChangepasswordComponent } from '../../../views/user/changepassword/changepassword.component';
import { MessageComponent } from '../../../views/Generic/message/message.component';
import { MatDialog, MatSnackBar } from '@angular/material';

@Component({
  selector: 'iadmin-dashboard',
  templateUrl: './iadmin-layout.component.html'
})
export class IadminLayoutComponent {

  multipleRoles:boolean;

  constructor(public dialog: MatDialog,globalVariable:GlobalVariables,private route: Router,private authService:AuthenticationService,private snackBar: MatSnackBar)
  {
   

  }

  ngOnInit()
  {
    if (this.authService.currentUserValue==null||this.authService.currentUserValue==undefined||this.authService.currentUserValue.username==null||this.authService.currentUserValue.username==undefined) { 
      this.route.navigate(['/logout']);
    }

   /*  if(this.authService.currentUserValue.roles.length>1||this.authService.currentUserValue.branches.length>1)
    {
     
      this.multipleRoles=true;
    
    } */

    if(this.authService.currentUserValue.roles!=undefined||this.authService.currentUserValue.roles.length>1)
    {
      this.multipleRoles=true;
    }

    else if(this.authService.currentUserValue.branches!=undefined||this.authService.currentUserValue.branches.length>1)
    {
     
      this.multipleRoles=true;
    
    }
  }

  passwordChange()
  {

    const dialogRef = this.dialog.open(ChangepasswordComponent, {
      width: '800px',maxHeight:'600px'
    });
  
    
     dialogRef.afterClosed().subscribe((data) => {
  
      if(data!=null)
      {
        this.authService.currentUserValue.password=data.newPassword;
        this.route.navigate(['/logout']);
        this.openSnackBar("Password Change Successfully");
        dialogRef.close();
      }
      
  
  
  });

  }

  openSnackBar(message: string) {
    this.snackBar.openFromComponent(MessageComponent, {
      data: message,
      duration: 5 * 1000
    });
  }

  public sidebarMinimized = false;
  public navItems = navItems;

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  logout()
  {
    console.log("logging Out");
    this.route.navigate(['/logout']);
    
  }

  roleBranchChange()
  {
    this.route.navigate(['/afterlogin']);
  }


}




