import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { AuthenticationService } from '../../../service/auth/authentication.service';
import { BranchserviceService } from '../../../service/branch/branchservice.service';
import { ManagementService } from '../../../service/inventory/inventory.service';
import { KitchenDebitService } from '../../../service/kitchendebit/kitchendebits.service';
import { BranchAccountOrder } from '../../bean/Branches';
import { InventoryLocations } from '../../bean/Inventory';
import { KitchenInventoryDebits, KitchenInventoryOrders } from '../../bean/Menu';
import { MessageComponent } from '../../Generic/message/message.component';
import { AlertPopup } from '../../popup/AlertPopup';
import { CommentPopup } from '../../popup/CommentPopup';
import { ConfirmPopup } from '../../popup/ConfirmPopup';
import { KitchenOrderDetailsPopup } from '../popup/KitchenOrderDetailsPopup';

@Component({
  selector: 'app-reciepedebitrequests',
  templateUrl: './reciepedebitrequests.component.html',
  styleUrls: ['./reciepedebitrequests.component.css']
})
export class ReciepedebitrequestsComponent implements OnInit {



  inventoryCode:string;
  inventoryName:string;

  submitted:boolean=false;

  
  allKitchenInvOrderList: KitchenInventoryOrders[] = [];
  tempKitchenInvOrderList: KitchenInventoryOrders[] = [];


  urlData:string;


  kitchenAdmin:boolean=false;

  fromDate:string;
  toDate:string;

  selectedBranchIdIndex:number;

  selectedBranchCode:string;

  selectedBranchName:string;

  branchSelected:boolean;

  branchList:BranchAccountOrder[]=[];

  newRequestOnlyClicked:boolean;
  filterDataClicked:boolean;

  showByFoodItems:boolean;
  showByInventoryItems:boolean;

  inventoryItemsReport:KitchenInventoryDebits[]=[];

  
  constructor(private authService:AuthenticationService,private inventoryService: ManagementService,
              private kitchenDebitService: KitchenDebitService, private branchService: BranchserviceService,
              private _snackBar: MatSnackBar,public dialog: MatDialog) { 

    this.kitchenAdmin=false;

    this.selectedBranchCode=this.authService.currentUserValue.branch.branchCode;
   
    
    if(this.authService.currentUserValue.role.role=='ROLE_SUPERVISOR')
    {
    this.inventoryCode=this.authService.currentUserValue.role.details;
    }
    else if(this.authService.currentUserValue.role.role=='ROLE_INVENTORY_ADMIN')
    {
    this.inventoryCode=this.authService.currentUserValue.role.details;
    }
    else if(this.authService.currentUserValue.role.role=='ROLE_KITCHEN_ADMIN')
    {
    this.kitchenAdmin=true;
    this.inventoryCode=this.authService.currentUserValue.role.details;
    }
  }

  onNewRequestOnlyClicked()
  {
    this.newRequestOnlyClicked=true;
    this.filterDataClicked=false;
    if(this.kitchenAdmin)
    {
      this.fetchAllNewBranchCode();
    }
    else
    {
    this.fetchAllNew();
    }
  }

  onFilterDataClicked()
  {
    this.newRequestOnlyClicked=false;
    this.filterDataClicked=true;

    if(this.kitchenAdmin)
  {
    
    this.fetchAllByDateRangeBranchCode();
  }
  else
  {
    this.fetchAllByDateRange();
  }

  }
  
 

  search()
  {
    this.allKitchenInvOrderList=[];
    this.tempKitchenInvOrderList=[];
    
    if(this.selectedBranchIdIndex==0)
    {
      console.log('Search All');
      if(this.filterDataClicked)
      {
      this.fetchAllByDateRange();
      }
      else
      {
        this.fetchAllNew();
      }
    }
    else
    {
      if(this.filterDataClicked)
      {
     console.log('Search All By Branch Code='+this.inventoryCode);
    this.fetchAllByDateRangeBranchCode();
      }
      else{
        this.fetchAllNewBranchCode();
      }
      }

    
  }

  ngOnInit() {

    var currentDate=new Date();
    this.fromDate= formatDate(currentDate, 'yyyy-MM-dd', 'en-US');
    this.toDate= formatDate(currentDate, 'yyyy-MM-dd', 'en-US');

  
   

    this.filterDataClicked=true;
    this.newRequestOnlyClicked=false;

    this.showByFoodItems=true;
    this.showByInventoryItems=false;

    

  if(this.kitchenAdmin)
  {
    
    this.fetchAllByDateRangeBranchCode();
  }
  else
  {
    this.selectedBranchIdIndex=0;
    this.branchSelected=true;
    this.fetchAllBranchList();
    
  }

    
  }

  onShowByInventoryItems()
  {
    this.showByFoodItems=false;
    this.showByInventoryItems=true;
  }

  onShowByFoodItems()
  {
    this.showByFoodItems=true;
    this.showByInventoryItems=false;

  }

  updatedSelectedBranch()
    {
      var selectedBranchCode=this.authService.currentUserValue.branch.branchCode;
      for(let i=0;i<this.branchList.length;i++)
      {
        if(selectedBranchCode==this.branchList[i].branchCode)
        {
          this.selectedBranchIdIndex=i+1;
          this.selectedBranchCode=selectedBranchCode;
          this.selectedBranchName=this.branchList[i].branchName
          this.branchSelected=true;
          break;
        }
      }

      this.fetchAllByDateRange();

    }

  fetchAllBranchList()
  {
    this.branchService.fetchAllActiveBranchAccountOrders().subscribe(response => {

      if(response.responseCode==true)
      {
       
        console.log('Branch List')
        this.branchList=response.responseObject;
        this.updatedSelectedBranch();
      
      }
     
      else
      {
        this.openSnackBar('Error Fetching Report')
      }
    
    });
  }

  onBranchSelected()
  {
   
    this.branchSelected=false;
    if(this.selectedBranchIdIndex!=-1)
    {
      if(this.selectedBranchIdIndex>0)
      {
        this.selectedBranchCode=this.branchList[this.selectedBranchIdIndex-1].branchCode;
        this.selectedBranchName=this.branchList[this.selectedBranchIdIndex-1].branchName;
      }
    this.branchSelected=true;
    }
   
  }

  fetchAllNew()
  {
    this.kitchenDebitService.fetchAllNew().subscribe(data => {
      this. allKitchenInvOrderList = data.responseObject;
      this.tempKitchenInvOrderList=data.responseObject;
      console.log(this. allKitchenInvOrderList);
      this.updateByInventoryItems()
    });
  }

  fetchAllNewBranchCode()
  {
    var branchCode={"branchCode":this.selectedBranchCode};
    this.kitchenDebitService.fetchAllNewBranchCode(branchCode).subscribe(data => {
      this. allKitchenInvOrderList = data.responseObject;
      this.tempKitchenInvOrderList=data.responseObject;
      console.log(this. allKitchenInvOrderList);
      this.updateByInventoryItems()
    });
  }

  fetchAllByDateRange()
  {
    let param={"startDate":this.fromDate,"endDate":this.toDate};
    this.kitchenDebitService.fetchAllByDateRange(param).subscribe(data => {
      this. allKitchenInvOrderList = data.responseObject;
      this.tempKitchenInvOrderList=data.responseObject;
      console.log(this. allKitchenInvOrderList);
      this.updateByInventoryItems()
    });
  }

  fetchAllByDateRangeBranchCode()
  {
    var param={"branchCode":this.selectedBranchCode,"startDate":this.fromDate,"endDate":this.toDate};
    this.kitchenDebitService.fetchAllByDateRangeBranchCode(param).subscribe(data => {
      this. allKitchenInvOrderList = data.responseObject;
      this.tempKitchenInvOrderList=data.responseObject;
      console.log(this. allKitchenInvOrderList);
      this.updateByInventoryItems()
    });
  }


  updateByInventoryItems()
  {
    this.inventoryItemsReport=[];
    for(let i=0;i<this.allKitchenInvOrderList.length;i++)
    {
      let order:KitchenInventoryOrders=this.allKitchenInvOrderList[i];
     
      for(let j=0;j<order.kitchendebits.length;j++)
      {
        let found=false;
      for(let k=0;k<this.inventoryItemsReport.length;k++)
      {
        if(order.kitchendebits[j].inventoryItemCode===this.inventoryItemsReport[k].inventoryItemCode)
        {
          found=true;
          this.inventoryItemsReport[k].quantity=this.inventoryItemsReport[k].quantity+order.kitchendebits[j].quantity;
          break;
        }

      }
      if(!found)
      {
       
        this.inventoryItemsReport.push(this.createNewKithcenDebitItem(order.kitchendebits[j]))
      }
    }

   

    }
  }
  

  createNewKithcenDebitItem(item:KitchenInventoryDebits):KitchenInventoryDebits
  {
    let kitchendebit=new KitchenInventoryDebits();
    kitchendebit.branchCode=item.branchCode;
    kitchendebit.quantity=item.quantity;
    if(kitchendebit.quantity<1)
    {
      kitchendebit.quantity=kitchendebit.quantity*1000;
      if(item.measurementUnit.toLowerCase()=='kg')
      {
        kitchendebit.measurementUnit='GRAMS';
      }
      else if(item.measurementUnit.toLowerCase()=='litres')
      {
        kitchendebit.measurementUnit='ML';
      }
    }
    else
    {
      kitchendebit.measurementUnit=item.measurementUnit;
    }
    kitchendebit.inventoryItemCode=item.inventoryItemCode;
    kitchendebit.inventoryItemName=item.inventoryItemName;
  
    return kitchendebit;
  }



  applyFilter(event: Event) {

    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    var filterResult:KitchenInventoryOrders[] = this.tempKitchenInvOrderList.filter(tran => 
      tran.inventoryName.toLowerCase().startsWith(filterValue)||tran.inventoryCode.toLowerCase().startsWith(filterValue)||
      tran.branchCode.toLowerCase().startsWith(filterValue)||
      tran.foodItemName.toLowerCase().startsWith(filterValue) ||tran.foodItemCode.toLowerCase().startsWith(filterValue));
  
      if(filterValue=='')
      {
        this.allKitchenInvOrderList=this.tempKitchenInvOrderList;
      }
      else
      {
      if(filterResult.length>0)
      {
        this.allKitchenInvOrderList=filterResult;
      }
      else
      {
        this.allKitchenInvOrderList=this.tempKitchenInvOrderList;
      }
    }
   
  }



  openSnackBar(message:string) {
    this._snackBar.openFromComponent(MessageComponent, {
      data: message,
      duration:5*1000
    });
  }

  openViewPopup(itemTran:KitchenInventoryOrders)
  {
    const dialogRef = this.dialog.open(KitchenOrderDetailsPopup, {
      width: '800px',maxHeight:'600px',data:itemTran
    });
  }

  openAcceptConfirmDialog(itemTran:KitchenInventoryOrders)
  {
    
    const dialogRef = this.dialog.open(ConfirmPopup, {
      width: '400px',maxHeight:'300px',data:{message:"Are you Sure to Accept?",description:"By Selecting accept you are going to Debit from Inventory for Kitchen Request"}
    });

    dialogRef.afterClosed().subscribe((result:string) => {
    
      
      if(result=='yes')
      {
        var param={"id":itemTran.id,"comments":result,"user":this.authService.currentUserValue.firstName+" "+this.authService.currentUserValue.lastName};
        this.kitchenDebitService.acceptRequest(param).subscribe(data => {
          const dialogRef = this.dialog.open(AlertPopup, {
            width: '400px',maxHeight:'300px',data:{"icon":"success","message":"Request Approved Successfully"}
          });
          dialogRef.afterClosed().subscribe(data=>{
          {
            this.search();
          }});
         
        });
       
      }

  });
  }


  openRejectCommentDialog(itemTran:KitchenInventoryOrders)
  {
    
    const dialogRef = this.dialog.open(CommentPopup, {
      width: '800px',maxHeight:'600px',data:"Reject"
    });

    dialogRef.afterClosed().subscribe((result:string) => {
    
      
      if(result!=undefined&&result!=null)
      {
        var param={"id":itemTran.id,"comments":result,"user":this.authService.currentUserValue.firstName+" "+this.authService.currentUserValue.lastName};
        this.kitchenDebitService.rejectRequest(param).subscribe(data => {
          this.openSnackBar(data.message);
          this.search();
        });
     
      }

  });
  }

}
