import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { api } from '../../../environments/api';
import { catchError } from 'rxjs/operators';
import { WalkIN } from '../../views/bean/Company';
import { BedBillDetails, BedOrderDetails} from '../../views/bean/Bed';

@Injectable({
  providedIn: 'root'
})
export class PrintService {

  httpOptions = {
    headers: new HttpHeaders({'Access-Control-Allow-Origin':'*'})

  };

  

  constructor(private http: HttpClient) { }

   printExtraOrderSummary(data: BedOrderDetails): Observable<any> {
    var printData=JSON.stringify(data);
    let params = new HttpParams();
    
    params=params.append('data',printData);
    return this.http.get<any>(api.printExtraBedOrderSummary,{params: params}).pipe(catchError(this.handleError));
  }

  printBill(data: WalkIN): Observable<any> {
    var printData=JSON.stringify(data);
    let params = new HttpParams();
    
    params=params.append('data',printData);
    return this.http.get<any>(api.printbill,{params: params}).pipe(catchError(this.handleError));
  }

  printBedOrderBill(data: BedBillDetails): Observable<any> {
    var printData=JSON.stringify(data);
    let params = new HttpParams();
    
    params=params.append('data',printData);
    return this.http.get<any>(api.printbill,{params: params}).pipe(catchError(this.handleError));
  }

  

  printKot(data: WalkIN): Observable<any> {
    var printData=JSON.stringify(data);
    let params = new HttpParams();
    params=params.append('data',printData);
  /*   var headers= new HttpHeaders({'Access-Control-Allow-Origin':'*'})
    headers.append('Content-Type', 'text/plain'); */
    return this.http.get<any>(api.printkot,{ params:params}).pipe(catchError(this.handleError));
  }

  printSplitKot(data: WalkIN): Observable<any> {
    var printData=JSON.stringify(data);
    let params = new HttpParams();
    params=params.append('data',printData);
 
    return this.http.get<any>(api.printsplitkot,{ params:params}).pipe(catchError(this.handleError));
  }

  printBillKot(data: WalkIN): Observable<any> {
    var printData=JSON.stringify(data);
    let params = new HttpParams();
    params=params.append('data',printData);
    return this.http.get<any>(api.printbillKOT, { params:params}).pipe(catchError(this.handleError));
  }

  printBillSplitKot(data: WalkIN): Observable<any> {
    var printData=JSON.stringify(data);
    let params = new HttpParams();
    params=params.append('data',printData);
    return this.http.get<any>(api.printbillsplitKOT, {params:params}).pipe(catchError(this.handleError));
  }

  shutdownPrintService(data:any): Observable<any> {
    return this.http.post<any>(api.shutdownprint,data, this.httpOptions).pipe(catchError(this.handleError));
  }

  handleError(error: HttpErrorResponse):Observable<any>{
    console.log(error);
    console.log(error.error.message);
    if(error==undefined||error.error==undefined||error.error.message==undefined)
    {
      alert('Print Service Not Running or Start Print Service');
    }
    else
    {
    alert(error.error.message);
    }
    return null;
    }


}
