import { Component, OnInit } from '@angular/core';
import { ViewChild, Input } from '@angular/core';
import { ManagementService } from '../../../service/inventory/inventory.service';
import { InventoryLocations, InventoryData, InventoryItem } from '../../bean/Inventory';
import { MatRadioButton } from '@angular/material';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MessageComponent } from "../../Generic/message/message.component";
import { Router, NavigationExtras } from '@angular/router';
import { GlobalVariables } from '../../../service/GloabalVariables.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.css']
})
export class LocationsComponent implements OnInit {


  constructor(private formBuilder: FormBuilder,private mService: ManagementService, 
              private _snackBar: MatSnackBar,
              private _router:Router) {
  }

  addItems: boolean = false;

  allInvLocData: InventoryLocations[] = [];
  tempInvLocData: InventoryLocations[] = [];
  updateTyep: boolean = false;


  actions: string[] = ['Update', 'Activate', 'De-Activate', 'Delete'];

  submitted:boolean=false;
  inventoryLocationForm: FormGroup;

  model: InventoryLocations = new InventoryLocations();

  ngOnInit() {

    this.inventoryLocationForm = this.formBuilder.group({
      inventoryName: ['', Validators.required],
      inventoryCode: ['', Validators.required]
  });

  this.submitted=false;
  this.inventoryLocationForm.reset();

    this.mService.getAllInventoryLocationItems().subscribe(data => {
      console.log(data.responseObject);
      this.allInvLocData = data.responseObject;
      this.tempInvLocData= data.responseObject;
    });
    this.addItems = false;
    this.updateTyep = false;
    this.model = new InventoryLocations();
  }


  addItem() {
    this.addItems = !this.addItems;
  }

  update(data: InventoryLocations) {
    this.addItems = !this.addItems;
    this.updateTyep = true;
    console.log(data);
    /* this.mService.getMenuData(data).subscribe(data => {
      this.model = data;
    }); */
    this.model = data;
  }


  deactivate(data: InventoryLocations) {
    var param={'inventoryCode':data.inventoryCode};
    this.mService.deactivateInventoryLocation(param).subscribe(result => {
      this.openSnackBar(result.message);
      this.ngOnInit();
    });

  }

  activate(data: InventoryLocations) {
    var param={'inventoryCode':data.inventoryCode};
    this.mService.activateInventoryLocation(param).subscribe(result => {
      this.openSnackBar(result.message);
      this.ngOnInit();
    });
  }

  delete(data: InventoryLocations) {
    
    var param={'inventoryCode':data.inventoryCode};
    this.mService.deleteInventoryLocation(param).subscribe(result => {
      this.openSnackBar(result.message);
      this.ngOnInit();
    });
  }


  listOfItem(inventory: InventoryLocations)
  {
  //  this.globalVariable.setInventoryCode(inventory.inventoryCode);
  const navigationExtras: NavigationExtras = {
    state: {
      inventoryCode: inventory.inventoryCode
    }
  };
    this._router.navigate(['/sadmin/inventory/iitemslist'],navigationExtras);
    
  }

  




 








  saveNewMenuItem() {

 /*    if (this.updateTyep) {
      this.mService.updateData(this.model).subscribe(data => {
        this.openSnackBar("Data Updated Successfully ....!!!!");
        this.ngOnInit();
      });
      return;
    } */

    this.submitted=true;
    if(!this.inventoryLocationForm.valid)
    {
      return;
    }

    this.mService.saveLocationData(this.model).subscribe(data => {

      this.openSnackBar("Data Saved Successfully ....!!!!");
      this.ngOnInit();
    }
    );
  }



  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();

    var filterResult:InventoryLocations[] = this.tempInvLocData.filter(tran => 
      tran.inventoryCode.toLowerCase().startsWith(filterValue) ||tran.inventoryName.toLowerCase().startsWith(filterValue)
      ||tran.inventoryAddress.toLowerCase().startsWith(filterValue));
  
      if(filterValue=='')
      {
        this. allInvLocData=this.tempInvLocData;
      }
      else
      {
      if(filterResult.length>0)
      {
  this.allInvLocData=filterResult;
      }
      else
      {
        this. allInvLocData=this.tempInvLocData;
      }
    }
  
  }



  openSnackBar(message: string) {
    this._snackBar.openFromComponent(MessageComponent, {
      data: message,
      duration: 5 * 1000
    });
  }


}


